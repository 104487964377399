import { EChartOption } from "echarts";
import { Table } from "helpers/models";

const get = (grouped: Table): EChartOption.Series[] => {
  const categories = Object.keys(grouped);

  const seriesMap: { [key: string]: EChartOption.SeriesBar } = {};

  for (const id of categories) {
    for (const item of grouped[id]) {
      if (!seriesMap[id + item.label]) {
        seriesMap[id + item.label] = {
          name: item.label,
          type: "bar",
          barGap: "30%",
          barCategoryGap: "50%",
          stack: "total",
          itemStyle: { color: item.color },
          data: Array(categories.length).fill(0) as number[],
          barWidth: 6
        };
      }
      const index = categories.indexOf(id);
      if (index === -1) {
        continue;
      }
      const data = seriesMap[id + item.label].data;
      if (!data || data.length <= index) {
        continue;
      }
      data[index] = item.count;
    }
  }

  const series = Object.values(seriesMap);

  return series;
};

export default get;

import { LegendItem, Polygon, Raster, RR } from "helpers/models";

const transform = (legendItems: LegendItem[]): RR => {
  const result: RR = {};

  legendItems.forEach((item) => {
    const layerId = item.layerId;
    if (!result[layerId]) {
      result[layerId] = {};
    }

    item.rules.forEach((rule) => {
      const title = rule.title;
      const raster = (rule.symbolizers[0] as Raster)?.raster?.color;
      const polygon = (rule.symbolizers[0] as Polygon)?.polygon?.fill;
      const color = raster || polygon;
      if (color) {
        result[layerId][title] = color;
      }
    });
  });

  return result;
};

export default transform;

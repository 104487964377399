import { Layer, R, Row, RR, RV, Table } from "helpers/models";

const sortArray = [
  "2 years",
  "5 years",
  "10 years",
  "20 years",
  "25 years",
  "30 years",
  "50 years",
  "≤50 years",
  "100 years",
  "200 years",
  "250 years",
  "300 years",
  "500 years",
  "1000 years",
  "≥1000 years",
  "Waterbody",
  "Outside",
  "No Data",
  "No Observation"
];

const transform = (group: RV, legend: RR): Table => {
  const integratedData: Table = {};

  Object.keys(group).forEach((layer) => {
    integratedData[layer] = [];

    const orderArray = layer.includes("Fathom")
      ? sortArray
      : move(Object.keys(legend[layer]).reverse());
    const arrayToSort = Object.keys(group[layer]);
    arrayToSort.sort((a: string, b: string) => {
      return orderArray.indexOf(a) - orderArray.indexOf(b);
    });

    arrayToSort.forEach((key: string) => {
      integratedData[layer].push({
        label: key,
        count: group[layer][key],
        color: getColor(key, legend[layer])
      });
    });
    
    sortRow(integratedData[layer]);
  });

  return integratedData;
};

export default transform;

const getColor = (key: string, rule: R): string => {
  if (key === "Outside") {
    return "#CCCCCC";
  }
  if (rule[key]) {
    return rule[key];
  }
  if (
    key === "5 years" ||
    key === "10 years" ||
    key === "20 years" ||
    key === "50 years"
  ) {
    return rule["≤50 years"];
  } else if (key === "1000 years") {
    return rule["≥1000 years"];
  }
  return "";
};

const move = (arr: string[]) => {
  const specialValues = ["Outside", "No Data"];
  const normalItems = arr.filter((item) => !specialValues.includes(item));
  const specialItems = arr.filter((item) => specialValues.includes(item));
  return [...normalItems, ...specialItems];
};

export const addColor = (result: Layer, legend: RR) => {
  for (const layerId in result) {
    const layer = result[layerId];
    const rules = legend[layerId];

    if (rules) {
      for (const groupKey in layer) {
        const group = layer[groupKey];
        group.forEach((entry) => {
          const matchedColor = getColor(entry.label, legend[layerId]);
          entry.color = matchedColor;
        });

        sortRow(group);
      }
    }
  }

  return result;
};

const sortRow = (group: Row[]) => {
  group.sort((a: Row, b: Row) => {
    return sortArray.indexOf(a.label) - sortArray.indexOf(b.label);
  });
};

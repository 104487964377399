export const loc =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAkCAYAAACaJFpUAAAAAXNSR0IArs4c6QAAAHhlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAAEgAAAAAQAAASAAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAABygAwAEAAAAAQAAACQAAAAAYF1tBwAAAAlwSFlzAAAsSwAALEsBpT2WqQAAAwVJREFUSA3tVT1oFEEYnW/2gr9EkBwRCw0BTalo629lkhO8Kmihkj8EwfQWYhoLOytRIgZMZ5oInmITTewkSmIXBY0SNJdLY9SAmtvxe3fM3bc/s3cG02VhmTffe997u8PMrlIb139eAarH70Hzs21/TDFrjH/GKDqglNld7qMvpMwMkX7UQN7Yhfypn7X8EgONMXS/+cklHgeNUc1JZkQqT0SDPfnOuzwal9YZ+DD9fPuyWRkxymRdzXF1UjTWSFvPdxVO/ojjdVxx6vBUw7Jayf1rGLzQg154xHnHBk5/zt/iZTwW11BPDb3wiNNGlnQ4nTtYNOoNP2mEizNw1XhpjUfqUHchMy01kTfksOtJYTBSRO9wl7B0Exge8BKlEgwEYvsrUu1hUWVONOGldGt/IdOGG5iDJyp8GLBXyVPUA4G//dWjvP6bBV+BvO0/bNKpTPdCx5wtAqMGztbkCC94ylogkAV7JCmxIX077mCjBk5qJQ57BgI16SYpltjzzaycS5zEhT0DgXyKvkkjiYua2uRc4iQu7BkI9Ml8lUYSk/EvhzcAeNTASa3EYc9A4JYG/UqKJeZvaesvfzU3vOtpi60DowbO1sJj2DNyuO815eb4DO0NN9o5zh5/Et5jzidyX40z+6lvKdNiezEG3rBM0KgUhHEpwJj9iu+kMJdXJNBoGgmHrHUe5xUJ7F/seMvr/GKtIbYPHvCycztGAkFoT92wgrWOLo/IprEBQ+nHk/xzC3yWLFdr5D/+ZF8hczxOF/uGEGpKXeGnKcY1JdXQQ+QNuDTOwN7F9hlDdMfV6KqjB70u3hmIBr1zx1X+XX10NUfqrC31RIhqITGwd/bI95RKXWS5X21xIh9a9DgVTCQGorG70P6SN8HNJBNw0EBbS1czEAaNJzqvseW424zGyxq3wjJ1BXaNUtFL0VkOnbeN1ZHmwUFTrblRXYFo71noLPC/LcubaKViV8ImW+Yq1URQdyBc+pdOv9baO8cQm8gHRg3cul5D6dwA7nUN2TCvdwX+AlVpEtPlQXeNAAAAAElFTkSuQmCC";

export const date =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAHhlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAAEgAAAAAQAAASAAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAACSgAwAEAAAAAQAAACQAAAAAv7+JHgAAAAlwSFlzAAAsSwAALEsBpT2WqQAAAXtJREFUWAntWDFOw0AQ3L1E9JEAWbwDGhrSpUD4D5Z4AD+AJ/AAkP9gKwWdqcM3QJZAco/ILXeJLN96sSOD7VDcVbt7q5nRnG35FuCfLWzTEwfpXGuY2x6lIIvyq8zGXVcXnGkbuBVDmm5tj4aN9qytv2mvC45qAtlXXRxZfLQMiCgkRSdAxiGiCysOEZ+NSdmvhP6Eo+FVoUqj98vcxWSC4iA5W3/hEwDN3KbhYiwmU1pEebgqOdiR6TXejyfGSqDZlrOUY16eKrT7dMryMZIaJxNEAAdjaHA56pxMkNu4r9gL2uV845f6+iNkn4RdQF33Hw4T8/jI5Y9MesIr3iHuh8y8Q9ITXvEOcT9k5h2SnvCKd4j7ITPvkPSEV7xD3A+ZeYekJ7zCfmHNP+tneS15PE7veGu/mRlibAAtp4vMBJkL/Iu5LJ7bhnLq4TYPEltOZ7FnSE3oxowVCmd/4BCLLWdFI24WbPpR9fUeocY3M1FJ6tOP3on+CvgNXr1wz6sYtSgAAAAASUVORK5CYII=";

export const map =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAHhlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAAEgAAAAAQAAASAAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAABygAwAEAAAAAQAAABwAAAAA8QyeQAAAAAlwSFlzAAAsSwAALEsBpT2WqQAAAp5JREFUSA3tVktoU0EUvXfyMW5ES9MqDWhBBEEQ6qqgIFZIP9GNUm0XRpK6cudeqIu6VBe6aWJoiqQ0KC4aYwK2ghtXIoggFUGE+KmRqrgxn3ev96V57ctrktcSlxkYZuZ+zpk5s7gXwWZE9qUOg6ZOuokXgz9G3tuE13XH9qa9THxuhwtTWC8i3vnkUAlhFIAvMMMRU8wrBZhwejzzwdzAZ5N905YnWcXuPfUzcFgwzgqWCxGvrxNGvZmDCDTK+mQ4ugmh1kCS+IIB5tDjfDiRG1w13LPd2d4ilUOCcVlIfIa9siq8gbHOTJ+G5Qgw9NU4TQcE/MTIXRKz02SubrEECFlkWBKCEQA8Ja9af0hNvBAqBu1MMzI9gRXMqI7d3UqpYAUcQNsAYhcwB4Tklrx4oCFZNcFJ8ilAG+mNduHl43/EN6tPka2rSJr8MY2LdP2NcurZnfWMdrZLK/7vEnNXn/qflUgbI6AJUarXLlfZBdj5hfxjOD98ExD119uOlgltGSwBbUKLIK0f25K2rqEFoS2pRZDWj21JW9fQgrCl8iTVPHjfmy66lGNOrw4WjG0dt0TIzAekkk8VNJqKelMvAVTCrRzJal38/4RmxLUKT/1Fje5EvKlnClUC9ux6TD9/m8Pq7hXp3UqTIc2TtCm4JOs1KbAp2ZeMcHE4pML7iShOq79WkPQurfGQFvE1gmOhgaSYQ4QZt3LGTH92O+rLdPDf8nkhH5fG6YTAr11YujmRfL+VTkjeyGXnGVRyIj/4Qfc75Q1lua0MLAnJggREQ1eHsjgpd7aMav85LebpuG+xp1gsXASiMTkfM0IF4y2zSio3JsNfhpYNu7Hig560r1DiACp8FPo2nDcc21krnbri0+yA51e+Bt41y/0H+SnwxWZHn3gAAAAASUVORK5CYII=";

export const set =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAHhlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAAEgAAAAAQAAASAAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAACSgAwAEAAAAAQAAACQAAAAAv7+JHgAAAAlwSFlzAAAsSwAALEsBpT2WqQAABKJJREFUWAntV01oXFUUvue+mfw0Vls7TW1R2yC2C/GvVRdCNf6AJs0kpuAiBWszKYKIghvFhUYXBd2oKC1iTVOahQsX1UyqFYpE3YikSgRFK7SNxCYziSlW6tDMzD1+Z2bu9L03M29eghuxF2bu+f3uN+eee98bpa6M/1gFKAzf4daxnZzTPycWOn+S+OSG5Ip0TvUq5m5WdIdSvKGIQ+cwTxKpT6LU8PHu1CMXi/bw34GEhmJjDzCZD0nR6YG5+L3MTMPrkk8ZpkGQWR+0DBHNEvGr/an4+5A5KNbtq0nocGtyd86oYa14OjHfvfHIus9bFs2lI4rVTjdAPRlkjkZ1wxNhq6WrAR5aM/Z03qjDYEtN5qrOiW0T0axZPLZUMoKNqvZKrmBUW8tvqyA0svb4dibezwrfrJK7Fh78cfK3mbcAfL8/OawuuYIRJr5iy4bWjqZApBV9w82Z5vWZlsx1yqjvhWAYwIAYQ5q2DqTjkwExylOhwmkCmWICn9t18eEUtmkwiIwQV0Sn5FOQa6+GdlSDtd1Fj4dQ3vALNoG0HpVGht5hbf4ZDftl1Gm4ae9cfIt8RBabP86ld5QwXSav6CGEFrzFug2ribzJbcf+N1mbe8bCp3F6duD0nLF2kcWmSJVt1iezYAmm2+aXfYTUChsQiWQnAXCj1StmogPVjrLYiPT+iviSIRATMR5C6IFy467O6lNo41gtYDL0y3J8QZiC5yGE5mW7yHndfBuK/KfV/TNr3uK3WT3IF4Qp+R5C2PvFMihduksTzVjdPzObZ6o1qNjE54+3ehCmxHgIYb+mbCJu6oeiUf2t1StmVm1yA4NAm/WJXLrRyzbrs3MgJoLA4fL4IHbsoFL5vWJBP10YmI9fMxRLnsVWbrwc5ZXk7kFf/FrIYXVznTtrCpibvAhezVOhloje57rcVo6sOn479I+8KV6tQIB5M9pvcxAZyaqHJTEeQn2zHVKNQt8IeDaafdvRzogE/hsjDJaHkCyqmd6wixvm+1ZmMr/jt41b2/JnGn8y3fFDvfwKQok/4u8g6UIpUZ9vajioHdpXD6iePyxGBSEBRmlfswvgCPdEnFXy8Pza2pY647r9KpHqOhEmryqh/vSON0np6RKAzmYXjgL0WRzJfBhQd0whh+g5ty1IrkpIErRjHkfvFG9upjshtKFK7wWBVfUhp947kDuvJqH+VPc3eDcvHPnCcTZqhBvXvIKze8YNEChL7LWrXwqM8Tk9F6PPJ68LdKh1LI258JDFtf8p7pLX82zGEVvzx5RwjEO6vX+ua0m9FwiKdx52IrlHsYCRRfDo7cgz34CtK18NpcUrJ8QslYyABBKSgD0zvScR9SIqAz4MijzcyNF3UdovxF9tiO/q9q6Xq/nq2QK3zJ2Ml//PwEiqhQKps+RE7jG5/Heo2/XuOHindcTZmpjtnPPaw2l1K2RhEul4J6o0JTqIbeK8ORBR9BjY/W1jRBbbcskITmhC0k8xZW5FTuEWZ2Xa98x3ncRfmz7YpMfkb06f2CAve4QmJCv0zPf81UgN20Au1eJE7xbbQKprFKfvefmILLYr439VgX8AjTOxPBaUCZkAAAAASUVORK5CYII=";

export const il =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAcCAYAAAAJKR1YAAAAAXNSR0IArs4c6QAAAHhlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAAEgAAAAAQAAASAAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAACSgAwAEAAAAAQAAABwAAAAALu56WQAAAAlwSFlzAAAsSwAALEsBpT2WqQAAAXZJREFUSA3tlj9OwzAUxp/dzBVLosBCOAHcgDAh1PYMJHRh4QyUGyCxkjYza6gQE+EG3ICwQEUXdhI/HmktbChUBeGAlEiJn9/zn5++RJ8DUF9fK8DU8sBN/CLHq9ccY+y6O+74at1EbOmbWBnj+REICPT8pBfZwwCY8GbVvpvj3IrD0U4m52tA00IvshNfDtBbEaDATT33wx7PU1ohk6twGfyVVlNoPhSPGRfp/HGLjLAydfRCQN1xK1Yn13GtACnwqTFKdRoW2wpHnVT2f7t991FPjZF2RQEh0S4VjN9KiOqM0Un2CWKFcdbuPrTuJBBABcbYd863SZ0TxuFg77F9+QZjJtJe2enycBWfxRltfU+3GzlJTz9rDBtjA8VaAdgkmCapdFhqopw1tTGWitQPXYF/ZIwIHiDuqvyVGWPfTo4JZANVmjKuwBg/MBhOaMY4cC88IfKAlHlChBtiWdd5DBsjQO7RT/zEEHWSslcb4wxRjKdeAK1SneXR3kDGAAAAAElFTkSuQmCC";

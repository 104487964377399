import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as PdfSvg } from "assets/icon_pdf.svg";
import { ReactComponent as ExcelSvg } from "assets/icon_excel.svg";
import { ReactComponent as LocationsPinSvg } from "assets/pin_locations.svg";
import Icon from "components/icon/icon";
import Results from "components/results/results";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { getCheckedCounts } from "helpers/helpers";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadExcel } from "./download/excel-download";
import styles from "./multi-locations.module.scss";
import { CircularProgress, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import getDoc from "report/set/document";
import generate from "report/generation";

function MultiLocations() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadPdf = () => {
    setAnchorEl(null);
    const set = context.currentMultipleLocations
    const analysisItems = context.hazardMultipleAnalysisResult
    const legendItems = context.hazardMultipleAnalysedLegends
    if(!set || !analysisItems || !legendItems){
      return;
    }
    
    setIsLoading(true);
    getDoc({ t, set, analysisItems,  legendItems})
    .then(dd => {
      generate(dd, set.name)
      .then(()=>{
        dispatch({
          type: AppAction.setSnackBarParams,
          payload: {
            title: "reportGenerated",
            message: "setReportGeneratedSuccess",
          },
        });
      }).catch(() => {
        dispatch({
          type: AppAction.setSnackBarParams,
          payload: {
            title: "climateRiskModalDownload",
            titleIconColor: "yellow",
            titleIcon: "warning",
            resMessage: t("climateRiskModal.downloadError"),
          },
        });
      }).finally(() => {
        setIsLoading(false);
      })
    })
    .catch(() => {
      dispatch({
        type: AppAction.setSnackBarParams,
        payload: {
          title: "climateRiskModalDownload",
          titleIconColor: "yellow",
          titleIcon: "warning",
          resMessage: t("climateRiskModal.downloadError"),
        },
      });
    })
  };

  const openModal = (): void => {
    dispatch({ type: AppAction.setAnalyseModal, payload: { type: "2" } });
  };

  const closeLocations = (): void => {
    dispatch({ type: AppAction.setJobId, payload: undefined });
    dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
    // dispatch({ type: AppAction.setLeftPanel, payload: LeftPanel.Empty });
    dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
    dispatch({
      type: AppAction.setCurrentMultipleLocations,
      payload: undefined,
    });
    dispatch({ type: AppAction.setMapCenter, payload: undefined });
    dispatch({ type: AppAction.setSingleLocation, payload: undefined });
    dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
  };

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }

  const handleDownloadExcel = async () => {
    setIsLoading(true);
    setAnchorEl(null);
    downloadExcel(context, t)
    .finally(()=>{
      setIsLoading(false);
    })
  };

  const addLocation = () => {
    setLeftPanel(dispatch, LeftPanel.AddLocation);
  };

  return (
    <div className={styles.multiple}>
      <div className="overflow-auto">
        <div className={styles.title}>
          <div>{context.currentMultipleLocations?.name}</div>
          <div className="flex items-center">
            {/* <div
							className="tooltip z-50"
							data-tip={t("single.addLocation")}
						>
							<div className={styles.icon}>
								<AddLocationSvg onClick={addLocation} />
							</div>
						</div> */}
            <div>
              <Icon onClick={closeLocations} />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-items-start p-[16px]">
          <div className={styles.image}>
            <LocationsPinSvg />
          </div>
          <div className={styles.address}>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "5px" }}>
                  {t("multiple.aggregationBy")}
                </div>
                <div>{t("multiple.NumberOfLocations")}</div>
              </div>
              <div>
                {t("multiple.createTime")}:{" "}
                {context.currentMultipleLocations?.createTime}
              </div>
              {context.multipleLocationsAnalysed && (
                <div>
                  {t("multiple.analysisDate")}: {getDate()}
                </div>
              )}
              {/* <Button>{t("multiple.details")}</Button> */}
            </div>
          </div>
        </div>
        {context.multipleLocationsAnalysed ? (
          <Results />
        ) : (
          <div className={styles.locationCount}>
            {getCheckedCounts(context.currentMultipleLocations?.items)}{" "}
            {t("multiple.locations")}
          </div>
        )}
      </div>
      <div className={styles.buttons}>
        {context.multipleLocationsAnalysed && (
          <div className="w-fit mr-3">
            {/* <a
							href="/CatNet_Report_Multiple_Premium.pdf"
							target="_blank"
							rel="noreferrer"
						>
							<Button type="outlined">{t("buttons.download")}</Button>
						</a> */}
            <Button color="success" variant="outlined" onClick={handleClick} endIcon={
              isLoading ? (<CircularProgress color="success" size={24} />):(<div className="material-icons">keyboard_arrow_down</div>)}
              disabled={isLoading}>
              {t("buttons.download")}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: { 
                  padding: "6px 0",
                  marginTop: -6
                 },
              }}
              >
                {context.option !== "climate" && <MenuItem onClick={handleDownloadPdf}>
                  <ListItemIcon>
                  <PdfSvg className="w-[24px] h-[24px] fill-[#0009] mx-[5px] mt-[-5px]" />
                  </ListItemIcon>
                  <Typography variant="inherit">{t("buttons.PDF Report")}</Typography>
                </MenuItem>}
                <MenuItem onClick={handleDownloadExcel}>
                  <ListItemIcon>
                  <ExcelSvg className="w-[24px] h-[24px] fill-[#0009] mx-[5px] mt-[-5px]" />
                  </ListItemIcon>
                  <Typography variant="inherit">{t("buttons.Excel Sheet")}</Typography>
                </MenuItem>   
            </Menu>
          </div>
        )}
        <Tooltip
          title={t("buttons.noLocationsSelected")}
          placement="top"
          disableHoverListener={
            !(getCheckedCounts(context.currentMultipleLocations?.items) === "0")
          }
        >
          <div className="flex-1">
            <Button
              onClick={openModal}
              disabled={
                getCheckedCounts(context.currentMultipleLocations?.items) ===
                "0"
              }
              variant="contained"
              color="success"
              style={{ width: "100%" }}
            >
              {t("buttons.analyseSelected")}
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default MultiLocations;

import { Content } from "pdfmake/interfaces";

const createColorBlock = (color: string): Content => {
  return {
    canvas: [
      {
        type: "rect",
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        color
      }
    ],
  };
};

export default createColorBlock;

import text from "components/results/analysis-results/text";
import { AnalysisItem, LegendItem, Table } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import gap from "report/common/gap";
import page from "report/common/page";
import getTitle from "report/common/title";
import getTpye from "report/common/type";
import getFathom from "./detail/fathom";
import getOther from "./detail/other";
import fathom from "./fathom";
import other from "./other";
import getOverview from "./overview/overview";

const get = async (
  t: any,
  analysisItems: AnalysisItem[],
  legendItems: LegendItem[],
  count: number
): Promise<Content[]> => {
  const { sorted, idArray, legend } = other(analysisItems, legendItems);

  const data = fathom(analysisItems, legend, idArray);

  const { otherData } = Object.keys(sorted).reduce(
    (result, key) => {
      if (!key.includes("Fathom")) {
        result.otherData[key] = sorted[key];
      }
      return result;
    },
    { otherData: {} as Table }
  );

  return [
    getTpye(t("report.hazard"), 802),
    gap(30),
    await getOverview(t, sorted, count),
    gap(30),
    text(t, idArray),
    page(),
    Object.keys(data).length || Object.keys(otherData).length
      ? getTitle(t("report.analysis"))
      : null,
    gap(10),
    Object.keys(data).length ? getFathom(t, data, count) : null,
    Object.keys(otherData).length ? getOther(t, otherData, count) : null
  ];
};

export default get;

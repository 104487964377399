import { Button, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Toolbar } from "@mui/material";
import { getRole } from "api/layer";
import { AppContext } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteRoleModal from "./delete-role-modal/delete-role-modal";
import RoleEditModal from "./role-edit-modal/role-edit-modal";
import RolePermissionModal from "./role-permission-modal/role-permission-modal";
import RoleQuotaModal from "./role-quota-modal/role-quota-modal";
import styles from "./role.module.scss";

function Role() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [currentRole, setCurrentRole] = useState<any>();
	const [tableData, setTableData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openDeleteRoleModal, setOpenDeleteRoleModal] = useState<boolean>(false);
	const [openPermissionModal, setOpenPermissionModal] = useState<boolean>(false);
	const [openQuotaModal, setOpenQuotaModal] = useState<boolean>(false);

	const initData = async () => {
		setIsLoading(true);
		const param: any = {};

		const pageParam: any = {
			pageNo: page + 1,
			pageSize: rowsPerPage,
		};

		getRole(param, pageParam).then((res: any) => {
			if (res.code !== 200 || !res.data || !res.data.rows) {
				return;
			}

			setTableData(res.data.rows);
			setTotalCount(res.data.totalCount * 1 || 0);
			setIsLoading(false);
		});
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));

		setPage(0);
	};

	const handleDelete = (role: any) => {
		setCurrentRole(role);
		setOpenDeleteRoleModal(true);
	};

	const paginatedRoles = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const handleSave = () => {
		setOpen(false);
		initData();
	};

	useEffect(() => {
		initData();
	}, [page, rowsPerPage]);

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t("userSystem.role")}</div>
			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				{context.menu && context.menu.find((item) => item.id === "100002") && (
					<Button
						variant="contained"
						color="success"
						size="small"
						endIcon={<span className="material-icons">add</span>}
						onClick={() => {
							setOpen(true);
							setCurrentRole(null);
						}}
					>
						{t("userSystem.addRole")}
					</Button>
				)}
			</Toolbar>
			<div style={{ flex: 1, overflow: "auto" }}>
				<Table
					stickyHeader
					aria-label="sticky table"
					size="small"
					className={styles.table}
				>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "300px" }}>{t("userSystem.roleCode")}</TableCell>
							<TableCell style={{ width: "300px" }}>{t("userSystem.roleName")}</TableCell>
							<TableCell style={{ width: "300px" }}>{t("userSystem.roleDescription")}</TableCell>
							{context.menu && context.menu.find((item) => item.id === "100002") && <TableCell></TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={8}>
									<div className={styles.loading}>
										<div className="loading loading-spinner loading-lg"></div>
										<div>{t("layers.loading")}</div>
									</div>
								</TableCell>
							</TableRow>
						) : (
							paginatedRoles.map((role: any, index: number) => (
								<TableRow
									key={role.id}
									hover
									onMouseEnter={() => setHoveredRow(role.id)}
									onMouseLeave={() => setHoveredRow(null)}
									className={styles.row}
								>
									<TableCell>{role.roleCode}</TableCell>
									<TableCell>{role.roleName}</TableCell>
									<TableCell>{role.roleDesc}</TableCell>
									{context.menu && context.menu.find((item) => item.id === "100002") && (
										<TableCell>
											{hoveredRow === role.id && (
												<div className={styles.delete}>
													<div
														className={`${styles.icons} delete-set`}
														onClick={(evt) => evt.stopPropagation()}
													>
														<div
															className="tooltip tooltip-bottom"
															data-tip={t("tooltip.edit")}
														>
															<IconButton
																onClick={() => {
																	setOpen(true);
																	setCurrentRole(role);
																}}
															>
																<span className="material-icons">edit</span>
															</IconButton>
														</div>
														<div
															className="tooltip tooltip-bottom"
															data-tip={t("tooltip.permission")}
														>
															<IconButton
																onClick={() => {
																	setOpenPermissionModal(true);
																	setCurrentRole(role);
																}}
															>
																<span className="material-icons">settings</span>
															</IconButton>
														</div>
														<div
															className="tooltip tooltip-bottom"
															data-tip={t("tooltip.quota")}
														>
															<IconButton
																onClick={() => {
																	setOpenQuotaModal(true);
																	setCurrentRole(role);
																}}
															>
																<span className="material-icons">key</span>
															</IconButton>
														</div>
														<div
															className="tooltip tooltip-bottom"
															data-tip={t("tooltip.delete")}
														>
															<IconButton onClick={() => handleDelete(role)}>
																<span className="material-icons">delete</span>
															</IconButton>
														</div>
													</div>
												</div>
											)}
										</TableCell>
									)}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</div>
			<div style={{ marginLeft: "auto" }}>
				<TablePagination
					component="div"
					count={totalCount}
					rowsPerPageOptions={[10, 15, 25]}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={t("userSystem.rowsPerPage")}
					slotProps={{
						select: {
							native: true,
						},
					}}
				/>
			</div>
			{openDeleteRoleModal && currentRole && (
				<DeleteRoleModal
					data={currentRole}
					initData={initData}
					onClose={() => setOpenDeleteRoleModal(false)}
				/>
			)}
			{open && (
				<RoleEditModal
					open={open}
					onClose={() => setOpen(false)}
					onSave={handleSave}
					data={currentRole}
				/>
			)}
			{openPermissionModal && currentRole && (
				<RolePermissionModal
					data={currentRole}
					onClose={() => setOpenPermissionModal(false)}
				/>
			)}
			{openQuotaModal && currentRole && (
				<RoleQuotaModal
					data={currentRole}
					onClose={() => setOpenQuotaModal(false)}
				/>
			)}
		</div>
	);
}

export default Role;

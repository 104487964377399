import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Collapse.module.scss";
import Hail from "./hail/Hail";
import { R, HailAdditionalDetails } from "helpers/models";

interface Props {
  detail: R;
  hailAdditionalDetails: HailAdditionalDetails;
  handleCollapse: () => void;
}

export default function Collapse({
  detail,
  hailAdditionalDetails,
  handleCollapse
}: Readonly<Props>) {
  const [t] = useTranslation();

  const hail31 =
    hailAdditionalDetails &&
    hailAdditionalDetails["HailHazard-31-year-historical-data-set"];

  const hail64 =
    hailAdditionalDetails &&
    hailAdditionalDetails["HailHazard-64-year-historical-data-set"];

  return (
    <div>
      <Stack spacing={0.3} className={styles.detail}>
        {Object.keys(detail).reverse().map(e => {
          return (
            <Stack key={e} direction="row" spacing={2}>
              <div className={styles.key}>
                {e.includes(":")
                  ? (() => {
                      const translated = t("legend." + e.replace(/:/g, ""));
                      return translated.startsWith("legend.") ? e : translated;
                    })()
                  : (() => {
                      const translated = t("legend." + e);
                      return translated.startsWith("legend.") ? e : translated;
                    })()}
              </div>

              <div className={styles.value}>
                {t("legend." + detail[e]).startsWith("legend.")
                  ? detail[e]
                  : t("legend." + detail[e])}
              </div>
            </Stack>
          );
        })}
      </Stack>

      {hail31 &&
        <Hail name="Hail Hazard (31-year historical dataset)" data={hail31} />}

      {hail64 &&
        <Hail name="Hail Hazard (64-year historical dataset)" data={hail64} />}

      <Button
        variant="text"
        onClick={handleCollapse}
        color="success"
        className={styles.btn}
      >
        {t("buttons.collapse")}
      </Button>
    </div>
  );
}

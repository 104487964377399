import { Content } from "pdfmake/interfaces";
import { AnalysisItem, HailAdditionalDetails, R } from "helpers/models";
import layout from "report/common/layout";
import gap from "report/common/gap";
import hail from "./hail";

const get = (t: any, analysisItem: AnalysisItem): Content[] => {
  let detail = analysisItem.details as R;

  const hailAdditionalDetails =
    analysisItem.hailAdditionalDetails as HailAdditionalDetails;

  const hail31 =
    hailAdditionalDetails &&
    hailAdditionalDetails["HailHazard-31-year-historical-data-set"];

  const hail64 =
    hailAdditionalDetails &&
    hailAdditionalDetails["HailHazard-64-year-historical-data-set"];

  if (hailAdditionalDetails && detail) {
    detail = {
      "Intensity Value": analysisItem.intensity.toString(),
      ...detail
    };
  }

  const row = Object.keys(detail)
    .reverse()
    .map((e) => {
      return [
        e.includes(":")
          ? (() => {
              const translated = t("legend." + e.replace(/:/g, ""));
              return translated.startsWith("legend.") ? e : translated;
            })()
          : (() => {
              const translated = t("legend." + e);
              return translated.startsWith("legend.") ? e : translated;
            })(),
        {
          text: t("legend." + detail[e]).startsWith("legend.")
            ? detail[e]
            : t("legend." + detail[e]),
          opacity: 0.87
        }
      ];
    });

  return [
    {
      table: {
        headerRows: 0,
        widths: ["*", "*"],
        body: [...row]
      },
      layout: layout(1, false, 6)
    },
    gap(10),
    hail31 && hail(t, "Hail Hazard (31-year historical dataset)", hail31),
    gap(10),
    hail64 && hail(t, "Hail Hazard (64-year historical dataset)", hail64)
  ];
};

export default get;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Hail.module.scss";
import { R } from "helpers/models";


interface Prop {
  name: string;
  data: R;
}

export default function Hail({ name, data }: Readonly<Prop>) {
  const [t] = useTranslation();

  return (
    <div className={styles.hail}>
      <Accordion
        sx={{
          boxShadow: "none",
          backgroundColor: "transparent",
          margin: "0!important",
          "&::before": {
            display: "none"
          },
          borderBottom: "1px solid #ccc",
          borderRadius: "0!important"
        }}
      >
        <AccordionSummary
          className={styles.summary}
          expandIcon={
            <div className="material-icons">keyboard_arrow_right</div>
          }
          sx={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)"
            },
            padding: "0 0",
            minHeight: "34px!important",
            height: "36px!important",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: ".25px",
            marginTop: "6px",
          }}
        >
          {t("legend." + name)}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0 0",
            width: "100%",
          }}
        >
          <Stack spacing={0.3} className={styles.detail}>
            {Object.keys(data).map(e => {
              return (
                <Stack key={e} direction="row" spacing={2}>
                  <div className={styles.key}>
                    {t("legend." + e).startsWith("legend.")
                      ? e
                      : t("legend." + e)}
                  </div>
                  <div className={styles.value}>
                    {t("legend." + data[e]).startsWith("legend.")
                      ? data[e]
                      : t("legend." + data[e])}
                  </div>
                </Stack>
              );
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import { Content } from "pdfmake/interfaces";
import domtoimage from "dom-to-image";
import title from "report/common/title";

const get = async (text: string = "Map View"): Promise<Content[]> => {
  const image = await domtoimage.toPng(
    document.getElementsByClassName("amap-maps")[0]
  );

  return [
    title(text),
    {
      image,
      fit: [268, 268]
    }
  ];
};

export default get;

const font = {
  // Roboto: {
  //   normal: `${window.location.origin}/font/Roboto-Regular.ttf`,
  //   bold: `${window.location.origin}/font/Roboto-Regular.ttf`,
  //   italics: `${window.location.origin}/font/Roboto-Regular.ttf`,
  //   bolditalics: `${window.location.origin}/font/Roboto-Regular.ttf`
  // },

  微软雅黑: {
    normal: `${window.location.origin}/font/%E5%BE%AE%E8%BD%AF%E9%9B%85%E9%BB%91.ttf`,
    bold: `${window.location.origin}/font/%E5%BE%AE%E8%BD%AF%E9%9B%85%E9%BB%91.ttf`,
    italics: `${window.location.origin}/font/%E5%BE%AE%E8%BD%AF%E9%9B%85%E9%BB%91.ttf`,
    bolditalics: `${window.location.origin}/font/%E5%BE%AE%E8%BD%AF%E9%9B%85%E9%BB%91.ttf`
  }
};

export default font;

import pngArray from "report/common/png";

const iconArr = Object.keys(pngArray).reduce((acc, e) => {
  const key = e as keyof typeof pngArray;
  acc[key] = {
    width: 12,
    height: 12,
    align: "left",
    backgroundColor: {
      image: pngArray[key]
    }
  };
  return acc;
}, {} as Record<string, any>);

export default iconArr;

import { sortArray } from "components/results/analysis-results/sortUtil";
import { AnalysisItem, LegendItem, RR, RV, Table } from "helpers/models";
import transformAnalysis from "report/common/analysis";
import combine from "report/common/combine";
import transformRule from "report/common/rule";

const get = (analysisItems: AnalysisItem[], legendItems: LegendItem[]) => {
  const grouped = transformAnalysis(analysisItems);

  const arr = ["Outside", "No Data", "No Observation"];

  const idArray = Object.keys(grouped).filter((key) => {
    const values = Object.keys(grouped[key]);
    return values.every((value) => arr.includes(value));
  });

  const { data } = Object.keys(grouped).reduce(
    (result, key) => {
      // if (!idArray.includes(key) && !key.includes("Fathom")) {
      if (!idArray.includes(key)) {
        result.data[key] = grouped[key];
      }
      return result;
    },
    { data: {} as RV }
  );

  const legend: RR = transformRule(legendItems);

  const together: Table = combine(data, legend);

  const sorted = Object.entries(together)
    .sort(([a], [b]) => {
      return sortArray.indexOf(a) - sortArray.indexOf(b);
    })
    .reduce((acc: Table, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {});

  return { sorted, idArray, legend };
};

export default get;

import { R } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import gap from "report/common/gap";
import getData from "report/common/data";
import layout from "report/common/layout";

const get = (t: any, text: string, data: R): Content[] => {
  const row = Object.keys(data).map((e) => {
    return getData(
      t("legend." + e).startsWith("legend.") ? e : t("legend." + e),
      t("legend." + data[e]).startsWith("legend.")
        ? data[e]
        : t("legend." + data[e])
    );
  });

  return [
    {
      text: t("legend." + text),
      opacity: 1
    },
    gap(10),
    {
      table: {
        headerRows: 0,
        widths: ["*", "*"],
        heights: 9,
        body: [...row]
      },
      layout: layout(1, false, 6)
    }
  ];
};

export default get;

import getMatchingTitle from "components/fathomAnalyze/util";
import { DefendedData, R } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import layout from "report/common/layout";
import createColorBlock from "report/common/defend";
import gap from "report/common/gap";

const get = (
  t: any,
  ruleMap: R,
  text: string,
  defended: string
): Content => {
  if (!defended) {
    return "";
  }

  const defendedArray: DefendedData[] = JSON.parse(defended) as DefendedData[];

  const row = defendedArray.map((e) => {
    const matchingTitle = getMatchingTitle(e.returnPeriod);
    const color = ruleMap[matchingTitle];
    return [
      createColorBlock(color),
      {
        text: e.returnPeriod,
      },
      {
        text: parseFloat(e.depth) <= 0 ? "-" : e.depth,
        alignment: "right",
      }
    ];
  });

  return {
    stack: [
      text,
      gap(10),
      {
        table: {
          headerRows: 1,
          widths: [7, "*", "*"],
          body: [
            [
              {
                text: t("fathom.Return Period"),
                colSpan: 2,
              },
              {},
              {
                text: t("fathom.Depth(m)"),
                alignment: "right",
              }
            ],
            ...row
          ]
        },
        layout: layout(0, true, 6),
        opacity: 0.87,
        fontSize: 6
      }
    ]
  };
};

export default get;

import { AnalysisItem, LegendItem } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import page from "report/common/page";
import gap from "report/common/gap";
import getTitle from "report/common/title";
import getTpye from "report/common/type";
import getDetail from "./analysis/detail";
import getOverview from "./analysis/overview";
import text from "components/results/analysis-results/text";

const get = (
  t: any,
  analysisItems: AnalysisItem[],
  legendItems: LegendItem[]
): Content[] => {
  return [
    getTpye(t("report.hazard")),
    gap(30),
    getTitle(t("report.Analysis Overview")),
    getOverview(t, analysisItems, legendItems),
    gap(20),
    text(
      t,
      analysisItems
        .filter((e) => e.valueLabel === "No Data")
        .map((e) => e.layerId)
    ),
    page(),
    getTitle(t("report.analysis")),
    getDetail(t, analysisItems, legendItems)
  ];
};

export default get;

import { AppContext, AppAction } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import styles from "./expander.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  layerInfo: any;
  children: React.ReactNode;
}

function Expander(props: Props) {
  const [context, dispatch] = useContext(AppContext);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [t] = useTranslation();

  const getLegend = () => {
    let arr = ["No Data", "Outside", "No Observation", "Negligible"]
    if (arr.includes(props.layerInfo.valueLabel) || !context.layerLegend) {
      return null
    }
    let legend: any = context.layerLegend.find((item: any) => item.layerId === props.layerInfo.layerId);
    if (!(legend && legend.rules)) {
      return null
    }
    return legend.rules.map((c: any, j: number) => {
      let valueLabel = props.layerInfo.valueLabel
      if (props.layerInfo.layerId.includes("Fathom")) {
        if (valueLabel === "5 years" || valueLabel === "10 years" || valueLabel === "20 years" || valueLabel === "50 years") {
          valueLabel = "≤50 years"
        } else if (valueLabel === "1000 years") {
          valueLabel = "≥1000 years"
        }
      }

      if (!c.symbolizers || !c.symbolizers[0] || c.title !== valueLabel) {
        return null;
      }
      const raster = c.symbolizers[0]?.raster?.color;
      const polygon = c.symbolizers[0]?.polygon?.fill;
      const rasterOpacity = c.symbolizers[0]?.raster?.opacity;
      const polygonOpacity = c.symbolizers[0]?.polygon?.fillOpacity;
      const color = raster || polygon;
      const opacity = rasterOpacity || polygonOpacity;
      return (
        opacity > 0 &&
        (<span
          className={styles.cardColor}
          key={j}
          style={{
            backgroundColor: color,
            opacity
          }}
        ></span>)
      )
    });
  }

  return (
    <div className={styles.expander}>
      <div className={styles.header} onClick={() => {
        if (props.layerInfo.details && JSON.stringify(props.layerInfo.details) !== "{}" && props.layerInfo.valueLabel !== "No Data") {
          setExpanded(!expanded)
        }
      }}>
        <div
          className={styles.icon}
          style={{ visibility: (props.layerInfo.details && (JSON.stringify(props.layerInfo.details) === "{}" || props.layerInfo.valueLabel === "No Data")) ? "hidden" : undefined }}
        >
          <div className="material-icons">
            {expanded ? "keyboard_arrow_down" : "keyboard_arrow_right"}
          </div>
        </div>
        <div className="text-[#000000de] text-[14px] font-medium mr-[20px] flex-1">{t("layers.item." + props.layerInfo.name)}</div>
        <div className={styles.result}>
          <span className="whitespace-nowrap">{t("legend." + props.layerInfo.valueLabel)}</span>
          {getLegend()}
        </div>
      </div>
      {expanded && <div className="pb-[10px]">{props.children}</div>}
    </div >
  );
}

export default Expander;

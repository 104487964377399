import { Table } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import getSvg from "./util";
import { EChartOption } from "echarts";
import getOption from "./option";
import gap from "report/common/gap";
import getTitle from "report/common/title";

const get = async (
  t: any,
  grouped: Table,
  count: number
): Promise<Content[]> => {
  const option: EChartOption = getOption(t, grouped, count);

  const image = await getSvg(option, Object.keys(grouped).length);

  return [
    getTitle(t("report.Analysis Overview")),
    gap(10),

    getTitle(t("multiple.chartTitle"), 12),
    gap(10),

    // getTitle("Results for # / # locations", 10, "right"),

    {
      svg: image
    }
  ];
};

export default get;

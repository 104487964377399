import { GET, POST, PUT, DELETE, PATCH } from "request/service";

export const getLayer = async () => {
	// return await GET(`/risklayer-api/catnet/Layers`);
	return await GET(`/risklayer-api/catnet-cn/Layers`);
};

export const getCrsLayer = async () => {
	return await GET(`/risklayer-api/crs/riskTypeList`);
};

export const getLegend = async (layerID: string) => {
	return await GET(`/risklayer-api/catnet/layers/${layerID}/legend`);
};

export const getLegends = async (params: any) => {
	return await GET(`/risklayer-api/catnet/legends`, { params });
};

export const getAnalysis = async (params: any) => {
	return await GET(`/risklayer-api/catnet/layersAnalysis/analysis`, { params });
};

export const getStartAnalysis = async (params: any) => {
	return await POST(`/singlesite-api/riskAnalysis/startAnalysis`, params);
};

export const getCrsAnalysisResult = async (batchId: string) => {
	return await GET(`/risklayer-api/crs/lookup/${batchId}`);
};

export const getAmapGeo = async (params: any) => {
	return await GET(`/risklayer-api/amap/geo`, { params });
};

export const getAmapRegeo = async (params: any) => {
	return await GET(`/risklayer-api/amap/regeo`, { params });
};

export const downloadReport = async (params: any) => {
	return await POST(`/singlesite-api/report/exportReportPdf`, params, { responseType: "blob" });
};

export const downloadHazardPdf = async (params: any) => {
	return await POST(`/data-report/hazard/dl`, params, { responseType: "blob" });
};

export const getAuth = async () => {
	return await POST(`/auth/user/authorizeOnce`);
};

export const logout = async () => {
	return await POST(`/auth/user/logout`);
};

export const agreement = async () => {
	return await POST(`/auth/user/sign`);
};

export const downloadClimatePdf = async (params: any) => {
	return await POST(`/data-report/climate/dl`, params, { responseType: "blob" });
};

export const downloadClimateJodPdf = async (params: any) => {
	return await POST(`/data-report/climate/jod/dl`, params, { responseType: "blob" });
};

export const getLocationsets = async () => {
	return await GET(`/locationset-api/manage/locationsets`);
};

export const addLocationsets = async (params: any) => {
	return await POST(`/locationset-api/manage/locationsets`, params);
};

export const deleteLocationsets = async (id: any) => {
	return await DELETE(`/locationset-api/manage/locationsets/${id}`);
};

export const patchLocationsets = async (id: any, params: any) => {
	return await PATCH(`/locationset-api/manage/locationsets/${id}`, params);
};

export const getLocationsetsItems = async (id: any) => {
	return await GET(`/locationset-api/manage/locationsets/${id}`);
};

export const addLocationSetItems = async (id: any, params: any) => {
	return await POST(`/locationset-api/manage/locationsets/${id}/items`, params);
};

export const deleteLocationsetsItems = async (locationSetId: any, locationId: any) => {
	return await DELETE(`/locationset-api/manage/locationsets/${locationSetId}/items/${locationId}`);
};

export const patchLocationsetsItems = async (locationSetId: any, locationId: any, params: any) => {
	return await PATCH(`/locationset-api/manage/locationsets/${locationSetId}/items/${locationId}`, params);
};

export const startMultipleAnalysis = async (params: any) => {
	return await POST(`/locationset-api/asyncBatchJob/createMultipleChunks`, params);
};

export const batchGeo = async (params: any) => {
	return await POST(`/locationset-api/amap/batchGeo`, params);
};

export const batchGeoStatus = async (params: any) => {
	return await GET(`/locationset-api/amap/batchGeoStatus`, { params });
};

export const batchGeoResult = async (params: any) => {
	return await GET(`/locationset-api/amap/batchGeoResult`, { params });
};

export const getMultipleAnalysisStatus = async (jobId: string) => {
	return await GET(`/locationset-api/asyncBatchJob/checkStatus/${jobId}`);
};

export const getMultipleAnalysisResult = async (jobId: string, tabIndex: string) => {
	return await GET(`/locationset-api/asyncBatchJob/getApiResponseResultByTabId/${jobId}/TabGroup/${tabIndex}`);
};

export const getTenant = async (param: any, pageParam: any) => {
	return await POST(`/admin-api/system/tenant/listPage`, param, { params: pageParam });
};

export const addTenant = async (params: any) => {
	return await POST(`/admin-api/system/tenant/addTenant`, params);
};

export const updateTenant = async (params: any) => {
	return await POST(`/admin-api/system/tenant/update`, params);
};

export const removeTenant = async (id: any) => {
	return await GET(`/admin-api/system/tenant/remove/${id}`);
};

export const getTenantAllData = async () => {
	return await GET(`/admin-api/system/tenant/list`);
};

export const getRoleDataByTenant = async (params: any) => {
	return await GET(`/admin-api/system/role/listByTenantId`, { params });
};

export const getRoleData = async () => {
	return await GET(`/admin-api/system/role/list`);
};

export const getUserData = async (data: any, params: any) => {
	return await POST(`/admin-api/system/user/listUserPage`, data, { params });
};

export const addUserData = async (params: any) => {
	return await POST(`/admin-api/system/user/addUser`, params);
};

export const deleteUserData = async (params: any) => {
	// return await POST(`/admin-api/system/user/removeById`, params);
	return await POST(`/admin-api/system/user/batchDelete`, params);
};

export const editUserData = async (params: any) => {
	return await POST(`/admin-api/system/user/update`, params);
};

export const editBatchUserData = async (params: any) => {
	return await POST(`/admin-api/system/user/batchUpdate`, params);
};

export const importRecords = async (params: any) => {
	return await POST(`/admin-api/idaas/login-log/importDataByExcel`, params);
};

export const getLoginLogData = async (params: any) => {
	return await GET(`/admin-api/idaas/login-log/listPage`, { params });
};

export const getstatLoginCountData = async (params: any) => {
	return await GET(`/admin-api/idaas/login-log/statPageLoginCount`, { params });
};

export const downloadUserData = async (data: any) => {
	return await POST(`/admin-api/system/user/exportUserList?${new URLSearchParams(data).toString()}`, data, { responseType: "blob" });
};

export const downloadTenantData = async (data: any) => {
	return await POST(`/admin-api/system/tenant/exportTenantList?${new URLSearchParams(data).toString()}`, data, { responseType: "blob" });
};

export const downloadLoginStatistic = async (data: any) => {
	return await POST(`/admin-api/idaas/login-log/exportLoginStatistic?${new URLSearchParams(data).toString()}`, data, { responseType: "blob" });
};

export const downloadLoginList = async (data: any) => {
	return await POST(`/admin-api/idaas/login-log/exportLoginList?${new URLSearchParams(data).toString()}`, data, { responseType: "blob" });
};

export const batchUpdateWithNotMatch = async () => {
	return await GET(`/admin-api/idaas/login-log/batchUpdateWithNotMatch`);
};

export const deleteRecords = async (params: any) => {
	return await POST(`/admin-api/idaas/login-log/removeByIds`, params);
};

export const getRole = async (param: any, pageParam: any) => {
	return await POST(`/admin-api/system/role/listPage`, param, { params: pageParam });
};

export const addRole = async (param: any) => {
	return await POST(`/admin-api/system/role/save`, param);
};

export const updateRole = async (param: any) => {
	return await POST(`/admin-api/system/role/update`, param);
};

export const queryMenuIdsByRoleId = async (params: any) => {
	return await GET(`/admin-api/system/menu/queryMenuIdsByRoleId`, { params });
};

export const upgradeRoleMenuPermission = async (param: any) => {
	return await POST(`/admin-api/system/menu/upgradeRoleMenuPermission`, param);
};

export const removeRole = async (id: any) => {
	return await GET(`/admin-api/system/role/remove/${id}`);
};

export const getMenu = async () => {
	return await POST(`/admin-api/system/menu/list`);
};

export const queryQuotasIdsByRoleId = async (params: any) => {
	return await GET(`/admin-api/system/quotas/queryRoleQuotasByRoleId`, { params });
};

export const upgradeRoleQuotasPermission = async (param: any) => {
	return await POST(`/admin-api/system/quotas/upgradeRoleQuotasPermission`, param);
};

export const getAnalysisRecord = async (data: any, params: any) => {
	return await POST(`/tracking-api/analysis/listPage`, data, { params });
};

export const downloadAnalysisRecord = async (data: any) => {
	return await POST(`/tracking-api/analysis/exportEventList?${new URLSearchParams(data).toString()}`, data, { responseType: "blob" });
};

export const getAnalysisStatistics = async (params: any) => {
	return await POST(`/tracking-api/analysis/statPageEventCount`, undefined, { params });
};

export const downloadAnalysisStatistics = async (data: any) => {
	return await POST(`/tracking-api/analysis/exportEventStatistic?${new URLSearchParams(data).toString()}`, data, { responseType: "blob" });
};

export const layerStatistics = async (data: any) => {
	return await POST(`/tracking-api/analysis/trackEvent`, data);
};

export const getRiskAnalysis = async (params: any) => {
	return await GET(`/singlesite-api/riskAnalysis/analysis`, { params });
};

// export const getLayerCounter = async (params: any) => {
// 	return await POST(`/admin-api/settle/queryItemCounter`, undefined, { params });
// };

export const getLayerCounter = async (params: any) => {
	return await POST(`/singlesite-api/riskAnalysis/queryItemCounter`, undefined, { params });
}

export const getLocationValidateItemCounter = async (data: any) => {
	return await POST(`/locationset-api/asyncBatchJob/validateItemCounter`, data);
};

export const getSingleValidateItemCounter = async (data: any) => {
	return await POST(`/singlesite-api/riskAnalysis/validateItemCounter`, data);
};

import { AnalysisItem, LegendItem } from "helpers/models";

export const sortArray = [
  "FL_Fluvial_Global_Fathom",
  "FL_Pluvial_Global_Fathom",
  "FL_Coastal_Global_Fathom",
  "FL_Fluvial_Official",
  "FL_Fluvial_SwissRe",
  "FL_Surge_SwissRe",
  "FL_Pluvial_SwissRe",
  "EQ_LocalSoilCondition_Global_SwissRe",
  "EQ_Bedrock_Global_SwissRe",
  "EQ_Tsunami_SwissRe",
  "WS_Windspeed_Global_SwissRe",
  "CS_Hail_Global_SwissRe",
  "CS_Tornado_Global_SwissRe",
  "EQ_Landslide_Global_SwissRe",
  "WF_Wildfire_Global_SwissRe",
  "CS_Lightning_Global_SwissRe",
  "VO_AshThickness_Global_SwissRe",
  "WF_DistToBush_AUS_SwissRe",
  "DR_Subsidence_France_SwissRe"
];

function sortItem(legendItems: string | LegendItem[] | AnalysisItem[]) {
  if (Array.isArray(legendItems)) {
    legendItems.sort((a: LegendItem | AnalysisItem, b: LegendItem | AnalysisItem) => {
      const indexA = sortArray.indexOf(a.layerId);
      const indexB = sortArray.indexOf(b.layerId);
      if (indexA === -1 || indexB === -1) {
        return 0;
      }
      return indexA - indexB;
    });
  }
}

export default sortItem;

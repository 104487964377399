import styles from "./multipleAgenda.module.scss";
import { AppContext } from "helpers/context";
import { MapContext } from "helpers/map";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCounts, getCheckedCounts } from "helpers/helpers";
import { colorByTiv } from "helpers/locations";
import { ReactComponent as MyLocaltionSvg } from "assets/my_localtion.svg";
import { ReactComponent as MapLegendPinSvg } from "assets/icon_map_legend_pin.svg";
import * as turf from '@turf/turf';

function MultipleAgenda() {
    const [context, dispatch] = useContext(AppContext);
    const { AMap, map } = useContext(MapContext);
    const [t] = useTranslation();
    const [checkedCounts, setCheckedCounts] = useState(getCheckedCounts(context.currentMultipleLocations?.items))
    const [legendArr, setLegendArr] = useState<any[]>([]);

    const handleClickMyLocation = () => {
        if (context.currentMultipleLocations && context.currentMultipleLocations.items) {
            let bounds: any = [];
            context.currentMultipleLocations.items.map((item: any) => {
                if (item.checked) {
                    bounds.push([item.longitude, item.latitude]);
                }
            });
            if (bounds.length === 0) return
            let bbox: any = turf.bboxPolygon(turf.bbox(turf.multiPoint(bounds)));
            var myBounds = new AMap.Bounds([bbox.bbox[2], bbox.bbox[3]], [bbox.bbox[0], bbox.bbox[1]]);
            console.log(myBounds);
            map?.setBounds(myBounds, true, [20, 20, 20, 20]);
        }
    }

    const getLegendArrData = (total: number) => {
        const base = Math.pow(total, 1 / 8);
        let current = 1;
        const thresholds: any = [{
            color: "#fff8a1",
            value: 1
        }];

        let color = ["#fff8a1", "#ffcb8a", "#ff9c86", "#ee6c91", "#cb419c", "#9c2499", "#61168e", "#001789"];
        for (let i = 1; i < color.length; i++) {
            current = current * base;
            thresholds.push({
                color: color[i],
                value: current
            });
            thresholds[i - 1].endValue = current;
        }

        thresholds[7].endValue = total;
        return thresholds;
    }

    useEffect(() => {
        setCheckedCounts(getCheckedCounts(context.currentMultipleLocations?.items))
    }, [context.currentMultipleLocations && context.currentMultipleLocations.items])

    useEffect(() => {
        let arr = getLegendArrData(context.currentMultipleLocations?.itemCount || 0)
        setLegendArr(arr.reverse())
    }, [context.currentMultipleLocations && context.currentMultipleLocations.itemCount])

    return (
        <div className={styles.multipleAgenda}>
            <div className={styles.header}>
                <div className={styles.text}>{context.currentMultipleLocations!.name}</div>
                <MyLocaltionSvg
                    id="loc"
                    className="w-[16px] h-[16px] fill-[#0009] cursor-pointer"
                    onClick={handleClickMyLocation}
                />
            </div>

            <div>
                <div className={styles.subtitle}>({t("legend.showingLocations")} {checkedCounts}/{context.currentMultipleLocations?.itemCount})</div>
                {context.currentMultipleLocations?.itemCount && (context.currentMultipleLocations?.itemCount > 9) ?
                    (<>{legendArr.map((item, index) => (
                        <div
                            key={index}
                            className={styles.legends}

                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    height: "21px"
                                }}
                            >
                                <MapLegendPinSvg
                                    className="w-[12px] h-[16px] mb-[3px] mr-[8px]"
                                    style={{
                                        fill: item.color
                                    }}
                                />
                                <div>
                                    <span>{item.value.toFixed()}</span>
                                    <span> - </span>
                                    <span>{item.endValue.toFixed()}</span>&nbsp;
                                    <span>{index === 0 ?
                                        `(> ${Math.floor(item.value / item.endValue * 100)}%)` :
                                        `(${item.value.toFixed() == 1 ? 0 : Math.floor((item.value / (context.currentMultipleLocations?.itemCount || 0)) * 100)} - ${item.endValue.toFixed() == 1 ? 0 : Math.floor((item.endValue / (context.currentMultipleLocations?.itemCount || 0)) * 100)}%)`}</span>
                                </div>
                            </div>
                        </div>
                    ))}</>) : (<div className={styles.legend}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "21px"
                            }}
                        >
                            <MapLegendPinSvg className="w-[12px] h-[16px] mb-[3px] fill-[#001789]" />
                            <span>100%({context.currentMultipleLocations?.itemCount})</span>
                        </div>
                    </div>)}
            </div>
        </div>
    );
}

export default MultipleAgenda;

import { Table } from "helpers/models";

function paginate(grouped: Table, count: number = 9) {
  const keys = Object.keys(grouped);
  const result: Table[] = [];
  
  for (let i = 0; i < keys.length; i += count) {
    const pageData: Table = {};
    const slice = keys.slice(i, i + count);

    slice.forEach((key) => {
      pageData[key] = grouped[key];
    });
    result.push(pageData);
  }
  return result;
}

export default paginate;

import { Detail } from "helpers/models";

const getDefended = (e: Detail): string => {
  if (!e || !e.Defended) {
    return "";
  }

  const defendedData = JSON.parse(e.Defended);
  const FloodDepthItem = defendedData.find(
    (item: { depth: string }) => parseFloat(item.depth) > 0
  );
  const returnPeriod = FloodDepthItem && FloodDepthItem.returnPeriod;

  const defended = returnPeriod
    ? returnPeriod + " years"
    : defendedData[0].valueLabel;
  return defended;
};

export default getDefended;

const get = (text: string) => {
  return "catnet@swissre.com";
  // if (!text || text.length > 1000) {
  //   return '';
  // }
  // const regex = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  // const email = text.match(regex);
  // if (!email || !email.length) {
  //   return "";
  // }
  // return email[0];
};

export default get;

import { AnalysisItem, Detail, Fathom, Layer, RR } from "helpers/models";
import { addColor } from "report/common/combine";
import getDefended from "report/common/defended";
import groupByLayerId from "report/common/group";

const get = (analysisItems: AnalysisItem[], legend: RR, idArray: string[]) => {
  const fathomArray = analysisItems.filter(
    (item) =>
      item.layerId.includes("Fathom") && idArray.indexOf(item.layerId) === -1
  );

  const cal: Fathom[] = fathomArray.map((e: AnalysisItem) => {
    const defended: string = getDefended(e.details as Detail);

    return {
      layerId: e.layerId,
      defended,
      undefended: e.valueLabel
    };
  });

  const grouped: Layer = groupByLayerId(cal);

  const data = addColor(grouped, legend);

  return data;
};

export default get;

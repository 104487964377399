import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Collapse from "./collapse/Collapse";
import { R } from "helpers/models";

interface Props {
  detail: R;
  hailAdditionalDetails?: any;
}

export default function Expand({
  detail,
  hailAdditionalDetails
}: Readonly<Props>) {
  const [t] = useTranslation();

  const [isExpand, setIsExpand] = useState(false);

  const handleExpand = () => {
    setIsExpand(!isExpand);
  };

  return (
    <div>
      {!isExpand
        ? <Button variant="text" onClick={handleExpand} color="success">
            {t("buttons.expand")}
          </Button>
        : <Collapse
            detail={detail}
            hailAdditionalDetails={hailAdditionalDetails}
            handleCollapse={handleExpand}
          />}
    </div>
  );
}

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { DefendedData } from "helpers/models";
import { useTranslation } from "react-i18next";
import styles from "./Defended.module.scss";

interface Prop {
  data: DefendedData[];
}

export default function Defended({ data }: Readonly<Prop>) {
  const [t] = useTranslation();

  return (
    <div>
      <Table size="small">
        <TableHead
          sx={{
            color: "gery",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "16px",
            letterSpacing: "0.4px",
            opacity: 0.6
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                width: "40%",
                fontSize: "12px",
                fontWeight: "600"
              }}
            >
              {t("fathom.Return Period")}
            </TableCell>
            <TableCell
              sx={{
                width: "40%",
                fontSize: "12px",
                fontWeight: "600"
              }}
            >
              {t("fathom.Depth(m)")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) =>
            <TableRow
              key={row.returnPeriod}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                background:
                  parseFloat(row.depth) <= 0 ? "rgba(0,0,0,.08)" : "transparent"
              }}
            >
              <TableCell scope="row" sx={{ display: "flex", fontSize: "12px" }}>
                <div
                  className={styles.colorDiv}
                  style={{ background: row.color }}
                />
                {row.returnPeriod}
              </TableCell>
              <TableCell align="left" sx={{ fontSize: "12px" }}>
                {parseFloat(row.depth) <= 0 ? "-" : row.depth}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

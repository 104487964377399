import { Table } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import gap from "report/common/gap";
import getLayer from "report/common/layer";
import row from "./row";

const get = (t: any, grouped: Table, count: number): Content[] => {
  const layerArray = Object.keys(grouped).map((e) => {
    return [
      getLayer(t, e, 10, 1),
      gap(10),
      row(t, grouped[e], count),
      gap(30)
    ];
  });

  return [layerArray];
};

export default get;

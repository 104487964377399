import { Content } from "pdfmake/interfaces";

const get = (top: number): Content => {
  return {
    text: "",
    margin: [0, top, 0, 0]
  };
};

export default get;

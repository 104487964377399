import { ReactComponent as NotAnalysisSvg } from "assets/notAnalysis.svg";
import FathomAnalyze from "components/fathomAnalyze/FathomAnalyze";
import Icon from "components/icon/icon";
import Svg from "components/svg/svg";
import { AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Polygon, Raster } from "../../../helpers/models";
import styles from "./analysis-results.module.scss";
import sortItem from "./sortUtil";
import Expand from "./expand/Expand";
import text from "./text";

export default function AnalysisResults() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  if (!context.analysisData) {
    return (
      <div className="text-center">
        <NotAnalysisSvg className="w-[60px] h-[60px] mx-auto  mb-4" />
        <div style={{ color: "var(--gray-text)" }}>
          {t("single.notAnalysis")}
        </div>
      </div>
    );
  }

  const legendItems = context.analysisData?.legendItems;
  const analysisItems = context.analysisData?.analysisItems;

  sortItem(legendItems);

  sortItem(analysisItems);

  if (typeof legendItems === "string" || typeof analysisItems === "string") {
    return (
      <div className="text-center">
        <div className="loading loading-spinner loading-lg"></div>
        <div>{t("single.alalyseLoadingContent")}</div>
      </div>
    );
  }

  const noData = analysisItems.filter((e) => e.valueLabel === "No Data");

  const legendItemsData =
    noData && noData.length
      ? legendItems.filter(
          (item) =>
            !noData.some((noDataItem) => noDataItem.layerId === item.layerId)
        )
      : legendItems;

  return (
    <div className="text-xs">
      {legendItemsData &&
        !!legendItemsData.length &&
        legendItemsData.map((e, i) => {
          const item = analysisItems.filter((r) => r.layerId === e.layerId);

          const valueLabel =
            (item && item[0]?.valueLabel) === "> 500 years"
              ? "> 500 years (US)"
              : item[0]?.valueLabel;
          const intensity = item && item[0]?.intensity;
          let detail = item && item[0]?.details;

          const hailAdditionalDetails = item && item[0]?.hailAdditionalDetails;

          if (hailAdditionalDetails && detail) {
            detail = {
              "Intensity Value": intensity,
              ...detail
            }
          }

          return (
            <div
              key={e.layerId}
              className="border-b border-[#cccccc] border-solid last:border-0 pt-4 pl-2"
            >
              {e.layerId.includes("Fathom") ? (
                <FathomAnalyze
                  key={e.layerId}
                  legendItem={e}
                  analysisItem={item[0]}
                />
              ) : (
                <>
                  <div
                    className="flex items-left items-center font-bold"
                    data-tip={t("layers.item." + e.title)}
                  >
                    <div>{Svg({ icon: e.layerId })()}</div>
                    <div className="truncate max-w-100 ml-2" style={{fontSize: "13px", fontWeight: "600"}}>
                      {t("layers.item." + e.title)}
                    </div>
                  </div>
                  <div
                    className="flex justify-between gap-2 pb-6"
                    style={{ color: "var(--gray-text)" }}
                  >
                    <div className={styles.legendItem}>
                      <div className="text-start h-[36px] leading-[36px]">
                        {t("single.HazardRisk")}
                      </div>
                      {valueLabel === "Outside" ||
                      valueLabel === "No Data" ||
                      valueLabel === "No Observation" ||
                      valueLabel === undefined ? (
                        <div id={e.layerId} className={`mb-[3px] pt-[6px]`}>
                          <div
                            style={{ backgroundColor: "grey" }}
                            className={`${styles.lagend} opacity-50 w-full flex-none`}
                          ></div>
                        </div>
                      ) : (
                        <div
                          id={e.layerId}
                          className="flex items-center h-[10px] w-full mb-[2px] pt-[6px]"
                        >
                          {e.rules?.map((c, j: number) => {
                            if (!c.symbolizers || !c.symbolizers[0]) {
                              return null;
                            }
                            const raster = (c.symbolizers[0] as Raster)?.raster
                              ?.color;
                            const polygon = (c.symbolizers[0] as Polygon)
                              ?.polygon?.fill;
                            const rasterOpacity = (c.symbolizers[0] as Raster)
                              ?.raster?.opacity;
                            const polygonOpacity = (c.symbolizers[0] as Polygon)
                              ?.polygon?.fillOpacity;
                            const color = raster || polygon;
                            const opacity = rasterOpacity || polygonOpacity;
                            return (
                              opacity > 0 && (
                                <div
                                  key={j}
                                  style={{ backgroundColor: color, opacity }}
                                  className={`${styles.lagend} ${
                                    c.title === valueLabel ? styles.active : ""
                                  }`}
                                >
                                  {/* {c.title} {c.symbolizers[0].raster.color} */}
                                </div>
                              )
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="flex-1 content-end text-start">
                      <div className="text-start h-[36px] leading-[36px]">
                        {t("legend.Return Period")}
                      </div>
                      <div>
                        {t("legend." + valueLabel)}
                      </div>
                    </div>
                    <div className="max-w-50 flex flex-col items-start">
                      <div className="flex w-full whitespace-nowrap text-ellipsis items-center">
                        {t("single.Intensity")}：
                        <div
                          className={`tooltip tooltip-left ${styles.tooltip}`}
                          data-tip={t("layers.strength")}
                        >
                          <Icon icon="info" type="outlined"></Icon>
                        </div>
                      </div>
                      <div>{intensity === 0 ? 1 : intensity}</div>
                    </div>
                  </div>
                  {detail && (
                    <Expand
                      detail={detail}
                      hailAdditionalDetails={hailAdditionalDetails}
                    />
                  )}
                </>
              )}
            </div>
          );
        })}

      {noData.length > 0 && <div className="m-2">{text(t, analysisItems.filter((e) => e.valueLabel === "No Data").map(e=>e.layerId))}</div>}
    </div>
  );
}

import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Checkbox, Tooltip } from "@mui/material";
import { ReactComponent as IconTable } from "assets/icon_table_th.svg";
import { ReactComponent as NotAnalysisSvg } from "assets/notAnalysis.svg";
import { ReactComponent as MapAnalysisSvg } from "assets/map_analysis.svg";
import Chart from "components/chart/chart";
import Icon from "components/icon/icon";
import Svg from "components/svg/svg";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./hazard-multiple-results.module.scss";
import CustomAccordion from "components/accordion/Accordion";

export default function HazardMultipleResults() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [activeLegend, setActiveLegend] = useState(0);
	const [defendeActiveLegend, setDefendeActiveLegend] = useState(0);
	const [chartOption, setChartOption] = useState<any>(undefined);
	const [chartHeight, setChartHeight] = useState(0);
	const [tableDatas, setTableDatas] = useState<any[]>([]);
	const [noData, setNoData] = useState<any[]>([]);
	const [sortData, setSortData] = useState<any[]>([]);
	const [rowCount, setRowCount] = useState(0);
	const [active, setActive] = useState<"sortIndex" | "value" | "share">("sortIndex");
	const [direction, setDirection] = useState<{ sortIndex: "asc" | "desc"; value: "asc" | "desc"; share: "asc" | "desc" }>({ sortIndex: "asc", value: "asc", share: "asc" });
	const [defendeTableDatas, setDefendeTableDatas] = useState<any[]>([]);
	const [defendeNoData, setDefendeNoData] = useState<any[]>([]);
	const [defendeSortData, setDefendeSortData] = useState<any[]>([]);
	const [defendeRowCount, setDefendeRowCount] = useState(0);
	const [defendeActive, setDefendeActive] = useState<"sortIndex" | "value" | "share">("sortIndex");
	const [defendeDirection, setDefendeDirection] = useState<{ sortIndex: "asc" | "desc"; value: "asc" | "desc"; share: "asc" | "desc" }>({ sortIndex: "asc", value: "asc", share: "asc" });
	const [noAnalysisLocation, setNoAnalysisLocation] = useState<any[]>([])

	const handleGetLocationNum = (item: any) => {
		let num = sortData[activeLegend].filter((i: any) => i.checked && i.valueLabel === item.valueLabel).length
		return num === 0 ? "-" : num
	}

	const handleGetLocationPercent = (item: any) => {
		let num = sortData[activeLegend].filter((i: any) => i.checked && i.valueLabel === item.valueLabel).length
		let total = sortData[activeLegend].filter((i: any) => i.checked).length
		return num === 0 ? "-" : (toFixedTwo(num / (total + noAnalysisLocation.length) * 100) + "%")
	}

	const handleDeleteNoData = () => {
		context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((location: any) => {
			noAnalysisLocation.forEach(item => {
				let itemId = item.id.split("-").join("");
				let locationId = location.id.split("-").join("");
				if (itemId === locationId) {
					location.checked = false;
				}
			})
			return location
		})
		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: context.currentMultipleLocations,
		});
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		let pointIds = sortData[activeLegend].map((data: any) => data.pointId.split("-").join(""));

		context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((location: any) => {
			if (event.target.checked && pointIds.includes(location.id.split("-").join(""))) {
				location.checked = true;
			} else {
				location.checked = false;
			}
			return location
		})

		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: context.currentMultipleLocations,
		});
	}

	const toggleLocation = (evt: any, item: any) => {
		let pointIds = sortData[activeLegend].filter((data: any) => data.valueLabel === item.valueLabel)
			.map((data: any) => data.pointId.split("-").join(""));

		context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((location: any) => {
			if (pointIds.includes(location.id.split("-").join(""))) {
				location.checked = evt.target.checked;
			}
			return location
		})

		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: context.currentMultipleLocations,
		});
	}

	const handleSort = (property: "sortIndex" | "value" | "share") => {
		if (!tableDatas.length) {
			return;
		}

		if (!property) {
			return;
		}

		setActive(property);
		direction[property] = direction[property] === "asc" ? "desc" : "asc";
		setDirection(direction);

		handleSetSortData(JSON.parse(JSON.stringify(tableDatas)), property);
	};

	const handleSetSortData = (tableData: any, property = active, activeIndex = activeLegend) => {
		if (!tableData || !tableData.length) return;
		let sortArr = [...tableData];
		if (sortArr.length === 0) return;
		let key = property;
		if (key === "share") {
			key = "value";
		}
		sortArr[activeIndex].sort((a: any, b: any) => {
			if (!a || !b) {
				return 0;
			}
			const aValue = a?.[key];
			const bValue = b?.[key];
			if (aValue === undefined) {
				return direction[property] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[property] === "asc" ? 1 : -1;
			}
			if (aValue < bValue) {
				return direction[property] === "asc" ? 1 : -1;
			}
			if (aValue > bValue) {
				return direction[property] === "asc" ? -1 : 1;
			}
			return 0;
		});
		setRowCount(sortArr[activeIndex].filter((item: any) => item.checked).length);
		setSortData([...sortArr]);
	};

	const handleCheckedStatus = (item: any) => {
		let total = 0,
			num = 0;
		sortData[activeLegend].map((i: any) => {
			if (i.valueLabel == item.valueLabel) {
				total++;
				if (i.checked) {
					num++
				}
			}
		})
		return (num > 0) && (num === total);
	}

	const handleIndeterminateStatus = (item: any) => {
		let total = 0,
			num = 0;
		sortData[activeLegend].map((i: any) => {
			if (i.valueLabel == item.valueLabel) {
				total++;
				if (i.checked) {
					num++
				}
			}
		})
		return (num > 0) && (num < total);
	}

	const noDataText = () => {
		if (noData.length > 0) {
			let text = t("multiple.noData");
			noData.forEach((item: any, index: number) => {
				if (index === 0) {
					text += t(`layerId.${item.layerId}`);
				} else if (index === noData.length - 1) {
					text += t("multiple.and") + t(`layerId.${item.layerId}`);
				} else {
					text += t("multiple.comma") + t(`layerId.${item.layerId}`);
				}
			});
			return text + t("multiple.period");
		}
	};

	const handleLegendClick = (index: number) => {
		let pointIds = sortData[activeLegend].map((data: any) => data.pointId.split("-").join(""));

		context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((location: any) => {
			if (pointIds.includes(location.id.split("-").join(""))) {
				location.checked = true;
			} else {
				location.checked = false;
			}
			return location
		})

		setActiveLegend(index);

		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: context.currentMultipleLocations,
		});
	};


	const handleGetDefendeLocationNum = (item: any) => {
		let num = defendeSortData[defendeActiveLegend].filter((i: any) => i.checked && i.defendeValueLabel === item.defendeValueLabel).length
		return num === 0 ? "-" : num
	}

	const handleGetDefendeLocationPercent = (item: any) => {
		let num = defendeSortData[defendeActiveLegend].filter((i: any) => i.checked && i.defendeValueLabel === item.defendeValueLabel).length
		let total = defendeSortData[defendeActiveLegend].filter((i: any) => i.checked).length
		return num === 0 ? "-" : (toFixedTwo(num / (total + noAnalysisLocation.length) * 100) + "%")
	}

	const defendeToggleLocation = (evt: any, item: any) => {
		let pointIds = defendeSortData[defendeActiveLegend].filter((data: any) => data.defendeValueLabel === item.defendeValueLabel)
			.map((data: any) => data.pointId.split("-").join(""));

		context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((location: any) => {
			if (pointIds.includes(location.id.split("-").join(""))) {
				location.checked = evt.target.checked;
			}
			return location
		})

		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: context.currentMultipleLocations,
		});
	}

	const handleDefendeSort = (property: "sortIndex" | "value" | "share") => {
		if (!defendeTableDatas.length) {
			return;
		}

		if (!property) {
			return;
		}

		setDefendeActive(property);
		let defendeDirectionObj = { ...defendeDirection }
		defendeDirectionObj[property] = defendeDirectionObj[property] === "asc" ? "desc" : "asc";
		setDefendeDirection(defendeDirectionObj);

		handleSetDefendeSortData(JSON.parse(JSON.stringify(defendeTableDatas)), property);
	};

	const handleSetDefendeSortData = (tableData = defendeTableDatas, property = defendeActive, activeIndex = defendeActiveLegend) => {
		if (!tableData || !tableData.length) return;
		let sortArr = [...tableData];
		if (sortArr.length === 0) return;
		let key = property;
		if (key === "share") {
			key = "value";
		}
		console.log(sortArr, activeIndex);


		sortArr[activeIndex].sort((a: any, b: any) => {
			if (!a || !b) {
				return 0;
			}
			const aValue = a?.[key];
			const bValue = b?.[key];
			if (aValue === undefined) {
				return defendeDirection[property] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return defendeDirection[property] === "asc" ? 1 : -1;
			}
			if (aValue < bValue) {
				return defendeDirection[property] === "asc" ? 1 : -1;
			}
			if (aValue > bValue) {
				return defendeDirection[property] === "asc" ? -1 : 1;
			}
			return 0;
		});

		setDefendeRowCount(sortArr[activeIndex].filter((item: any) => item.checked).length);
		setDefendeSortData([...sortArr]);
	};

	const handleDefendeCheckedStatus = (item: any) => {
		let total = 0,
			num = 0;
		defendeSortData[defendeActiveLegend].map((i: any) => {
			if (i.defendeValueLabel == item.defendeValueLabel) {
				total++;
				if (i.checked) {
					num++
				}
			}
		})
		return (num > 0) && (num === total);
	}

	const handleDefendeIndeterminateStatus = (item: any) => {
		let total = 0,
			num = 0;
		defendeSortData[defendeActiveLegend].map((i: any) => {
			if (i.defendeValueLabel == item.defendeValueLabel) {
				total++;
				if (i.checked) {
					num++
				}
			}
		})
		return (num > 0) && (num < total);
	}

	const toFixedTwo = (num: number) => {
		return num % 1 === 0 ? num : num.toFixed(2);
	};

	const handleSetOptions = (tableData: any, activIndex = activeLegend) => {
		let arr = [...tableData].map((item: any) => {
			return item.filter((data: any) => data.checked)
		});
		arr.forEach((dataItem: any) => {
			if (noAnalysisLocation.length > 0) {
				let obj = { ...dataItem[0] };
				noAnalysisLocation.forEach(v => {
					obj.pointId = v.id;
					obj.valueLabel = "Not Analyzed";
					obj.sortIndex = -1;
					obj.checked = true;
					obj.value = 1;
					obj.itemStyle = {
						"color": "#f2f2f2",
						"opacity": 1
					};
					dataItem.push({ ...obj })
				})
			}
			const valueMap: any = {};
			dataItem.forEach((item: any) => {
				const label = item.valueLabel;
				if (valueMap[label] === undefined) {
					valueMap[label] = 1; // First occurrence
					item.value = dataItem.filter((i: any) => i.valueLabel === label).length; // Count total occurrences
				} else {
					item.value = 0; // Subsequent occurrences
				}
			});
		})

		let series: any = [];
		let Xnum = arr[activIndex] ? arr[activIndex].length : 1;
		let Ynum = arr.length;
		setChartHeight(Ynum);
		for (let i = 0; i < Xnum; i++) {
			let dataArr = [];
			for (let j = 0; j < Ynum; j++) {
				if (arr[j]) {
					dataArr.unshift(arr[j][i]);
				}
			}

			series.push({
				name: i,
				type: "bar",
				stack: "total",
				barWidth: 24,
				// emphasis: {
				//     focus: 'self'
				// },
				data: dataArr,
			});
		}

		const option: any = {
			tooltip: {
				confine: true,
				trigger: "item",
				axisPointer: {
					type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
				},
				backgroundColor: "rgba(0,0,0,0.6)",
				borderColor: "rgba(0,0,0,0.6)",
				formatter: function (params: any, ticket: any) {
					return `<div>
                            <p style="color: #fff;font-size:14px;">${t("legend." + params.data.valueLabel)}</p>    
                            <p style="color: #fff;font-size:14px;margin: 5px 0;">${t("layerId." + params.data.layerId)}</p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t(
						"locations.table.locations"
					)}</span> <span style="color: #fff">${params.data.value}</span></p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t(
						"locations.shareOfNumberOfLocations"
					)}</span> <span style="color: #fff">${toFixedTwo((params.data.value / Xnum) * 100)}%</span></p>    
                        </div>`;
				},
			},
			grid: {
				left: 10,
				right: 10,
				bottom: 10,
				top: 10,
				containLabel: true,
			},
			xAxis: {
				type: "value",
				axisLine: {
					show: true,
				},
				minInterval: 1,
				max: Xnum,
			},
			yAxis: {
				type: "category",
				axisTick: {
					show: false,
				},
				axisLabel: {
					show: false,
				},
				axisLine: {
					show: true,
					onZero: true,
					lineStyle: {
						color: "#fff",
						shadowOffsetX: -1,
						shadowColor: "#333",
					},
				},
			},
			series: series,
		};

		setChartOption(option);
	}

	const showInfo = () => {
		dispatch({ type: AppAction.setShowLocationAnalysisDetails, payload: true });
	};

	useEffect(() => {
		if (context.currentMultipleLocations && context.currentMultipleLocations.items && context.hazardMultipleAnalysisResult) {
			let hazardMultipleAnalysisResult: any[] = [];
			const noData = context.currentMultipleLocations.items.filter((location: any) => {
				let is = true;
				hazardMultipleAnalysisResult = context.hazardMultipleAnalysisResult!.map((item: any) => {
					let id = item.pointId.split("-").join("");
					let locationId = location.id.split("-").join("");
					if (id === locationId) {
						item.checked = location.checked
						is = false;
					}
					return item
				});
				return is && location.checked
			})
			setNoAnalysisLocation(noData)
			dispatch({
				type: AppAction.setHazardMultipleAnalysisResult,
				payload: hazardMultipleAnalysisResult,
			});
		}
	}, [context.currentMultipleLocations && context.currentMultipleLocations.items])

	useEffect(() => {
		if (context.hazardMultipleAnalysisResult && context.hazardMultipleAnalysisResult.length > 0
			&& context.hazardMultipleAnalysedLegends && context.hazardMultipleAnalysedLegends.length > 0
			&& context.hazardMultipleAnalysisStatus !== "error" && context.hazardMultipleAnalysisStatus !== "running") {
			let defendeResultData: any = []
			let resultData = context.hazardMultipleAnalysisResult.filter((item: any) => {
				let isFind = context.hazardMultipleAnalysedLegends!.find((legend: any) => {
					return legend.layerId === item.layerId;
				});

				if (isFind) {
					if (item.layerId === 'FL_Fluvial_Global_Fathom' ||
						item.layerId === 'FL_Coastal_Global_Fathom'
					) {
						const data = JSON.parse(item.details.Defended)
						const FloodDepthItem = data.find((item: { depth: string; }) => parseFloat(item.depth) > 0);
						const returnPeriod = FloodDepthItem && FloodDepthItem.returnPeriod
						item.defendeValueLabel = returnPeriod ? returnPeriod + " years" : data[0].valueLabel;
						defendeResultData.push(item)
					}

					isFind.rules.forEach((i: any, j: number) => {
						let label = item.valueLabel;
						let defendeValueLabel = item.defendeValueLabel;
						if (item.layerId.includes("Fathom")) {
							if (label === "5 years" || label === "10 years" || label === "20 years" || label === "50 years") {
								label = "≤50 years"
							} else if (label === "1000 years") {
								label = "≥1000 years"
							}

							if (defendeValueLabel === "5 years" || defendeValueLabel === "10 years" || defendeValueLabel === "20 years" || defendeValueLabel === "50 years") {
								defendeValueLabel = "≤50 years"
							} else if (defendeValueLabel === "1000 years") {
								defendeValueLabel = "≥1000 years"
							}
						}

						if (i.title == label) {
							item.sortIndex = j
						}

						if (i.title == defendeValueLabel) {
							item.defendeSortIndex = j
						}
					})
				}

				if (item.valueLabel === "Negligible (< 0.014)" || item.valueLabel === "Negligible") {
					item.sortIndex = 0.5;
				}

				if (item.defendeValueLabel === "Negligible (< 0.014)" || item.defendeValueLabel === "Negligible") {
					item.defendeSortIndex = 0.5;
				}

				if (item.valueLabel === "Outside" || item.valueLabel === "No Data" || item.valueLabel === "No Observation") {
					item.sortIndex = -1;
				}

				if (item.defendeValueLabel === "Outside" || item.defendeValueLabel === "No Data" || item.defendeValueLabel === "No Observation") {
					item.defendeSortIndex = -1;
				}

				if (item.checked === undefined) {
					item.checked = true;
				}
				return isFind !== undefined;
			});

			let arr: any = [...resultData].sort((a: any, b: any) => {
				return b.sortIndex - a.sortIndex;
			});

			let defendeArr: any = [];
			if (defendeResultData.length > 0) {
				defendeArr = [...defendeResultData].sort((a: any, b: any) => {
					return b.defendeSortIndex - a.defendeSortIndex;
				});
			}

			let data: any = [];
			let defendeData: any = [];

			context.hazardMultipleAnalysedLegends.forEach((legend, index) => {
				arr.forEach((item: any) => {
					if (item.layerId === legend.layerId) {
						if (item.valueLabel === "Outside" || item.valueLabel === "No Data" || item.valueLabel === "No Observation" || item.valueLabel === undefined) {
							if (!item.valueLabel) {
								item.valueLabel = "No Data";
							}
							if (data[index]) {
								data[index].push({
									...legend,
									...item,
									value: 1,
									itemStyle: {
										color: "grey",
										opacity: "0.5",
									},
								});
							} else {
								data[index] = [
									{
										...legend,
										...item,
										value: 1,
										itemStyle: {
											color: "grey",
											opacity: "0.5",
										},
									},
								];
							}
						} else {
							legend.rules.forEach((rules: any) => {
								let label = item.valueLabel
								if (legend.layerId.includes("Fathom")) {
									if (label === "5 years" || label === "10 years" || label === "20 years" || label === "50 years") {
										label = "≤50 years"
									} else if (label === "1000 years") {
										label = "≥1000 years"
									}
								}

								if (rules.title === label) {
									const raster = rules.symbolizers[0]?.raster?.color;
									const polygon = rules.symbolizers[0]?.polygon?.fill;
									const rasterOpacity = rules.symbolizers[0]?.raster?.opacity;
									const polygonOpacity = rules.symbolizers[0]?.polygon?.fillOpacity;
									const color = raster || polygon;
									const opacity = rasterOpacity || polygonOpacity;
									if (data[index]) {
										data[index].push({
											...legend,
											...item,
											value: 1,
											itemStyle: {
												color: color,
												opacity: opacity,
											},
										});
									} else {
										data[index] = [
											{
												...legend,
												...item,
												value: 1,
												itemStyle: {
													color: color,
													opacity: opacity,
												},
											},
										];
									}
								}
							});
						}
					}
				});
				if (defendeArr.length > 0) {
					defendeArr.forEach((item: any) => {
						if (item.layerId === legend.layerId) {
							if (item.defendeValueLabel === "Outside" || item.defendeValueLabel === "No Data" || item.defendeValueLabel === "No Observation" || item.defendeValueLabel === undefined) {
								if (!item.defendeValueLabel) {
									item.defendeValueLabel = "No Data";
								}
								if (defendeData[index]) {
									defendeData[index].push({
										...legend,
										...item,
										defendeValue: 1,
										itemStyle: {
											color: "grey",
											opacity: "0.5",
										},
									});
								} else {
									defendeData[index] = [
										{
											...legend,
											...item,
											defendeValue: 1,
											itemStyle: {
												color: "grey",
												opacity: "0.5",
											},
										},
									];
								}
							} else {
								legend.rules.forEach((rules: any) => {
									let label = item.defendeValueLabel
									if (label === "5 years" || label === "10 years" || label === "20 years" || label === "50 years") {
										label = "≤50 years"
									} else if (label === "1000 years") {
										label = "≥1000 years"
									}

									if (rules.title === label) {
										const raster = rules.symbolizers[0]?.raster?.color;
										const polygon = rules.symbolizers[0]?.polygon?.fill;
										const rasterOpacity = rules.symbolizers[0]?.raster?.opacity;
										const polygonOpacity = rules.symbolizers[0]?.polygon?.fillOpacity;
										const color = raster || polygon;
										const opacity = rasterOpacity || polygonOpacity;
										if (defendeData[index]) {
											defendeData[index].push({
												...legend,
												...item,
												defendeValue: 1,
												itemStyle: {
													color: color,
													opacity: opacity,
												},
											});
										} else {
											defendeData[index] = [
												{
													...legend,
													...item,
													defendeValue: 1,
													itemStyle: {
														color: color,
														opacity: opacity,
													},
												},
											];
										}
									}
								});
							}
						}
					});
				}
			});

			let tableData: any = [],
				defendeTableData: any = [],
				noData: any = [],
				defendeNoData: any = [];

			data.forEach((dataItem: any) => {
				const valueMap: any = {};
				if (dataItem && dataItem.length > 0) {
					dataItem.forEach((item: any) => {
						const label = item.valueLabel;
						if (valueMap[label] === undefined) {
							valueMap[label] = 1; // First occurrence
							item.value = dataItem.filter((i: any) => i.valueLabel === label).length; // Count total occurrences
						} else {
							item.value = 0; // Subsequent occurrences
						}
					});

					if ((dataItem[0].valueLabel === "Outside" || dataItem[0].valueLabel === "No Data" || dataItem[0].valueLabel === "No Observation" || dataItem[0].valueLabel === "Negligible") && dataItem[0].value === dataItem.length) {
						noData.push(dataItem[0]);
					} else {
						tableData.push(dataItem);
					}
				}
			});
			if (defendeData.length > 0) {
				defendeData.forEach((dataItem: any) => {
					const valueMap: any = {};
					if (dataItem && dataItem.length > 0) {
						dataItem.forEach((item: any) => {
							const label = item.defendeValueLabel;
							if (valueMap[label] === undefined) {
								valueMap[label] = 1; // First occurrence
								item.defendeValue = dataItem.filter((i: any) => i.defendeValueLabel === label).length; // Count total occurrences
							} else {
								item.defendeValue = 0; // Subsequent occurrences
							}
						});

						if ((dataItem[0].defendeValueLabel === "Outside" || dataItem[0].defendeValueLabel === "No Data" || dataItem[0].defendeValueLabel === "No Observation" || dataItem[0].defendeValueLabel === "Negligible") && dataItem[0].defendeValue === dataItem.length) {
							defendeNoData.push(dataItem[0]);
						} else {
							defendeTableData.push(dataItem);
						}
					}
				});
			}

			setTableDatas(tableData);
			setNoData(noData);

			handleSetSortData(JSON.parse(JSON.stringify(tableData)), active, activeLegend);

			setDefendeTableDatas(defendeTableData);
			setDefendeNoData(defendeNoData);
			if (defendeTableData.length > 0) {
				let activeIndex = defendeTableData.findIndex((item: any) => item[0].layerId === tableData[activeLegend][0].layerId);
				activeIndex = activeIndex === -1 ? 0 : activeIndex;
				setDefendeActiveLegend(activeIndex)
				handleSetDefendeSortData(JSON.parse(JSON.stringify(defendeTableData)), defendeActive, activeIndex);
			}

			handleSetOptions(tableData, activeLegend);
		}
	}, [context.hazardMultipleAnalysisResult, context.hazardMultipleAnalysedLegends]);

	useEffect(() => {
		setActiveLegend(0);
	}, [context.hazardMultipleAnalysisStatus])

	if (context.hazardMultipleAnalysisStatus && context.hazardMultipleAnalysisStatus === "error") {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<div
					className="text-center"
					style={{ color: "gray" }}
				>
					<span className="material-icons">error_outline</span>
				</div>
				<div className="text-[14px]">{t("single.graunchTitle")}</div>
				<div dangerouslySetInnerHTML={{ __html: t("single.graunchContent") }}></div>
			</div>
		);
	}

	if (!context.hazardMultipleAnalysedLegends || context.hazardMultipleAnalysedLegends.length === 0) {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<NotAnalysisSvg className="w-[60px] h-[60px] mx-auto mb-4" />
				<div style={{ color: "var(--gray-text)" }}>{t("single.notMultipleAnalysis")}</div>
			</div>
		);
	}

	if (context.hazardMultipleAnalysisStatus && context.hazardMultipleAnalysisStatus === "running") {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<LinearProgress
					color="success"
					style={{ width: "40%", marginBottom: "10px" }}
				/>
				<div>{t("multiple.alalyseLoadingContent")}</div>
			</div>
		);
	}

	const randerTable = () => {
		return <TableContainer sx={{ height: "100%" }}>
			<Table
				stickyHeader
				aria-label="sticky table"
				size="small"
			>
				<TableHead>
					<TableRow
						className={styles.row}
						sx={{
							"& th": {
								fontSize: "12px",
								color: "#000000de"
							}
						}}
					>
						<TableCell
							style={{ padding: 0, width: 44 }}
							align="center"
						>
							<Checkbox
								color="success"
								indeterminate={(rowCount > 0 || noAnalysisLocation.length > 0) && (sortData[activeLegend] ? sortData[activeLegend].length : 0) !== rowCount}
								checked={rowCount > 0 && (sortData[activeLegend] ? sortData[activeLegend].length : 0) === rowCount}
								onChange={handleSelectAllClick}
								inputProps={{
									"aria-label": "select all desserts",
								}}
							/>
						</TableCell>
						<TableCell align="left" style={{ padding: "8px" }}>
							<TableSortLabel
								active={active === "sortIndex"}
								onClick={() => handleSort("sortIndex")}
								direction={direction.sortIndex}
							>
								{sortData[activeLegend]
									? sortData[activeLegend][0].subtitle
										? t("legend." + sortData[activeLegend][0].subtitle)
										: ""
									: noData[0].subtitle
										? t("legend." + noData[0].subtitle)
										: ""}
							</TableSortLabel>
						</TableCell>
						<TableCell align="right" style={{ width: "85px", padding: "8px" }}>
							<TableSortLabel
								active={active === "value"}
								onClick={() => handleSort("value")}
								direction={direction.value}
							>
								{t("locations.table.locations")}
							</TableSortLabel>
						</TableCell>
						<TableCell align="right" style={{ width: "85px", padding: "8px 16px 8px 8px" }}>
							<TableSortLabel
								active={active === "share"}
								onClick={() => handleSort("share")}
								direction={direction.share}
							>
								{t("locations.table.share")}
							</TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sortData[activeLegend] &&
						sortData[activeLegend].map(
							(item: any, index: number) =>
								item.value > 0 && (
									<TableRow
										key={index}
										sx={{
											"& td": {
												fontSize: "13px",
												color: "#000000de"
											}
										}}
									>
										<TableCell
											style={{ padding: 0 }}
											align="center"
										>
											<Checkbox
												color="success"
												indeterminate={handleIndeterminateStatus(item)}
												checked={handleCheckedStatus(item)}
												onClick={(evt: any) => toggleLocation(evt, item)}
											/>
										</TableCell>
										<TableCell align="left" style={{ padding: "8px" }}>
											<div style={{ display: "flex", alignItems: "center" }}>
												<span
													style={{
														flexShrink: 0,
														width: "16px",
														height: "16px",
														marginRight: "5px",
														display: "inline-block",
														border: "1px solid #e3e3e3",
														backgroundColor: item.itemStyle.color,
														opacity: item.itemStyle.opacity,
													}}
												></span>
												{item.valueLabel ? t("legend." + item.valueLabel) : ""}
											</div>
										</TableCell>
										<TableCell align="right" style={{ padding: "8px" }}>{handleGetLocationNum(item)}</TableCell>
										<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>{handleGetLocationPercent(item)}</TableCell>
									</TableRow>
								)
						)}
					{noAnalysisLocation.length > 0 && (
						<TableRow
							sx={{
								"& td": {
									fontSize: "13px",
									color: "#000000de"
								}
							}}
						>
							<TableCell
								style={{ padding: 0 }}
								align="center"
							>
								<Checkbox
									color="success"
									checked={true}
									onClick={handleDeleteNoData}
								/>
							</TableCell>
							<TableCell align="left" style={{ padding: "8px" }}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<span
										style={{
											flexShrink: 0,
											width: "16px",
											height: "16px",
											boxSizing: "border-box",
											marginRight: "5px",
											display: "inline-block",
											backgroundColor: "rgb(242, 242, 242)",
											border: "2.5px dotted #959595",
										}}
									></span>
									{t("legend.Not Analyzed")}
								</div>
							</TableCell>
							<TableCell align="right" style={{ padding: "8px" }}>{noAnalysisLocation.length}</TableCell>
							<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>{toFixedTwo(noAnalysisLocation.length / (noAnalysisLocation.length + sortData[activeLegend].filter((i: any) => i.checked).length))}</TableCell>
						</TableRow>
					)}
					<TableRow
						style={{ fontWeight: "bold", height: "50px" }}
						sx={{
							"& td": {
								fontSize: "13px",
								color: "#000000de"
							}
						}}
					>
						<TableCell style={{ padding: "8px" }} colSpan={2}>{t("multiple.total")}</TableCell>
						<TableCell align="right" style={{ padding: "8px" }}>
							{sortData[activeLegend] ?
								(noAnalysisLocation.length > 0 ? (noAnalysisLocation.length + sortData[activeLegend].filter((i: any) => i.checked).length)
									: sortData[activeLegend].filter((i: any) => i.checked).length)
								: 0}
						</TableCell>
						<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>100%</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	}

	const defendeRanderTable = () => {
		return <TableContainer sx={{ height: "100%" }}>
			<Table
				stickyHeader
				aria-label="sticky table"
				size="small"
			>
				<TableHead>
					<TableRow
						className={styles.row}
						sx={{
							"& th": {
								fontSize: "12px",
								color: "#000000de"
							}
						}}
					>
						<TableCell
							style={{ padding: 0, width: 44 }}
							align="center"
						>
							<Checkbox
								color="success"
								indeterminate={(defendeRowCount > 0 || noAnalysisLocation.length > 0) && (defendeSortData[defendeActiveLegend] ? defendeSortData[defendeActiveLegend].length : 0) !== defendeRowCount}
								checked={defendeRowCount > 0 && (defendeSortData[defendeActiveLegend] ? defendeSortData[defendeActiveLegend].length : 0) === defendeRowCount}
								onChange={handleSelectAllClick}
								inputProps={{
									"aria-label": "select all desserts",
								}}
							/>
						</TableCell>
						<TableCell align="left" style={{ padding: "8px" }}>
							<TableSortLabel
								active={defendeActive === "sortIndex"}
								onClick={() => handleDefendeSort("sortIndex")}
								direction={defendeDirection.sortIndex}
							>
								{defendeSortData[defendeActiveLegend]
									? defendeSortData[defendeActiveLegend][0].subtitle
										? t("legend." + defendeSortData[defendeActiveLegend][0].subtitle)
										: ""
									: defendeNoData[0].subtitle
										? t("legend." + defendeNoData[0].subtitle)
										: ""}
							</TableSortLabel>
						</TableCell>
						<TableCell align="right" style={{ width: "85px", padding: "8px" }}>
							<TableSortLabel
								active={defendeActive === "value"}
								onClick={() => handleDefendeSort("value")}
								direction={defendeDirection.value}
							>
								{t("locations.table.locations")}
							</TableSortLabel>
						</TableCell>
						<TableCell align="right" style={{ width: "85px", padding: "8px 16px 8px 8px" }}>
							<TableSortLabel
								active={defendeActive === "share"}
								onClick={() => handleDefendeSort("share")}
								direction={defendeDirection.share}
							>
								{t("locations.table.share")}
							</TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{defendeSortData[defendeActiveLegend] &&
						defendeSortData[defendeActiveLegend].map(
							(item: any, index: number) =>
								item.defendeValue > 0 && (
									<TableRow
										key={index}
										sx={{
											"& td": {
												fontSize: "13px",
												color: "#000000de"
											}
										}}
									>
										<TableCell
											style={{ padding: 0 }}
											align="center"
										>
											<Checkbox
												color="success"
												indeterminate={handleDefendeIndeterminateStatus(item)}
												checked={handleDefendeCheckedStatus(item)}
												onClick={(evt: any) => defendeToggleLocation(evt, item)}
											/>
										</TableCell>
										<TableCell align="left" style={{ padding: "8px" }}>
											<div style={{ display: "flex", alignItems: "center" }}>
												<span
													style={{
														flexShrink: 0,
														width: "16px",
														height: "16px",
														marginRight: "5px",
														display: "inline-block",
														border: "1px solid #e3e3e3",
														backgroundColor: item.itemStyle.color,
														opacity: item.itemStyle.opacity,
													}}
												></span>
												{item.defendeValueLabel ? t("legend." + item.defendeValueLabel) : ""}
											</div>
										</TableCell>
										<TableCell align="right" style={{ padding: "8px" }}>{handleGetDefendeLocationNum(item)}</TableCell>
										<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>{handleGetDefendeLocationPercent(item)}</TableCell>
									</TableRow>
								)
						)}
					{noAnalysisLocation.length > 0 && (
						<TableRow
							sx={{
								"& td": {
									fontSize: "13px",
									color: "#000000de"
								}
							}}
						>
							<TableCell
								style={{ padding: 0 }}
								align="center"
							>
								<Checkbox
									color="success"
									checked={true}
									onClick={handleDeleteNoData}
								/>
							</TableCell>
							<TableCell align="left" style={{ padding: "8px" }}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<span
										style={{
											flexShrink: 0,
											width: "16px",
											height: "16px",
											boxSizing: "border-box",
											marginRight: "5px",
											display: "inline-block",
											backgroundColor: "rgb(242, 242, 242)",
											border: "2.5px dotted #959595",
										}}
									></span>
									{t("legend.Not Analyzed")}
								</div>
							</TableCell>
							<TableCell align="right" style={{ padding: "8px" }}>{noAnalysisLocation.length}</TableCell>
							<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>{toFixedTwo(noAnalysisLocation.length / (noAnalysisLocation.length + sortData[activeLegend].filter((i: any) => i.checked).length))}</TableCell>
						</TableRow>
					)}
					<TableRow
						style={{ fontWeight: "bold", height: "50px" }}
						sx={{
							"& td": {
								fontSize: "13px",
								color: "#000000de"
							}
						}}
					>
						<TableCell style={{ padding: "8px" }} colSpan={2}>{t("multiple.total")}</TableCell>
						<TableCell align="right" style={{ padding: "8px" }}>
							{defendeSortData[defendeActiveLegend] ?
								(noAnalysisLocation.length > 0 ? (noAnalysisLocation.length + defendeSortData[defendeActiveLegend].filter((i: any) => i.checked).length)
									: defendeSortData[defendeActiveLegend].filter((i: any) => i.checked).length)
								: 0}
						</TableCell>
						<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>100%</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	}

	const randerDetail = () => {
		const layerId = sortData[activeLegend] && sortData[activeLegend][0] && sortData[activeLegend][0].layerId
		if (layerId === 'FL_Fluvial_Global_Fathom' ||
			layerId === 'FL_Coastal_Global_Fathom'
		) {
			return <>
				<CustomAccordion title={t("fathom.Undefended")}>
					{randerTable()}
				</CustomAccordion>
				<CustomAccordion title={t("fathom.Defended")}>
					{defendeRanderTable()}
				</CustomAccordion>
			</>
		}
		return randerTable()
	}

	return (
		<>
			<div className={styles.locationInfo}>
				<div className="flex justify-between items-center">
					<span className="text-[#000000de] text-[13px] font-medium">{t("multiple.overview")}</span>
					<div className="flex items-center">
						<div className="mt-[3px] flex items-center">
							{/* {sortData[activeLegend] ? sortData[activeLegend].filter((i: any) => i.checked).length : 0}/{context.currentMultipleLocations?.items?.filter((v) => v.checked).length} {t("multiple.locations")} {t("results.multipleSelected")} */}
							<Tooltip placement="top" title={t("results.resultsFor")}>
								<span>{t("results.resultsFor")}</span>
							</Tooltip>
							<MapAnalysisSvg className="w-[24px] h-[24px] fill-[#0009] mx-[5px] mt-[-5px]" />
							<Tooltip placement="top" title={`${(sortData[activeLegend] ? sortData[activeLegend].filter((i: any) => i.checked).length : 0) + " / " + context.currentMultipleLocations?.items?.filter((v) => v.checked).length}${t("results.locations")}`}>
								<span>
									{sortData[activeLegend] ? sortData[activeLegend].filter((i: any) => i.checked).length : 0} / {context.currentMultipleLocations?.items?.filter((v) => v.checked).length}
									{t("results.locations")}
								</span>
							</Tooltip>
						</div>
						<Icon
							icon="info"
							type="outlined"
							onClick={showInfo}
						></Icon>
					</div>
				</div>
			</div>
			{tableDatas.length > 0 && (
				<>
					<div
						className={styles.chart}
						style={{ height: chartHeight * 63 + "px" }}
					>
						<div className={styles.legendIcon}>
							{tableDatas.map((tableDataItem: any, index: number) => {
								return (
									<Tooltip placement="top" title={t("layerId." + tableDataItem[0].layerId)} key={index}>
										<IconButton
											onClick={() => {
												handleLegendClick(index);
											}}
											className={`${activeLegend === index ? styles.activeLegend : ""}`}
										>
											{Svg({ icon: tableDataItem[0].layerId })()}
										</IconButton>
									</Tooltip>
								);
							})}
						</div>
						<Chart option={chartOption} />
					</div>
					<div className="text-center text-[12px]">{t("multiple.chartTitle")}</div>
				</>
			)}
			{noData.length > 0 && <div className="m-2">{noDataText()}</div>}
			{(tableDatas[activeLegend] || noData.length > 0) && (
				<div>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px" }}>
						<p className="m-2 font-bold">{t(`layerId.${tableDatas[activeLegend] ? tableDatas[activeLegend][0].layerId : noData[0].layerId}`)}</p>
						<div style={{ padding: "6px 10px", borderRadius: "5px", backgroundColor: "var(--green-hover)", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)" }}>
							<IconTable />
						</div>
					</div>
					<Paper
						sx={{ width: "100%", boxShadow: "none" }}
						className={styles.table}
					>
						{randerDetail()}
					</Paper>
				</div >
			)
			}
		</>
	);
}

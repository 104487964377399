import styles from "./RiskScore.module.scss";
import { AnalysisItem, LegendItem } from "helpers/models";
import { useTranslation } from "react-i18next";
import FathomIcon from "components/fathomIcon/fathomIcon";
import { useState } from "react";
import LayerInfo from "./layerInfo/LayerInfo";

interface Props {
  legendItem: LegendItem;
  analysisItem: AnalysisItem;
}

export default function RiskScore({
  legendItem,
  analysisItem
}: Readonly<Props>) {
  const [t] = useTranslation();

  const [isShow, setIsShow] = useState(false);

  return (
    <div className={styles.div}>
      <div className={styles.section}>
        <div className={styles.label}>
          {t("fathom.RiskScore")}
        </div>

        <span
          className={`material-icons-outlined ${styles.info}`}
          onClick={() => setIsShow(true)}
        >
          info
        </span>
        <FathomIcon className={styles.icon} />
      </div>

      <div className={styles.score}>
        <div className={styles.key}>
          {t("fathom.Relative Score")}
        </div>
        <span>
          {analysisItem &&
          analysisItem.relativeRisk &&
          analysisItem.relativeRisk < 0
            ? "-"
            : analysisItem.relativeRisk}
        </span>
      </div>

      <div className={styles.score}>
        <div className={styles.key}>
          {t("fathom.Category Score")}
        </div>
        <span>
          {analysisItem &&
          analysisItem.riskCategory &&
          analysisItem.riskCategory < 0
            ? "-"
            : analysisItem.riskCategory}
        </span>
      </div>

      {isShow && <LayerInfo open={isShow} onClose={() => setIsShow(false)} />}
    </div>
  );
}

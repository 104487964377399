import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { getAmapGeo } from "api/layer";
import countryArray from "assets/json/country.json";
import { Item } from "helpers/models";
import { getLanguage } from "helpers/translations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  item: Item;
  edit: (item: Item) => void;
}

export default function AddressInput(props: Props) {
  const [t] = useTranslation();
  const language = getLanguage();

  const defaultContry =
    language === "en"
      ? { label: "China", value: "CN" }
      : { label: "中国", value: "CN" };

  const [country, setCountry] = useState<{ label: string; value: string }>(
    defaultContry
  );

  const [options, setOptions] = useState<Item[]>([]);

  let countryLangue: any[] = [];
  if (language === "en") {
    countryLangue = countryArray.slice(242);
  } else {
    countryLangue = countryArray.slice(0, 242);
  }

  useEffect(() => {
    if (!props.item) {
      return;
    }
    if (!props.item.country) {
      return;
    }
    if (!props.item.address) {
      return;
    }
    if (!countryLangue || countryLangue.length === 0) {
      return;
    }
    const country = countryLangue.find((item: any) =>
      item.label.toLowerCase().includes(props.item.country.toLowerCase())
    );
    setCountry(country);
  }, []);

  const handleCountryChange = async (event: any, newValue: any) => {
    setCountry(newValue);
  };

  const handleInputChange = async (event: any, newValue: string | null) => {
    // let country = countryArray.filter((item) => item.label.toLowerCase().includes("中国"))[0].value;

    if (!newValue) {
      setOptions([]);
      return;
    }

    // if (!country || !country.value) {
    // 	return;
    // }

    let param = {
      address: newValue,
      country: country?.value
    };

    let resp: any = await getAmapGeo(param);

    if (!resp || !resp.data || !resp.data.length) {
      return;
    }

    if (!resp.data[0].geocodes || !resp.data[0].geocodes.length) {
      return;
    }

    const options = resp.data[0].geocodes.map((e: any) => {
      // return item.formatted_address;

      // return {
      // 	...props.item,
      // 	...item,
      // 	address: item.formatted_address,
      // };

      const city = Array.isArray(e.city) && e.city.length === 0 ? "" : e.city;
      const country =
        Array.isArray(e.country) && e.country.length === 0 ? "" : e.country;
      const district =
        Array.isArray(e.district) && e.district.length === 0 ? "" : e.district;
      const address =
        Array.isArray(e.formatted_address) && e.formatted_address.length === 0
          ? ""
          : e.formatted_address;
      const location =
        Array.isArray(e.location) && e.location.length === 0 ? "" : e.location;
      const longitude = location.split(",")[0];
      const latitude = location.split(",")[1];
      const number =
        Array.isArray(e.number) && e.number.length === 0 ? "" : e.number;
      const province =
        Array.isArray(e.province) && e.province.length === 0 ? "" : e.province;
      const street =
        Array.isArray(e.street) && e.street.length === 0 ? "" : e.street;
      const county = district;
      const place = address;
      const zipCode =
        Array.isArray(e.postcode) && e.postcode.length === 0 ? "" : e.postcode;

      return {
        ...props.item,
        // id: props.item.id,
        // code: props.item.code,
        // name: props.item.name,
        country: country.label,
        county,
        latitude,
        longitude,
        number,
        place,
        province,
        street,
        // quality: 0,
        address,
        city,
        district,
        location,
        zipCode
      };
    });

    const uniqueOptions = options.filter(
      (option: Item, index: number, self: Item[]) =>
        index === self.findIndex((o) => o.address === option.address)
    );

    // const options = [];
    // options.push(newValue);
    // setOptions(options);

    setOptions(uniqueOptions);
  };

  const handleChange = (event: any, newValue: Item | null) => {
    if (!newValue) {
      return;
    }

    props.edit(newValue);
  };

  return (
    <div style={{ display: "flex" }}>
      <Autocomplete
        // id={props.id}
        disablePortal
        value={country}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          option &&
          value &&
          option.value &&
          value.value &&
          option.value === value.value
        }
        onChange={handleCountryChange}
        sx={{ width: "30%", paddingRight: "0.5rem" }}
        options={countryLangue}
        // onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            color="success"
          />
        )}
      />
      <Autocomplete
        disablePortal
        value={props.item}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        getOptionLabel={(options: Item) => options.address}
        isOptionEqualToValue={(option, value) =>
          option.address === value.address
        }
        sx={{ width: "70%" }}
        noOptionsText={t("addressEncoding.noAddressFound")}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={t("addressEncoding.table.searchAddress")}
            variant="outlined"
            color="success"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <span className="material-icons-outlined">search</span>
                </InputAdornment>
              )
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <span className="material-icons">location_on</span>
            {option.address}
          </li>
        )}
        // filterOptions={(options, state) => {
        // 	if (!props.item || !props.item.address) {
        // 		return [];
        // 	}
        // 	return options;
        // }}
      />
    </div>
  );
}

import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Collapse from "./collapse/Collapse";
import { AnalysisItem, DefendedData, LegendItem, Detail } from "helpers/models";
import getMatchingTitle from "../util"

interface Props {
  legendItem: LegendItem;
  analysisItem: AnalysisItem;
}

export default function Expand({ legendItem, analysisItem }: Readonly<Props>) {
  const [t] = useTranslation();

  const [isExpand, setIsExpand] = useState(false);

  const handleExpand = () => {
    setIsExpand(!isExpand);
  };

  const details = analysisItem.details as Detail;

  const defendedArray: DefendedData[] =
    typeof details.Defended === "string" && !!details.Defended
      ? JSON.parse(details.Defended) as DefendedData[]
      : [];

  const undefendedArray: DefendedData[] =
    typeof details.Undefended === "string" && !!details.Undefended
      ? JSON.parse(details.Undefended) as DefendedData[]
      : [];

  const rule = legendItem.rules;
  
  const ruleMap = rule.reduce((acc: { [key: string]: string }, currentRule) => {
    const symbolizer = currentRule.symbolizers[0];
    if ("raster" in symbolizer) {
      acc[currentRule.title] = symbolizer.raster.color;
    }
    return acc;
  }, {} as { [key: string]: string });

  const defended = defendedArray.map(item => {
    const matchingTitle = getMatchingTitle(item.returnPeriod);
    const color = ruleMap[matchingTitle];
    return {
      ...item,
      color: color
    };
  });
  
  const undefended = undefendedArray.map(item => {
    const matchingTitle = getMatchingTitle(item.returnPeriod);
    const color = ruleMap[matchingTitle];
    return {
      ...item,
      color: color
    };
  });

  return (
    <div>
      {!isExpand
        ? <Button variant="text" onClick={handleExpand} color="success">
            {t("buttons.expand")}
          </Button>
        : <Collapse
            defended={defended}
            undefended={undefended}
            handleCollapse={handleExpand}
          />}
    </div>
  );
}

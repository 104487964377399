const layout = (line: number = 0, v: boolean = false, padding: number = 3) => ({
  hLineWidth: (i: number) => (i >= line ? 1 : 0),
  vLineWidth: (i: number, node: any) => {
    if (!v) {
      return 0;
    }
    return i === 0 || i === node.table.widths.length ? 1 : 0;
  },
  hLineColor: () => "#E4E4E4",
  vLineColor: () => "transparent",
  paddingTop: () => padding,
  paddingBottom: () => padding
});

export default layout;

import { TDocumentDefinitions } from "pdfmake/interfaces";
import getHeader from "report/common/header";
import getLogo from "report/common/logo";
import getReportGEO from "./detail/geo";
import getReportDetail from "./detail/report";
import getMap from "report/common/map";
import getLegend from "report/common/legend";
import getFooter from "report/common/footer";
import getHazard from "./hazard/hazard";
import { AnalysisItem, LegendItem } from "helpers/models";
import { getLanguage } from "helpers/translations";

interface Param {
  t: any;
  name: string;
  lng: number;
  lat: number;
  province: string;
  county: string;
  analysisItems: AnalysisItem[];
  legendItems: LegendItem[];
}

const getDoc = async ({
  t,
  name,
  lng,
  lat,
  province,
  county,
  analysisItems,
  legendItems
}: Param): Promise<TDocumentDefinitions> => {
  const docDefinition: TDocumentDefinitions = {
    pageMargins: [20, 60, 20, 30],

    header: (currentPage, pageCount) =>
      currentPage === 1 ? getHeader(t("report.CatNet")) : getLogo(),

    footer: (currentPage, pageCount) => getFooter(t, currentPage, pageCount),

    content: [
      {
        columns: [
          {
            stack: getReportDetail(t, name, lng, lat)
          },
          {
            stack: getReportGEO(t, province, county)
          }
        ],
        columnGap: 20,
        margin: [0, 20, 0, 10]
      },
      {
        columns: [
          {
            stack: await getMap(t("report.Map View"))
          },
          {
            stack: await getLegend(t("report.Map Legends"))
          }
        ],
        columnGap: 20,
        pageBreak: "after"
      },
      getHazard(t, analysisItems, legendItems)
    ],

    defaultStyle: {
      // font: getLanguage() === "en" ? "Roboto" : "微软雅黑",
      font: "微软雅黑",
      fontSize: 8,
      opacity: 0.6,
      lineHeight: 1,
      characterSpacing: 0
    }
  };

  return docDefinition;
};

export default getDoc;

const pngArray = {
  EQ_Landslide_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACLElEQVRYhdWYLYzCQBCF9y4YJBaJRWKRWCQWiUVisUgsEovEIrFILBJbiezlbdhmWGaX2VlauC+5pHfJbV/n5820P2VZluaL+f1mceY/CGzRX5bLZXXd7XbNaDQyvV7vE7oqghG8Xq9mu91a0ZfLRXX44XDI1SdLMYSmcjqdzPF4tA9YFEW9AjUMBoPqv9brtToLtTbJfD6vrpEFRDUVlcDNZmNTR5uKo9PpPIjc7/dmt9sl3UslEA3keBUViKQPcj6f7QNSYs0kFhgqdFprMahIPOBqtbLXqE00U4iW6PS7QEQDjMdjsTAKRELY7XazP+7aP58ijiDtQo04x2KxsEMAOHEmUioqgbnMZrOnE7IF0sYIgTRJTJnrfhpNirgGASxiMpmIbwqL8esKker3+zYjIVFqgbAILBBcMXPATlBzFNQvV8MQzDVKsg9ibPmE0iqJkANbU1YXa0Aqc1EJ9CMWSnmoXlNQCUSa/XEF86bQGZzDw0vTq+HPMRwObeOYe2QhXnIO5i86GnU6nU6Dm3u2QEfsJj6Ivu+rofH5tiaBR7oFIAaizJk+VjGOtwl0CwCyEFuf/NqlcHZVi824dxGOmDdyHlurD/oiNQtH7S/uNN2vFol2u/30t9oF0m0ZXep2QQ5uDWvk0wftbojgRiCix02kpG1GCxqDbipuBGI7gjAYfWhLf5tRS9Cc3+jXrZgHhmhUICZIqsjGvw/Sd2IJH/mAiaYRRdIY8wcZASSThh6RdgAAAABJRU5ErkJggg==",
  CS_Tornado_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAABiElEQVRYhcWYO26EMBCGnWgbOkRHS0fLo6OkpKWlpaPlPlyDI3AGECdAdJREE2Ujlg1Z/56J8zU0fnzC9szYb/u+7wpgmibVdR3S5YGiKFSSJNrtb+gEHLmqqlQQBFAfSHBZFtTpm6ZplOd5cL93pHHf9/AE6mtZTeQUKjjPMzyB4zjQnjsDCZrQti2rPyS4bRs0eBiGqM8TkCAtF0KapnYFUVzXtSuITmh6co9AgnEcQ4Nz4uYdSBANF+u6Gig9Au9B5KAMw2AsdgcWLMtSu61JYD8DC1Ky1/2LaNz8CaMwg2QHKs84GMfBLMu02nHKM8URzPMcziwmsDIJLbXOfuQsMzvV6Uhywo1ILn51aDjhRqxYoJL+Ck64EROkwoBKe2lEyy1OaX+FeD14FR9NKxtxwSiKRMcTF7wqUq1cO/8DK4LoZeuIFUH0qnDEiiAVFqaIC54LA3rR4vCngr7vw89tZ8QFx3H8/NLBqOuaPR78gPkKumrSn5OQI+An4N+gdEZLLJaTlVIf23Jnl8eGFVQAAAAASUVORK5CYII=",
  CS_Hail_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACPklEQVRYhe1YLY/CQBCdu5zBARKJRIND4vD8BDQOCRaHLRKJxxEUCkJwGBIkkoJC9vLIDaF7293ZbXsg7iVNu21n+3a+tx9RFEX0xvh8Z3LAV5aTTSYTOp1Oj3Gz2aRWq5VqzkxMvNlsaD6fJz4fDofec6c28eVyMZKjVxAEKcZ4PBbJjEYjn0+5+eBisaDVauX1odvt5iUn1iC05kvueQ5XiAkGQZCKHHA4HJxlrCbGqmezmS+nGJbLJV2vV6fUY0wzaaLPhkqlQt1u1/peoonzJAcgoSOx26AlmJVJJSS9CO73+zz4aIEqZMLLm4XdbvfeBG0RrSWILuSvUK1W3QmmbZGkkCjCmAdR4H1rqA3SXlHUDx6Px/uhwlabdRqq1+tULBbFCxF1M/ATna+YCGaV6MXtFpu7UChQv9+/30O5siVbaH46nd6v2+02NRoNJ4KiNIM+kH0RZy5RnU7HKsvkAFvn7U3wfD7HxmEY3s8uvuQLEUFVU2xiwOZrtVrtcQ33YMAKkMWhC0BGYhTDrNvtNkbGBJUoiJlcQH0/aaFaDSIgEKHwN2k0qu9l1XBoCdqSM5tGJdXr9WJjU6fybG5EdxK0aQbCSSTV7SN6RzYlggamZe0hajmt8I6QK4jUdbQahDAmAlFVS6VSKTYul8uxsc7voElO6jjbekArQfppGHSrVPcRknqKzZJpbEIuv99Y6/BJmB07w+c/EHxfhCgHDAaDaL1e/5o4CALnj2VOECTCMMxsvv8/rKlARN+3ckYchS6gbwAAAABJRU5ErkJggg==",
  EQ_Tsunami_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAADnElEQVRYhe1YOUtzQRQ9iXGJu2iQuOCKEkQQLUQhEk0hVjYiFrb+J0s7CwsRBAsXtDAK7iKCFhpwQ1Hjvms+zsXJl6gvi+8FLHJgCO9lljP33nNmEpPf7/fjD8P8l8khQdAAJAjqRYKgXiQI6kWCoF4kCOqFJZbx5+fnGB8fx/7+Pkwmk7x7fX1Ffn4+XC4XGhsbDScY1XVrc3MT09PTSEpKwu3trZBS4HC+T01NRXFxMdxuN+x2u2EEI0ZwcXERs7Oz8Pl8QsRiscBs/l8ZjOT7+zuur69xd3eH5+dntLS0wOFwBKIcN4JLS0uYm5uT1GZlZeHj4wMvLy/SiJSUFGkkwk9+v7e3h4eHB9hsNml6EVYkHo8HFxcXQo6RIYHy8nK0t7dLKy0txdvbm3xHkowsI3x0dITJyUnc39/HL4KMGhflgiSQmZmJmpoaNDU1oaKiQvrs7u5ifX0dXq8Xl5eXsFqt8j4tLQ0LCwuymYaGBqSnp/+aoKZIhoaGcHx8jMfHR4lcZ2cn2trafpxkdXUVo6OjgSjiUzxEb28v6urqfk1QM8W0EtYa66mjo0OTHEF76evrk74KJEvFM816fpdpElQK5OQqdeFQUFAgkaKiFVgWExMTUsuGE1QgweAIPD09YWNj41s/mnVra6uIJjC52Sy1qVT/G0T0QRY4hXB6eirPTCOVTYHQsJ1Op6iZYsrJyRGCNG2FjIwMqVHO09zcHDNFTYJULr2Ni9E2WJMqKiRzeHiIq6srSSsJKmRnZ4ekmeMPDg6wtraG+vr6mBWtmeKysjIkJyfLYiTKemLjAnxmZHJzc3FzcyObwadxFxYWhszD8uA4njQrKysxkQtLsKurS8QRfO5+BY++s7MzsSJ8PjOCFFhw3XKjPCopFlqXIQQZQRY+U6plEyRfUlIikVQEf0ohx9O8aTuDg4NSGtFaT1gV9/f3o7q6OsTfgkED58IKFAhTrgVugG14eFhSHg3Cqphi6OnpQV5eHmZmZiRVyh9ZdwMDA6itrQ30Z6p3dnaExE83GZV6pvnk5ERUH+nGE9FmWOC8GFCtnExNSPFUVVWFXL24OIlHUirnGBsbk9sODT5sX6P+H6QIRkZGxFIYwUjgEVhZWYnu7m4UFRVp9jbkNwlvPjzStra2pCyiATOzvb0t4+ipcSPIE2Zqakout1R9tAlhP6aXm+KlmDUZF4LLy8tyNtP/Yq0W9qfhMwPz8/PfOwD4BwxGt8x3QcpXAAAAAElFTkSuQmCC",
  FL_Pluvial_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACaElEQVRYhe1Yq67CQBCde4NBESyK4PgAHJJgUYQ/4BewWCQWiUNiCRJHGiSOoHCEoJC9Oc2dZrvs7IOWh+AkDdvS7Z6dOfNof+I4jumD8fvJ5OhLsAB8CeaFSPByudBut3s7wZL0x3Q6TX7r9TpVq9VXcsrAaMHJZJKOmei7YCR4u92sdLCBV7nfSLBcLosTQAwbWC6Xz+SVQix1CBIcjUYjc308HqdjbGQ0Gt3NK1KzYhRjEZ2cDl0Ki8WicM0G58FarZaOe71eOl6v17Tf75MxrAxLMmazWaJb/KrXfeDsZvDQ4XBofdThcKD5fH53HURBfLPZ3F33hdWCcNnpdEoWkQCLmMgxkfP57E3GBCtBdpluARUuzfEzHoVYSeBaFXCjLaJ1IMIhjbwRLRJstVqZXKcvJJFTUw/X8+12m5xXKhXqdrtBpK1BAqtBf/1+P/NQiRwiHFYDMZfr2+02dTqdfARNgOsROBI5BFaI7lwRHZQHOap1sN5CyfkQFDVogrQ4NAe3qonaB5wjbbnW24LQow15SpzJKwxvC0p1GeVOL1+uzTDUJA55DAaDxwmS4jrVhei4oyjK3CdVFhsk+TgJctt1vV6THeulC+lHtxhvgNMNnyMnIrfyORoIV3MsEvSZrG7CB0jUoTAGCXboS47+3ewDeKEQgiGA9VC+8kJ6zbBqEJOgMdRl9fUTmsOB4EAO09v+RyDlQSNBV51EysGh3tNsNp1VxKZBqYEwutiniOsw5TAdkgZtlafwD5hYTNUTgs12jk7J9nL2lC+sUsejw6dmP+0TMKJ7tVrR8XjMpKygTpuI/gBxtl7s0438JQAAAABJRU5ErkJggg==",
  FL_Coastal_Global_Fathom:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABb0lEQVRIie3Vv0ocURgF8J/LFmogYTGksEoskqgoBCsrLdIYWCQIgaRI2qTxEQQfwEcQ/4AIomWqIPgCbhUTBRvtAlooCQkWrsXcxXG9s7NmFRE88MFlOPc75zv3zgz3uG20XVOfdrzBMF5hEE/qSWUsoYI9/MYfbGMF79EZad6DXVTr6hLqCbE6xiI+4QEe4UcGFxcjquIXtnAQ6iSMOoQXKe7PEMMMJmNuReIfzSDW0IdpSRxj6A0GsqaNqjzHS3SFOg1TVYLrNL5KDjYLlybYbOCmih28C9wPOdz/OuR1lBo4jqKYWm9IruN3HIY6wWM8DQKnWe4iuNI79gxzYd2MQJQzjmWsYSQ4GMCC89vSkkBVEtFDdId1LZL0waU3lyWRfsPnPIFZFPAWR7JvRnrzBObxGh0xgULq4Rd8xGqYolmUJN+jYh6xH/8ynDeaYCrSKxrRYk7zlg95/yYE0i/DX8mPoxHamhSo732HcQa9YZfP6Vr3lwAAAABJRU5ErkJggg==",
  FL_Fluvial_Official:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAAB7klEQVRYhe2YLa/CMBSGz725BovD4XFLcFOAQxN+Ag47i8XicPNoHHJuen4OR3DIkbehN4OctqfrRhC8puto6dPT89Hsp6qqij5Yv58MR1/AFvQFDNUXMFStAG42m88F3G63qu0KMqiSAO52uz2906B14F6vR0mSNFqjsQU5uFcwLW6cVI0AD4eDddE2j9sbMM9zKoqiNQCXvADLsqTj8fg2OPIBvFwulKZptzSMxIC73e6dXP8SpZkQp4/jmKIoUs/9ft97/p9rQBO4+XxO4/HYex4nJ6Cv9IZOpxNlWWacLd14a4D1aiFZHGNGoxEtFgvrOHGpcy3KlTiJXGUwyIKvvqbh1uu1aD4q0vl8Vu4wm838AE21ti4kbSRvHJO+1ZBHtGIcAOGrJkA2D8IS0gI/nU5VG3IhoEcJ5RQcJJy1mqQmnAaXmoyAcN7JZGLNZ/v9XrXwJRMgnnUf7oByqfuY57p4sIBSC6xWK9WiTnMyvddy/U6+txnTH+pS9ipXsEiCKSiKIVgbbsBdwyQW0oJLcQqOYviVSS4L1TewXC7lgD7Sd0QkbRsAp/oGhsMhO8aaZnBVMiVQekQxEi1AuGOWWtAWlK1/wMRig8FAPV+vV+Uqtr4rlXXyhdUnwFzq9BMwl4gRrQgIk889iYjurer9mgCZ5LYAAAAASUVORK5CYII=",
  WS_Windspeed_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACSUlEQVRYhdWYLYwCMRCF5y6nUJBVOAgOjUPjsGCRSBwajUMisWgcCQ5HgkORgEMRUNi9PJKSttvZbn/IcZ/h2L1uH9POm+l+pWma0gfz/cni6D8I/AkZPJ1OX3/3ej2q1+sxNCl478HT6USLxSJzvdlsUr/fjybQe4m5aB0OB5pMJrTb7UJ0vQjO4tvtRsvlki6XS+ZetVql4XD4twJlIBQRlAkVGd0HEdHZbKZc63a71Gq1vJ4XzWYgDIkjBMmsVivv5wbZzHq9pv1+T4/Hw/q/SBqfKHoJNC2jDUTxrQI533s3VoFYmpA9pP9I12qTmySwjVjiQN4KiATTYW1mPp8bzTeUdrtNnU5HeQpqOhKtVCrReDxW7hkjiGV1FYeJUeJ0i9HZbreZa8IFTG5gFKgvq21SCBNRQabie1GwjQSIoFWgPACMRiNqNBrsdJz4wWDAjpH3m1waa7WaXaA8AHW0UqnQ/X5nJ+O8LS9br9fr8xNGL2Nq0xSBeoskiny5XGYn47IPZs6RJMnzvrwfEQwTisDNZmMcgChycNZhqzT6fa7jYX3QpUVCUoiI4dOWJPqPggNwKJUkr+jjISaLELjWZgEyV/dFmYxRY+NyA1zsowhFmlmnhjVmw2CqKCac2q0Yx8qiwgRBDWtRQlp+5zOJS/sV44zsHEFEQhcoytrxeHx6Jkpjnne64HWqQ6brloOaHUuUjPex09QvuibAWwWS1GjqxHw/E3xwNy23TKi5R3uzwB0RQiwGRH/1gYb3fD6/lj4oeYjoF/FNPfIyUMs3AAAAAElFTkSuQmCC",
  DR_Subsidence_France_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAABuUlEQVRYhe2XMZKCMBSG3+5YUIMlXsCG4QBUnMCGC0hNbUlJTY0XsOEEVJzAhhlqKdXajp2XnTjKApvkJTvOjn8TDJnk8yUv7+ej7/seXlifrwwHb0ANegNS9QakyghglmXa5tJ6UZ9OJ9jv9+zZdV2I45g854KO9a00TZ9+d12nZV5yBKuqgrquR99ZlgW73e4+RiWqpDOIUZuCQ91uN9byMRjVYaR/k9IWHw4HaJpGaCw1YaQBZSPAo6gqYcCiKLQdfBkJAcpGbU5JkkiN//NKYtu21Pj/Uepwi3Vus4yEI3i9XrUsKPtHhQHzPFfhIYtUi4MggDAMn/p0H4XZCGLFmJPv+z/e6gacNAuPC/FnLFuPlWGq+I9BqoILZzEmCTqT9Xp97+fFH8GPxyPrQ+eiU0IR5Nput7BarWYt1pgo5lUqSdAtbzYblhjL5RLatgXHceByudxb1PB57KwaAUSVZQnn85lBep6nvLColK4Z3F6MUBRF7GxS78i5I6Bci9GwYnJg8Zd1KEPN2TiSWcArh0OaqtVkN4OQHM4EpDa7ZQpSqx80ATkJiJmlIl51sMXvYhFNrgUAX/vaxCjIsX8jAAAAAElFTkSuQmCC",
  EQ_Bedrock_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAABxElEQVRYhe2YLZODMBCG95iaSmrrqusYbAX0J7S2Do2sxfYvgMNWokFgO3XVdbWtrORmmUtnk+MjuYQbBO9Mp0kG2Cf7LkmGr6qqKhixrDHDwQRoQBOgriZAXU2AupoAdTV6wNnQAZIkgcfjAcvlEoIgUL5/0Ay+Xq8aDoX/2FfVoIB5nnP9OI7HBXi73bj++/1WzuJggG0gYlb7pAwYRVH9u16vnde1gYhZNQp4Op0+7SzLOq/tAimKQjqmNOD9fq9r6K+BqMqyNA+Ypql0IBH8cDjAfD7nxnDCxgCptaKaavFyuXD91WoFx+ORGzufz2YAEUC0lqqpFun1NHO03fVMqhnaoVITaJdoNy4ptm3XbdE613U/7e12y00IV4M2bTYb8DwPLBU43E/RrjAMuXG6Q4jWYRAmx3GkYzEuSyzeLrHNnmWLidrVZx1mRkaMq/70gTY/n8/W2xaLBZcJ+KlNahc7rVDb1us17Ha7X8/DMsCFnE2UlogYT+vbjFhDOGuawa4ak5XWXowZopJ9M/8NsMk+JrTchLRPM20gvu+PA7ANBJcjE9IGbAJRWbr6ZOTAirsL1X6/HxcgZpFmzZS9AADfAwO8sYeMM8sAAAAASUVORK5CYII=",
  FL_Surge_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACAUlEQVRYhe1XIYzCQBC8f4ckWAwai0YQJBZsDcEisVgklmDQWCQg0NhqDJYgkSXTZMn9/e7dHuWT5tMxbbO97txub+buK8uyzJQY32UmZyqCH0BFsCgqgkURTXA+nxeKLxYLc7/f1fmiCIaSb7fb/Ho+n8V3Ho+HWS6X6pxqgvasJaJpmubX3W7Hxu1x2iqqrA4f42ZtJ+RIT6dTU6/X83u0FtWT4m8RRMuoKn+JdrtthsMhm8HbYpdcs9lULZJareaN4zu+PDa8FcTPfjgc8vvZbPYjhravVqvX82Qy+dUutDUUR9sHg4HpdDrxBMuAykmK4n8RxMIoanWIXy4Xdc4ogiTWUgJatev1mo3TuM1m83mCtr8igWtVSE5Ocb1e2UnYxI7HoyqvSmYkRyGrAnnOf0nfJKvsdrum1+u9T5DzzxDgIu+McY2A4G2xmwgV4ayKgIogEa4SEYxPksSbx4a3gmjNfr83rVaLtSL8R7fbTYyj9fgXG40G20qK9/t9cVdTWV1RRBOUNI5g72A40LFAiyiCIAeNk0Ba6NvOQ65Ck7ARRZDISXZGQiwdiohYjAypCbqk3Fa5cfcZ1beJhTybEJQZ7Jp9Mw4JsyY+Go1yqeLgrSBaxZ3EbLhxtzKa8b7Ng9rqXN90F4x7MoOIn06n1zPcZzwes3Gf1ZVbqI0xTyi9WkgGIe5DAAAAAElFTkSuQmCC",
  CS_Lightning_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAABnUlEQVRYhdWYMZJFQBCGe7c2EUocQOISQpeQOIBEKJUKJQKhROAKQpeQCIQSodBWq2KnFIPp9sp+ifeK6vlruufv5muapglezPebxSE/nMHiOIZxHNf/nueBaZqkmCwpHoYBkiTZvWcYBvi+rxybJcVH4pC+76GqKuXYSgLbtoWiKOadS9P09Pm6rlWWmbldg1EUrb+bplFe+CqXahB3CVPFxZ26lArEVOZ5zrwnf1w55dIafFLcEh8dQMahQPS0T5BlmZpA0XCf5Gyd17e6/ysQT9gnOFvnUCAef03THpWI8c9s5tSosaU90TGumvWtaebMs66g6/qt518/UbMMrHst0bIscF2XHJvFZvZaIoc4eMoHgyBgi0UWuB1YMbV3D4IMssDtnMiV2gXSKb4yL2K6KTtK2sGyLKX3bdsmp5skUDYqYRtzHIcSfoac4uW6fXMTX64okHYQG/1es3+VzcDmvZej7kTYjZqj7kTIAnEcW+CqOxGywK7r5itn3YmQBaLVcLc3EZJA/GqFfsfd3kRIAvH0hmHILmoFAH4BXU+se9S3vAAAAAAASUVORK5CYII=",
  FL_Pluvial_Global_Fathom:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABIUlEQVRIid3UPUoEQRAF4M+fQDEQE41MDPQCJptrKAheQq+gF1CMXUQQvIJgZmSgiQgKpqYmyiaKGCyswfRIMzs70yuzLPigaKaorvde0VP8J8zjMpwjwSl64RwJOoGgE+UadXWETxxGubG42g6EE00QFF218BVIj0NuD1c4x2Jdw6qZr+ItNM/jAE/R92YdwaCZL+Gl0DyP72EIymY+h/sBzYtRS1Cc+bRsvnmDxnFSUEiakyQx+wMupVxOIvhogmCyougmkFRhC8+4xm4CcR9aqh3s4AIbmI3ypQ5aOMMj3tHFXYKIBazIXlwf4uStv+2XhyCsFHHD3FYXr7KdM4VlzET1vQQhpTVtrCu3uiZ7trGQOoJfRcMi9Y9uZJ2PHz9ThHEdZt2fsAAAAABJRU5ErkJggg==",
  FL_Fluvial_Global_Fathom:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABQElEQVRIieXVzypEYRjH8c+gxE6KlIUoSS6AWYqdWbkBli4BZScLN6CkrCyUspgLsFIWlFKysLKQxWiiZGNYnDOaGefMvDMMC796Fu+fvr/neZ/3vIf/pAXsIdMO+Axe8I7teG4F+dh04DvwMTzE8HKs47JiPN8qfBC3NfByvIYYdNSB9+IYoynr3SEZphl04gDTIZBWtCv5WNJisxn4WpPwcqyGGjy3aFAMNcjjKQXyY8rWgO+wHK+FVgS6KqCzWMKkr1eziFLFuNFz8WmQSZqso0y8L8QgQ3UFREdxjUcU8IZhjGMqAZTDFu5xhJ16rtkGWY1UZFfWIvYxh56aClBdwWmc6QT64yiJHroLUWVJ6hP17KxBgs7VvxU3KRVsJLD+psknOMSVqMGFeOOQqMG5gAQSM2pWoV90W36tv68P0JmDQVxOA/sAAAAASUVORK5CYII=",
  WF_Wildfire_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAAB0ElEQVRYhe2YIbCCQBBA9//5hSjRppVotGq0abVptFqtViI0KzSjGInSqESiRiN/lpH5IvrZvT3U4Cs6N3f6WG6X5b6yLMvgjfl+Zzn4CGpAWfB4PILneY0LipJkt9tBFEXQarVgPp/rNbsgzuL1eg3n8zn/3m63tYuKBfFW27ZdGlssFmCaptQtR5wkKGIYRmkMhZMkkf50jpYsHgwGlbHNZqNFki14OBwqY51O5+5clJTCFgyCoCL5335brVYiRbYglpTtdstacy/qVJT3IKdIcy/oGrZgmqb5ZxzHrHVYjlTQksWUP9/v968TdF23dg434gUswXv7rumG4Yc6EW/jbRSun8McsIB3u13SCnIEb5+3iIocXMoONWlIgo7jKIk8AiPo+z5pLukWF6VFFxh5avRrI4j77JU8/Z0Eu3AOtYKqifCIMAz1Ct42oxJUOpunR7CAeuG1gpZlaVIqg20bhVrByWTSiOB4PNYjCJr3YQH1rY8kOJvNNCj9MZ1OyXNJgni1/X5fLAaXl3tqowCcQj0cDvMfl8I9eWCfLGD/p9J84j5eLpfsdUpHH9gqYRdNrZGj0Qh6vR5bDhGfzWArdjqdKrIYMUwu0RkNAPwCZU66Sb+VfygAAAAASUVORK5CYII=",
  FL_Fluvial_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACSUlEQVRYhdWYLYwCMRCF5y6nUJBVOAgOjUPjsGCRSBwajUMisWgcCQ5HgkORgEMRUNi9PJKSttvZbn/IcZ/h2L1uH9POm+l+pWma0gfz/cni6D8I/AkZPJ1OX3/3ej2q1+sxNCl478HT6USLxSJzvdlsUr/fjybQe4m5aB0OB5pMJrTb7UJ0vQjO4tvtRsvlki6XS+ZetVql4XD4twJlIBQRlAkVGd0HEdHZbKZc63a71Gq1vJ4XzWYgDIkjBMmsVivv5wbZzHq9pv1+T4/Hw/q/SBqfKHoJNC2jDUTxrQI533s3VoFYmpA9pP9I12qTmySwjVjiQN4KiATTYW1mPp8bzTeUdrtNnU5HeQpqOhKtVCrReDxW7hkjiGV1FYeJUeJ0i9HZbreZa8IFTG5gFKgvq21SCBNRQabie1GwjQSIoFWgPACMRiNqNBrsdJz4wWDAjpH3m1waa7WaXaA8AHW0UqnQ/X5nJ+O8LS9br9fr8xNGL2Nq0xSBeoskiny5XGYn47IPZs6RJMnzvrwfEQwTisDNZmMcgChycNZhqzT6fa7jYX3QpUVCUoiI4dOWJPqPggNwKJUkr+jjISaLELjWZgEyV/dFmYxRY+NyA1zsowhFmlmnhjVmw2CqKCac2q0Yx8qiwgRBDWtRQlp+5zOJS/sV44zsHEFEQhcoytrxeHx6Jkpjnne64HWqQ6brloOaHUuUjPex09QvuibAWwWS1GjqxHw/E3xwNy23TKi5R3uzwB0RQiwGRH/1gYb3fD6/lj4oeYjoF/FNPfIyUMs3AAAAAElFTkSuQmCC",
  WF_DistToBush_AUS_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACLElEQVRYhc1YLY8CMRCdu5xBYpEgkWBRaBxILH8Bi0VisUhwaBSadUiQWHDIvbxmuxlKu/3cwEsuuU223dfXmXkz/OR5ntMX47eK2uFwiGJ+v9+jT24kuFwu6Xg8Rm2+Wq1ovV6Xz4vFgk6nUxxBnBobPZ/PKHISt9tNHBaYTqe03+/F/q7qvsTgdrul8/mchJgOILjb7crDDwYDGg6HbgRxylSqVaHVaglVJbrdLk0mE+OK8orn87lYXCdGoxHNZrOXL+DGLpeL8atvZQYvbzab8hnxEgq+1vS/7TtvSdLpdKJIqcCtuOxnyu4/0wJsykuEDshEvMNjt9FoiHChIilwYBcgu3u93tubwU5iSyoop8abbZ1O6UonCSVHhbqy/nFAXajsCm8FYX+hDsOvHAeA03BIBVGPZenxVjDG/lAdpIs0m03tlaKKXK/X8tmLYArzp8KjZdZCVY4sy17CJygGUwBZiwOrWa5a7ccIUqEkMRV1SeVFEHFTB6SKusrwUQUBaQa89KCBkPAiqLuCWOgSj3c3XgTrbMdk+HD1yIegzZdjASVxzWpvaGwWVPAmMyXG47HYDQrqvPvjSSIzWEeOfAj6GLwr1HiLItjv95MTrJpFJJwJ2qYvX7h27V7tljqvhACDk65zTkKQitkBRm8D/PXxeIjWqd1ui78Qqwxu+W1DfqrBK+mvW7bRMgS11cFUzW1tBFO1ZkkJ8mL+lQrybucrFeRIoiAR/QPgeSDcXUDU/wAAAABJRU5ErkJggg==",
  VO_AshThickness_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACCUlEQVRYheWYrY/CQBDF5y5ncGAxeDQWhUXzJ4DFIUGCw4JGkeCQoJAQJAqNIwWF3MtrWFLKdj9mt4SEX0LabNvp60zn7ZYfIYSgD+b3k8XRVwu8XC40GAxot9t5xfEWCCH4pSkWi1Qul33Dk3eTjEYjut1ucbbywDuDpVKJCoVCvI9yzudzZUa5eAuMouixf71e6XA4BBMXI5hst1vR7/dFFEXKABjH8dVqxb1FzB/3wdAE1WpVeex4PMZlxvFKpeKTP/8mUYHGAb1ezz+YV/4TDIfDuOyyvFmld4Vd4iQoJ6wGTUL38ociaInX6zVtNhvqdrvBRAbJoAQNcT6fQ4bMp0lCws4gOhWzCIwaW5S01Wo9jdN9pmm322zJrJkE7xqa4nQ6PbYQB5Ljcn86nb5XIBohSb1e154Pkdz52VkgFgNJsFBoNBrG6yaTSf4CkYX0YqDT6Vhdi3JjCnTFSaAqCy6ZWSwW+QmUs0UajMm5FzSbzcwYnCxaCxyPx9obS5G1Wk0bZzabueizE2jz4QOR0k7kCjsL2FRQgcvl0iqY9DxTV6dtSodRoKvJQuR+vzeel3xvvQTihq7YXINXwsa8tQJtn5KLrvGMAmEHKlsJjcl2MgVyTJWDyXaUAt+VPYnOxpQCXc3UF52NvQh0MdGQpFdJmQJdTDQkWX+ZPAn0WfmG4MXWiOgfFYA61RSA5lYAAAAASUVORK5CYII=",
  EQ_LocalSoilCondition_Global_SwissRe:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAACHklEQVRYhe1XIc/CMBDt9+UzKAISi0QSHBKHRmKxSCwWicUi+QsEhUMjsUiQyH15S2653nrjRrcEsZcs7dbt+nZ9d9f+JEmSuC/G7zeTcw3BCtAQjEVDMBYNwVg0BGPxV/cE6/U6befzuev3+6W/r9WDz+cz6+/3+49s1Epwt9t595fLpbSNWgm+Xi/v/nQ6lbbxEUEs3eFw8JYw9I4ECIeeV05wu9266/WathrwAyFozysjSFFJkDoj3O/3rD8YDILPKyd4PB4LiWiYzWbeSBkvmglCO+fz2USce7XVaqUt9yLkYUUuUcsl1IAJaSIQn0wm2Zvcq8PhMG3hRU5ss9m4TqfjWce99LZH0EqOqgKf8Ha7BSsFJ97r9TLyiGiZhjAGm5xH6SDBJEQEfQJVCr7ctLwE6R0LcudieAJ4PB6u2+3mWukl/rfL5dJLPZCBJAX7sKVhNBp5I9EH9yJZWCVThOhSBz3G4F3KiSaobaG4PjVArwiKovKn7gfxsZb3pLZ4yiEsFou3BMk+dKvJQfUgTw8SUvhadCJhY+JQBUIe5KDgNBN0ir6m02nwXZ5SqE85T64EyMgcqG1oCwlCX3JimQYIq9UqHceFPjAej4M/pZEJbTxMaYb0gTzHyxOWiTxhTSmI2qJaLO2YCEJDiDSuNRlEiFpLYGha40WBO8F0qgsFTLvdtnyaQ9HJTm4egI/zILTI9WnxXmk45/4BJ1QMGsWX8RAAAAAASUVORK5CYII=",
  premuim:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAk1BMVEUAAAD//wDMmRrRixfVlRXYkxfVlBjWlBjVlRnVlhnVlRjVlhjWlBfUlRnVlhnWlRfWlBnWlBjWlRjVlRnVlRjVlRfVlBjVlRfWlhjVlRjVlRjVlRjWlRnVlRjVlRjVlRjVlRjVlRfVlhnWlRjVlRjVlRjVlRjVlRjVlRfVlRjVlRjVlRjVlRjVlBjVlRjVlRj///9qMIVSAAAAL3RSTlMAAQoLGCErSltcYGFkZWZvcHV2nJ2krK6ztLW2xcbHyM7P0NHX2Nnf5OXm6PH5/GUmFj4AAAABYktHRDCu3C3kAAAA0ElEQVQYGY3B61qCQBQF0D3hpWBQp1LxQiiBgiL7/d+uOTN8iPaj1sI/xJdLjCfKZJtvWvnmyyjcRRwI0QtOHCgDeCo68UEZKYh3dpZLdgxESq8dj1t6e4gdvRWworeFyEnezlkyASZJdr6RPMKKab2i90ZLA6hpNVN0pg2tCkBN0c7hzFuKCoCmc4VzpaNh5RRrOGuKA8SOYoHR58cIC4otREoxMw3ZmBnFHsLwFwOhwoIPilDBC0oOFC/oRRwIcadMmhxpHZLUKDzRdaXxtx99ajN8k1SE8AAAAABJRU5ErkJggg=="
};
export default pngArray;

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import { Tooltip } from "react-tooltip";
import { ReactComponent as IconExport } from "assets/icon_export.svg";
import Icon from "components/icon/icon";
import { AppContext } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import styles from "./address-encoding-table.module.scss";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Item } from "helpers/models";
import * as XLSX from "xlsx";
import AddressInput from "./address-input/address-input";

interface Props {
	data: any[];
	type?: "candidates" | "no_match" | "match";
	handleMove: (id: string, type?: string) => void;
	handleData: (data: any) => void;
	isShowBtn?: boolean;
}

export default function AddressEncodingTable(props: Props) {
	const [context, dispatch] = useContext(AppContext);

	const [data, setData] = useState(props.data);
	const [inputValue, setInputValue] = useState("");

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));

		setPage(0);
	};

	const currentData = data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const [t] = useTranslation();

	const searchData = (value: string) => {
		setInputValue(value);

		if (!value) {
			setData(props.data);
			setPage(0);
			return;
		}

		if (!props.data || props.data.length === 0) {
			setPage(0);
			return;
		}

		const data = props.data.filter((item) => {
			if (item.name && item.name.toLowerCase().includes(value.toLowerCase())) {
				return true;
			}
			if (item.address && item.address.toLowerCase().includes(value.toLowerCase())) {
				return true;
			}
			return false;
		});

		setData(data);

		setPage(0);
	};

	const [active, setActive] = useState("name");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");

	const handleSort = (property: "name" | "address") => {
		setActive(property);
		setDirection(direction === "asc" ? "desc" : "asc");

		props.data &&
			props.data.sort((a, b) => {
				if (a[property] < b[property]) {
					return direction === "asc" ? 1 : -1;
				}
				if (a[property] > b[property]) {
					return direction === "asc" ? -1 : 1;
				}
				return 0;
			});

		// props.handleSort(props.data);
		setData(props.data);
	};

	useEffect(() => {
		setData(props.data);
	}, [props]);

	const exportData = () => {
		if (!context.currentMultipleLocations) {
			return;
		}

		const name = context.currentMultipleLocations.name;

		if (!name) {
			return;
		}

		if (!props.data || props.data.length === 0) {
			return;
		}

		// const data1 = [[t("headerName"), name]];
		const data1 = [{ "": t("headerName"), " ": name }];
		const worksheet1 = XLSX.utils.json_to_sheet(data1);

		// if (worksheet1["!ref"]) {
		// 	const range = XLSX.utils.decode_range(worksheet1["!ref"]);
		// 	for (let col = range.s.c; col <= range.e.c; col++) {
		// 		worksheet1[XLSX.utils.encode_cell({ c: col, r: 0 })] = null;
		// 	}
		// }

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet1, t("sheet.header"));

		const data: any[] = props.data.map((e) => {
			return {
				[t("importLocationSet.id")]: e.code,
				[t("importLocationSet.name")]: e.name,
				[t("importLocationSet.country")]: e.country,
				[t("importLocationSet.countryISO3")]: e.countryISO3,
				[t("importLocationSet.province")]: e.province,
				[t("importLocationSet.place")]: e.place,
				[t("importLocationSet.county")]: e.county,
				[t("importLocationSet.street")]: e.street,
				[t("importLocationSet.number")]: e.number,
				[t("importLocationSet.zipCode")]: e.zipCode,
				[t("importLocationSet.coordinateSource")]: e.coordinateSystem,
				[t("importLocationSet.latitude")]: e.latitude,
				[t("importLocationSet.longitude")]: e.longitude,
			};
		});

		const worksheet = XLSX.utils.json_to_sheet(data);
		XLSX.utils.book_append_sheet(workbook, worksheet, t("sheet.site"));

		const fileName = name.replace(/\s+/g, "_");

		XLSX.writeFile(workbook, `${fileName}.xlsx`);
	};

	const editItem = (item: Item) => {
		const data = props.data.map((e) => {
			if (e.id === item.id) {
				return { ...e, ...item };
			}
			return e;
		});

		props.handleData(data);
	};

	return (
		<Paper sx={{ width: "100%", overflow: "auto", boxShadow: "none", flex: 1 }}>
			<TextField
				size="small"
				hiddenLabel
				id="outlined-basic"
				// label={t("addressEncoding.search")}
				variant="outlined"
				value={inputValue}
				onChange={(e) => searchData(e.target.value)}
				color="success"
				placeholder={t("addressEncoding.search")}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<span className="material-icons-outlined">search</span>
						</InputAdornment>
					),
				}}
				style={{ marginTop: "10px", marginBottom: "10px", width: "30%" }}
			/>
			{!data || data.length === 0 ? (
				<div style={{ textAlign: "center", paddingTop: "20px" }}>
					<span
						className="material-icons-outlined"
						style={{ fontSize: "100px", color: "gray" }}
					>
						content_paste_search
					</span>
					<div style={{ fontSize: "16px", marginTop: "10px", color: "gray" }}>{t("addressEncoding.noResults")}</div>
				</div>
			) : (
				<div>
					<TableContainer style={{ maxHeight: "50vh", height: "50vh", overflowY: "auto", width: "100%" }}>
						<Table
							size="small"
							stickyHeader
							aria-label="sticky  table"
						// style={{maxHeight: "100px", height: "100px", overflowY: "auto", width: "100%"}}
						>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: "20%" }}>
										<TableSortLabel
											active={active === "name"}
											onClick={() => handleSort("name")}
											direction={direction}
										>
											{t("addressEncoding.table.name")}
										</TableSortLabel>
									</TableCell>
									<TableCell style={{ width: props.type === "no_match" ? "70%" : "50%" }}>
										<TableSortLabel
											active={active === "address"}
											onClick={() => handleSort("address")}
											direction={direction}
										>
											{t("addressEncoding.table.address")}
										</TableSortLabel>
									</TableCell>
									{props.type !== "no_match" && (
										<TableCell style={{ width: "20%" }}>
											<TableSortLabel
												active={active === "address"}
												onClick={() => handleSort("address")}
												direction={direction}
											>
												{t("addressEncoding.table.geoQuality")}
												<div
													data-tooltip-id="address-tooltip"
													data-tooltip-html={`
                                                        <div style="font-size: 16px; font-weight: bold; line-height: 1.5;">${t("addressEncoding.tip.geoQuality.title")}</div>
                                                        <table style="width: 100%;">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 40%; white-space: normal; font-size: 14px; font-weight: bold; vertical-align: top;  padding: 3px 0;">${t(
														"addressEncoding.tip.geoQuality.Exact"
													)}</td>
                                                                    <td style="width: 60%; white-space: normal; vertical-align: top;  padding: 3px 0;">
                                                                        ${t("addressEncoding.tip.geoQuality.ExactExp")}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="white-space: normal; font-size: 14px; font-weight: bold; vertical-align: top;  padding: 3px 0;">${t(
														"addressEncoding.tip.geoQuality.Place"
													)}</td>
                                                                    <td style="white-space: normal; vertical-align: top;  padding: 3px 0;">${t("addressEncoding.tip.geoQuality.PlaceExp")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="white-space: normal; font-size: 14px; font-weight: bold; vertical-align: top;  padding: 3px 0;">${t(
														"addressEncoding.tip.geoQuality.ZIP"
													)}</td>
                                                                    <td style="white-space: normal; vertical-align: top;  padding: 3px 0;">${t("addressEncoding.tip.geoQuality.ZIPExp")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="white-space: normal; font-size: 14px; font-weight: bold; vertical-align: top;  padding: 3px 0;">${t(
														"addressEncoding.tip.geoQuality.ADMIN1"
													)}</td>
                                                                    <td style="white-space: normal; vertical-align: top;  padding: 3px 0;">${t("addressEncoding.tip.geoQuality.ADMIN1Exp")}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    `}
												>
													<Icon
														icon="info"
														type="outlined"
														style={{ color: "#666" }}
													/>
												</div>
											</TableSortLabel>
											<Tooltip
												id="address-tooltip"
												style={{ maxWidth: "300px", width: "300px", fontSize: "13px" }}
											/>
										</TableCell>
									)}
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentData &&
									currentData.map((item, index) => {
										if (!item) {
											return null;
										}

										// Exact
										// Place
										// ZIP
										// ADMIN1
										let quality = t("addressEncoding.tip.geoQuality.Exact");
										if (item.quality <= 1) {
											quality = t("addressEncoding.tip.geoQuality.ADMIN1");
										} else if (item.quality <= 2) {
											quality = t("addressEncoding.tip.geoQuality.Place");
										} else if (item.quality <= 3) {
											quality = t("addressEncoding.tip.geoQuality.ZIP");
										}
										// else if (item.quality === 4) {
										// 	quality = "Exact";
										// }
										return (
											<TableRow key={item.code}>
												<TableCell>{item.name}</TableCell>
												<TableCell>
													{props.type === "no_match" ? (
														<div>
															{item && (
																<AddressInput
																	item={item}
																	edit={editItem}
																/>
															)}
														</div>
													) : (
														item.address
													)}
												</TableCell>
												{props.type !== "no_match" && (
													<TableCell>
														<div style={{ display: "flex", alignItems: "center" }}>
															{quality}
															{/* {quality !== "Exact" && (
																<Icon
																	icon="warning"
																	style={{ color: "orange" }}
																/>
															)} */}
														</div>
													</TableCell>
												)}
												<TableCell>
													{props.type === "candidates" && (
														<div style={{ display: "flex", justifyContent: "space-between" }}>
															<div
																className="tooltip tooltip-left"
																data-tip={t("addressEncoding.tip.no_match")}
															>
																<Icon
																	style={{ color: "orange" }}
																	icon="warning"
																	onClick={() => props.handleMove(item.id, "skip")}
																></Icon>
															</div>
															<div
																className="tooltip tooltip-left"
																data-tip={t("addressEncoding.tip.candidates")}
															>
																<Icon
																	style={{ color: "green" }}
																	icon="where_to_vote"
																	onClick={() => props.handleMove(item.id)}
																></Icon>
															</div>
														</div>
													)}
													{props.type === "no_match" && (
														<div style={{ display: "flex", justifyContent: "space-between" }}>
															<Button
																color="success"
																onClick={() => props.handleMove(item.id, "complete")}
															>
																{t("addressEncoding.table.complete")}
															</Button>
															<div
																className="tooltip tooltip-left"
																data-tip={t("addressEncoding.tip.no_match")}
															>
																<Icon
																	icon="delete"
																	style={{ color: "#666" }}
																	onClick={() => props.handleMove(item.id)}
																></Icon>
															</div>
														</div>
													)}
													{props.type === "match" && (
														<div
															className="tooltip tooltip-left"
															data-tip={t("addressEncoding.tip.match")}
														>
															<Icon
																icon="error"
																style={{ color: "#666" }}
																onClick={() => props.handleMove(item.id)}
															></Icon>
														</div>
													)}
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", alignItems: "center" }}>
						{props.isShowBtn && (
							<Button
								color="success"
								startIcon={<IconExport />}
								onClick={exportData}
							>
								{t("addressEncoding.table.export")}
							</Button>
						)}
						<div style={{ marginLeft: "auto" }}>
							<TablePagination
								component="div"
								count={data.length}
								rowsPerPageOptions={[10, 15, 25]}
								page={page}
								onPageChange={handleChangePage}
								rowsPerPage={rowsPerPage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage={t("addressEncoding.table.rowsPerPage")}
								slotProps={{
									select: {
										native: true,
									},
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</Paper>
	);
}

import { Table } from "helpers/models";
import rich from "./rich";
import { EChartOption } from "echarts";

const y = (t: any, grouped: Table): EChartOption.YAxis => ({
  inverse: true,
  type: "category",
  data: Object.keys(grouped),

  axisLabel: {
    width: 160,
    formatter: (value: string) => {
      const title = `{${value}|}{value|${t("layerId." + value)}}`;
      return value.includes("Fathom") ? title + "{premuim|}" : title;
    },

    rich: {
      value: {
        lineHeight: 12,
        align: "left",
        fontSize: 8
        // width: 100,
      },
      ...rich
    },
    show: true,
    fontSize: 8,
    fontWeight: "normal",
    color: "#333"
  },
  axisLine: {
    show: true,
    lineStyle: {
      color: "#D9E7F7",
      width: 1
    }
  }
});

export default y;

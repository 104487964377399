import pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import font from "report/common/font";

pdfMake.fonts = font;

const generate = async (
  docDefinition: TDocumentDefinitions,
  docName: string
) => {
  pdfMake.createPdf(docDefinition).download(docName);
};

export default generate;

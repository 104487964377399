import { EChartTitleOption } from "echarts";

const title = (t: any): EChartTitleOption => ({
  text: t("report.Number of Locations"),
  top: "bottom",
  left: "center",
  textStyle: {
    color: "#333",
    fontSize: 10,
    fontWeight: 500
  }
});

export default title;

import { Alignment, Content } from "pdfmake/interfaces";

const get = (
  text: string = "Analysis Overview",
  fontSize: number = 14,
  alignment: Alignment = "left"
): Content => ({
  text,
  fontSize,
  margin: [0, 0, 0, 8],
  alignment,
  opacity: 0.87
});

export default get;

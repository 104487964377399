import { Content } from "pdfmake/interfaces";
import getEmail from "report/common/email";
import getUrl from "report/common/url";

const get = (t: any, currentPage: number, pageCount: number): Content => {
  const copyright = t("report.copyright");
  const authorize = t("report.authorize");

  const email = getEmail(copyright);
  const url = getUrl(authorize);

  const copyrightArray = copyright.split(email);
  const authorizeArray = authorize.split(url);

  return {
    columns: [
      {
        stack: [
          {
            text: [
              copyrightArray[0],
              { text: email, color: "blue", link: `mailto:${email}` },
              copyrightArray[1]
            ]
          },
          {
            text: [
              authorizeArray[0],
              { text: url, color: "blue", link: url },
              authorizeArray[1]
            ]
          }
        ],
        width: "*"
      },
      {
        text: currentPage + " / " + pageCount,
        alignment: "right",
        width: "auto",
        fontSize: 10,
        opacity: 0.87
      }
    ],
    fontSize: 6,
    opacity: 0.6,
    characterSpacing: -0.6,
    lineHeight: 1,
    margin: [20, 0, 20, 20]
  };
};

export default get;

import ExcelJS, { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { getLanguage } from "helpers/translations";

export const downloadAnalysis = async (context: any, t: any) => {
  if (!context.currentMultipleLocations) {
    return;
  }
  if (!context.currentMultipleLocations.items) {
    return;
  }

  const language = getLanguage();
  const templateUrl =
    language === "en" ? "/template/location/location_set_en.xlsx" : "/template/location/location_set_cn.xlsx";
  const response = await fetch(templateUrl);
  const templateBlob = await response.blob();
  const templateArray = await new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(new Uint8Array(reader.result as ArrayBuffer));
    reader.onerror = reject;
    reader.readAsArrayBuffer(templateBlob);
  });
  const workbook: Workbook = new ExcelJS.Workbook();

  await workbook.xlsx.load(templateArray);

  const worksheet1 = workbook.getWorksheet(1);
  if (!worksheet1) {
    return;
  }

  const locationObj = context.currentMultipleLocations;

  const locationSetName = locationObj?.name;

  const cellB2 = worksheet1.getCell("B2");
  cellB2.value = locationSetName;

  const cellB3 = worksheet1.getCell("B3");
  cellB3.value = new Date().toLocaleDateString();

  if (!locationObj) {
    return;
  }

  if (!locationObj.items || locationObj.items.length === 0) {
    return;
  }

  const checkedItems = locationObj.items.filter((item: any) => item.checked);

  if (checkedItems.length === 0) {
    return;
  }

  const data = checkedItems.map((e: any) => [
    e.code,
    e.name,
    e.zipCode,
    e.street,
    e.number,
    e.county,
    e.place,
    e.province,
    e.country,
    e.countryISO3,
    e.latitude,
    e.longitude,
    e.coordinateSystem
  ]);

  data.forEach((item: any, index: number) => {
    item.forEach((value: any, i: number) => {
      if (!value) {
        data[index][i] = "";
        return;
      }
      if (value === undefined) {
        data[index][i] = "";
        return;
      }
      if (value.toString().includes("NaN")) {
        data[index][i] = value.replace("NaN", "");
        return;
      }
      if (value.toString().includes("null")) {
        data[index][i] = value.replace("null", "");
        return;
      }
      if (value.toString().includes("undefined")) {
        data[index][i] = value.replace("undefined", "");
        return;
      }
    });
  });

  const worksheet = workbook.getWorksheet(2);
  if (!worksheet) {
    return;
  }

  worksheet.addRows(data);

  const buf = await workbook.xlsx.writeBuffer();

  saveAs(
    new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }),
    `${locationSetName}.xlsx`
  );
};

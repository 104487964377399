import styles from "./agenda-open.module.scss";
import { LegendData } from "helpers/models";
import { AppAction, AppContext } from "helpers/context";
import { useState, useContext, useEffect, useRef } from "react";
import { MapContext } from "helpers/map";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from '@mui/material';
import Icon from "components/icon/icon";
import ResizeObserver from 'resize-observer-polyfill';
import { ReactComponent as MyLocaltionSvg } from "assets/my_localtion.svg";
import { ReactComponent as MapLegendPinSvg } from "assets/icon_map_legend_pin.svg";
import * as turf from '@turf/turf';

interface Props {
	onClose?: (item: LegendData) => void;
}

interface legendColor {
	raster: {
		color?: string;
		opacity?: number;
	};
	polygon: {
		fill?: string;
		fillOpacity?: number;
		stroke?: string;
		strokeDashArray?: any;
		strokeOpacity?: number;
	};
}

interface legendItem {
	title: string;
	symbolizers: legendColor[];
}

function Agenda(props: Props) {
	const [context, dispatch] = useContext(AppContext);
	const { AMap, map } = useContext(MapContext);
	const agendaEl = useRef<HTMLDivElement>(null);
	const [isAtStart, setIsAtStart] = useState(true); // 是否在起始位置
	const [isAtEnd, setIsAtEnd] = useState(false); // 是否在末尾位置
	const [legendArr, setLegendArr] = useState<any[]>([]);
	const [t] = useTranslation();

	const handleLeftClick = () => {
		if (agendaEl.current) {
			const { scrollLeft, scrollWidth, clientWidth } = agendaEl.current;
			// 判断是否在起始位置
			setIsAtStart((scrollLeft - 100) <= 0);
			// 判断是否在末尾位置
			setIsAtEnd(false);
			// 横向滚动 100px，使用平滑滚动
			agendaEl.current.scrollBy({ left: -100, behavior: 'smooth' });
		}
	}

	const handleRightClick = () => {
		if (agendaEl.current) {
			const { scrollLeft, scrollWidth, clientWidth } = agendaEl.current;
			// 判断是否在起始位置
			setIsAtStart(false);
			// 判断是否在末尾位置
			setIsAtEnd((scrollLeft + clientWidth + 100) >= scrollWidth);
			// 横向滚动 100px，使用平滑滚动
			agendaEl.current.scrollBy({ left: 100, behavior: 'smooth' });
		}
	}

	const handleBtnType = () => {
		if (agendaEl.current) {
			const { scrollLeft, scrollWidth, clientWidth } = agendaEl.current;
			// 判断是否在起始位置
			setIsAtStart(scrollLeft === 0);
			// 判断是否在末尾位置
			setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
		}
	}

	const handleClickMyLocation = () => {
		if (context.currentMultipleLocations && context.currentMultipleLocations.items) {
			let bounds: any = [];
			context.currentMultipleLocations.items.map((item: any) => {
				if (item.checked) {
					bounds.push([item.longitude, item.latitude]);
				}
			});
			if (bounds.length === 0) return
			let bbox: any = turf.bboxPolygon(turf.bbox(turf.multiPoint(bounds)));
			var myBounds = new AMap.Bounds([bbox.bbox[2], bbox.bbox[3]], [bbox.bbox[0], bbox.bbox[1]]);
			console.log(myBounds);
			map?.setBounds(myBounds, true, [20, 20, 20, 20]);
		}
	}

	const getLegendArrData = (total: number) => {
		const base = Math.pow(total, 1 / 8);
		let current = 1;
		const thresholds: any = [{
			color: "#fff8a1",
			value: 1
		}];

		let color = ["#fff8a1", "#ffcb8a", "#ff9c86", "#ee6c91", "#cb419c", "#9c2499", "#61168e", "#001789"];
		for (let i = 1; i < color.length; i++) {
			current = current * base;
			thresholds.push({
				color: color[i],
				value: current
			});
			thresholds[i - 1].endValue = current;
		}

		thresholds[7].endValue = total;
		return thresholds;
	}

	useEffect(() => {
		let arr = getLegendArrData(context.currentMultipleLocations?.itemCount || 0)
		setLegendArr(arr)
	}, [context.currentMultipleLocations && context.currentMultipleLocations.itemCount])

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				// 根据目标元素更新对应的宽度状态
				if (entry.target === agendaEl.current) {
					handleBtnType();
				}
			});
		});

		// 开始监听多个元素
		if (agendaEl.current) observer.observe(agendaEl.current);

		// 组件卸载时停止监听
		return () => observer.disconnect();
	}, []);

	return (<>
		<IconButton
			style={{
				flexShrink: 0,
			}}
			sx={{
				"&.Mui-disabled": {
					cursor: "no-drop",
					pointerEvents: "auto"
				}
			}}
			disabled={isAtStart}
			onClick={handleLeftClick}
		>
			<div className="material-icons">chevron_left</div>
		</IconButton>
		<div className={styles.agendaContent} ref={agendaEl}>
			<div style={{ display: "flex" }}>
				{context.currentMultipleLocations &&
					(<div className={styles.agendaItem}>
						<div className={styles.header}>
							<div className={styles.text}>{context.currentMultipleLocations!.name}</div>
							<MyLocaltionSvg
								className="w-[16px] h-[16px] fill-[#0009] cursor-pointer"
								onClick={handleClickMyLocation}
							/>
						</div>
						{context.currentMultipleLocations?.itemCount && (context.currentMultipleLocations?.itemCount > 9) ?
							<div className="flex">{
								legendArr.map((item, index) =>
								(<Tooltip title={`${item.value.toFixed()} - ${item.endValue.toFixed()}(${index === (legendArr.length - 1) ?
									(">" + Math.floor(item.value / item.endValue * 100))
									:
									(
										(item.value.toFixed() == 1 ? 0 : Math.floor((item.value / (context.currentMultipleLocations?.itemCount || 0)) * 100))
										+ "-" +
										(item.endValue.toFixed() == 1 ? 0 : Math.floor((item.endValue / (context.currentMultipleLocations?.itemCount || 0)) * 100)))}%)`} placement="top">
									<div
										className={styles.legendMutipleItem}
										key={index}
									>
										<MapLegendPinSvg
											className="w-[13px] h-[13px] mr-[3px]"
											style={{ fill: item.color }}
										/>
										<span
											style={{
												backgroundColor: item.color,
												opacity: '1',
												flex: 1,
												height: "6px",
											}}
										></span>
									</div>
								</Tooltip>))
							}</div>
							:
							(<Tooltip title={`100%(${context.currentMultipleLocations?.itemCount})`} placement="top">
								<div
									className={styles.legendMutipleItem}
								>
									<MapLegendPinSvg className="w-[13px] h-[13px] mr-[3px] fill-[#001789]" />
									<span
										style={{
											backgroundColor: "#001789",//colorByTiv(0),
											opacity: '1',
											flex: 1,
											height: "6px",
										}}
									></span>
								</div>
							</Tooltip>)}
					</div>)
				}
				{context.layerLegend &&
					(context.layerLegend.map((itemLayer, indexLayer) => (
						itemLayer.layerInfo.checked && (
							<div
								className={styles.agendaItem}
								key={indexLayer}
							>
								<div className={styles.header}>
									<div className={styles.text}>{t("legend." + itemLayer.title)}</div>
									<div
										role="img"
										className={`material-icons ${styles.icon}`}
										aria-hidden="true"
										data-mat-icon-type="font"
										onClick={() => props.onClose!(itemLayer)}
									>
										cancel
									</div>
								</div>
								<div className={styles.legend}>
									{itemLayer.rules &&
										itemLayer.rules.map((item: legendItem, index: number) => (
											<Tooltip title={t("legend." + item.title)} placement="top">
												<div
													className={styles.legendItem}
													key={index}
													style={{
														display: item.symbolizers[0].raster
															? item.symbolizers[0].raster.opacity === 0
																? "none"
																: "flex"
															: item.symbolizers[0].polygon.fillOpacity === 0
																? "none"
																: "flex",
														alignItems: "center",
													}}
												>
													<span
														style={{
															flex: 1,
															height: "100%",
															backgroundColor: item.symbolizers[0].raster ? item.symbolizers[0].raster.color : item.symbolizers[0].polygon.fill,
															opacity: item.symbolizers[0].raster ? item.symbolizers[0].raster.opacity : item.symbolizers[0].polygon.fillOpacity,
														}}
													></span>
												</div>
											</Tooltip>
										))}
								</div>
							</div>
						)
					)))
				}
			</div>
		</div >
		<IconButton
			style={{
				flexShrink: 0,
			}}
			sx={{
				"&.Mui-disabled": {
					cursor: "no-drop",
					pointerEvents: "auto"
				}
			}}
			disabled={isAtEnd}
			onClick={handleRightClick}
		>
			<div className="material-icons">chevron_right</div>
		</IconButton>
	</>);
}

export default Agenda;

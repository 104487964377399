const getMatchingTitle = (returnPeriod: string) => {
  if (returnPeriod === "5" || returnPeriod === "10" || returnPeriod === "20") {
    return "≤50 years";
  } else if (returnPeriod === "50") {
    return "≤50 years";
  } else if (returnPeriod === "100") {
    return "100 years";
  } else if (returnPeriod === "200") {
    return "200 years";
  } else if (returnPeriod === "500") {
    return "500 years";
  } else if (returnPeriod === "1000") {
    return "≥1000 years";
  } else {
    return "Outside";
  }
};

export default getMatchingTitle;

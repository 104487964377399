import { Content } from "pdfmake/interfaces";

const barHeight = 4;
const currentBarHeight = 9;
const xStart = 0;
const yStart = 0;
const lineColor = "#E4E4E4";

const get = (
  colors: string[],
  currentValueIndex: number,
  width: number = 237
): Content => {
  const length = colors.length;
  const barWidth = width / length;

  let xPosition = xStart;

  const canvas = colors.map((color, i) => {
    const rectHeight = i === currentValueIndex ? currentBarHeight : barHeight;
    const yPosition = i === currentValueIndex ? yStart - 2 : yStart;
    const rect = {
      type: "rect" as const,
      x: xPosition,
      y: yPosition,
      w: barWidth,
      h: rectHeight,
      lineColor: lineColor,
      lineWidth: 1,
      color: color
    };
    xPosition += barWidth;
    return rect;
  });
  return {
    canvas: canvas,
    margin: [0, 3, 0, 0]
  };
};

export default get;

import { AppContext, AppAction } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LayersSvg } from "assets/icon_layers.svg";
import Expander from "../expander/expander";
import HailExpander from "../expander/hailExpander/hailExpander"
import styles from "./layers.module.scss";
import { getRiskAnalysis } from "api/layer"
import { CircularProgress, Card, Stack } from "@mui/material";
import FathomGlobalAnalysis from "assets/fathom_global_analysis.svg"
import FathomGlobalAnalysisChina from "assets/fathom_global_analysis_china.svg"

let axiosObj: any = {};
function Layers() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();
  const [layerInfoList, setLayerInfoList] = useState<any[]>([]);
  const [layerCount, setLayerCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const handleCreateData = debounce(async (isRest = false) => {
    if (context.layerList) {
      let list = isRest ? [] : [...layerInfoList]
      let num = 0;
      context.layerList.forEach((item) => {
        item.children.forEach((i) => {
          let isFind = list.find((v: any) => {
            return v.id === i.id
          })
          if (i.checked) {
            num++
          }
          if (isFind) {
            isFind.checked = i.checked
          } else if (i.checked) {
            list.push({ ...i })
          }
        })
      })
      setLayerCount(num);
      let promiseAll: any = [],
        listIndex: any = {};

      list.forEach((item, index) => {
        if (item.checked && !item.valueLabel && !axiosObj[item.id]) {
          let params = {
            longitude: context.currentMultipleLocationInfo ? context.currentMultipleLocationInfo.longitudeWgs : context.searchLocationInfo.en.location.split(",")[0],
            latitude: context.currentMultipleLocationInfo ? context.currentMultipleLocationInfo.latitudeWgs : context.searchLocationInfo.en.location.split(",")[1],
            queryLayers: item.id,
            country: context.currentMultipleLocationInfo ? context.currentMultipleLocationInfo.country : context.searchLocationInfo.en.country,
            oversea: context.currentMultipleLocationInfo ? true : context.searchLocationInfo.en.oversea,
            spatial: context.currentMultipleLocationInfo ? "wgs" : context.searchLocationInfo.en.spatial,
          }
          axiosObj[item.id] = true;
          promiseAll.push(getRiskAnalysis(params))
          listIndex[item.id] = index
        }
      })
      if (promiseAll.length > 0) {
        await Promise.all(promiseAll).then(res => {
          res.forEach((resultItem) => {
            let item = resultItem.data.results.results[0],
              index = listIndex[item.layerId];
            list[index] = { ...list[index], ...item };
            delete axiosObj[item.layerId];
            dispatch({ type: AppAction.updateSingleLayersStatus, payload: { id: item.layerId, status: false } })
          })
        })
      }

      let sortArr: any = [];
      context.layerList?.forEach((item) => {
        item.children.forEach((i) => {
          let isFind = list.find(v => v.id === i.id);
          if (isFind) {
            sortArr.push(isFind);
            if (isFind.valueLabel) {
              dispatch({ type: AppAction.updateSingleLayersStatus, payload: { id: isFind.id, status: false } })
            }
          }
        })
      })
      setLayerInfoList(sortArr);
    }
  }, 1000)

  const handleSetLoadingStatus = async () => {
    if (context.singleLayersStatus) {
      setIsLoading(Object.values(context.singleLayersStatus).filter(item => item).length > 0)
    } else {
      setIsLoading(false)
    }
  }

  //防抖 N秒内重复触发的话只触发最后一次
  function debounce<T extends (...args: any[]) => void>(fn: T, delay: number, immediate = false, executeImmediately = false): (...args: Parameters<T>) => void {
    let timer: NodeJS.Timeout | null = null;
    let isInvoke = false;

    const _debounce = function (this: unknown, ...args: Parameters<T>): void {
      const executeNow = immediate && !isInvoke && executeImmediately;

      if (timer) clearTimeout(timer);

      if (executeNow) {
        fn.apply(this, args);
        isInvoke = true;
      } else {
        timer = setTimeout(() => {
          fn.apply(this, args);
          isInvoke = false;
        }, delay);
      }
    };

    return _debounce;
  }

  useEffect(() => {
    handleCreateData()
  }, [context.layerList])

  useEffect(() => {
    handleSetLoadingStatus()
  }, [context.singleLayersStatus])

  useEffect(() => {
    let singleLayersStatus = {
      ...context.singleLayersStatus
    }
    if (context.layerLegend) {
      context.layerLegend.forEach((item: any) => {
        singleLayersStatus[item.layerId] = item.layerInfo.checked
      })
    }
    axiosObj = {};
    dispatch({ type: AppAction.setSingleLayersStatus, payload: singleLayersStatus });
    handleCreateData(true)
  }, [context.currentMultipleLocationInfo, context.searchLocationInfo])

  useEffect(() => {
    handleSetLoadingStatus()
    handleCreateData()
  }, [])

  return layerCount > 0 ? (<div className={styles.layers}>
    <div className={styles.header}>
      <div className={styles.icon}>
        <LayersSvg />
      </div>
      <div>{t("layers.title")}</div>
      <div className={styles.count}>
        {layerCount}{" "}
        {layerCount === 1 ? t("layers.singular") : t("layers.title")}
      </div>
    </div>
    {isLoading ?
      (<div className="flex flex-col items-center justify-center py-2">
        <CircularProgress color="success" className="my-3" />
        <div>{t("single.loadingLayerAnalyses")}</div>
      </div>) :
      (<>
        {layerInfoList.map((item, index) => (item.checked && (
          <Card
            variant="outlined"
            style={{
              borderRadius: "4px",
              marginTop: "15px",
              marginBottom: "15px",
              marginLeft: "10px",
              border: "1px solid var(--Surface-Border---Outline, rgba(33, 33, 33, .12))",
              background: "#fff",
              boxShadow: "0 1px 6px #0000001f, 0 1px 4px #0000001f",
            }}
            key={index}
          >
            <Expander layerInfo={item}>
              {(item.details && JSON.stringify(item.details) !== "{}") &&
                (<>
                  <Stack spacing={0.3} className={styles.detail}>
                    {
                      item.layerId === "CS_Hail_Global_SwissRe" ?
                        <HailExpander layerInfo={item} />
                        :
                        (<>
                          {Object.keys(item.details).reverse().map(e => {
                            return (
                              <Stack key={e} direction="row" spacing={2}>
                                <div className={styles.key}>
                                  {t("legend." + e).startsWith("legend.") ? e : t("legend." + e)}:
                                </div>
                                <div className={styles.value}>
                                  {t("legend." + item.details[e]).startsWith("legend.")
                                    ? item.details[e]
                                    : t("legend." + item.details[e])}
                                </div>
                              </Stack>
                            );
                          })}
                          {!item.layerId.includes("Fathom") && (<Stack direction="row" spacing={2}>
                            <div className={styles.key}>
                              {t("single.intensityValue")}:
                            </div>
                            <div className={styles.value}>{item.intensity}</div>
                          </Stack>)}
                        </>)
                    }
                  </Stack>
                  {item.layerId.includes("Fathom") && (<div className={styles.fathomLayer}>
                    <div className={styles.fathomHeader}>
                      <span className={styles.fathomHeaderContent}><div className="material-icons">check</div>{t("legend.Flood Depth")}</span>
                      <span className={styles.fathomHeaderContent}><div className="material-icons">check</div>{t("legend.5-1000 Years Return Period")}</span>
                    </div>
                    <div
                      className={styles.fathomFoot}
                      style={{
                        backgroundImage: `url(${context.language === "en" ? FathomGlobalAnalysis : FathomGlobalAnalysisChina})`
                      }}
                    >
                      <div className={styles.fathomFootContent}>
                        <div
                          className="material-icons"
                          style={{
                            color: "#0009",
                            height: "24px",
                            width: "24px",
                            marginRight: "10px",
                            position: "absolute",
                            top: "16px"
                          }}
                        >lock_open</div>
                        <div
                          style={{
                            width: "225px",
                            textIndent: "34px",
                            marginTop: "10px"
                          }}
                        >
                          {t("single.unlockFathom")}
                          <span
                            onClick={() => {
                              if (context.menu.find((item) => item.id === "102004" && item.status === "1")) {
                                dispatch({ type: AppAction.setAnalyseModal, payload: { type: "1", selectId: item.layerId } });
                              } else {
                                dispatch({ type: AppAction.setFathomPermissionDialog, payload: true })
                              }
                            }}
                          >{t("single.analyzeLocation")}</span> {t("single.toUnlockMoreInsights")}
                        </div>
                      </div>
                    </div>
                  </div>)}
                </>)}
            </Expander>
          </Card>
        )))}
      </>)}
  </div >) : null;
}

export default Layers;

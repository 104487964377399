import { Content } from "pdfmake/interfaces";
import layout from "report/common/layout";
import getTitle from "report/common/title";

const get = (t: any, province: string, county: string): Content[] => [
  getTitle(t("report.GEO Details")),
  {
    table: {
      headerRows: 0,
      widths: ["*", "*"],
      body: [
        // [
        //   { text: "Place ID", bold: true, opacity: 0.6 },
        //   {
        //     text: "GhJIjw7tCExxREARgw2ZPK9LWOA",
        //     link: "https://amap.com",
        //     color: "blue",
        //     decoration: "underline"
        //   }
        // ],
        [
          { text: t("report.County Province"), bold: true, opacity: 0.6 },
          { text: `${county} / ${province}` }
        ]
      ]
    },
    layout: layout(),
    fontSize: 8
  }
];

export default get;

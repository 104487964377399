import { LeftPanel, RightPanel, zoomLevelDefault } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Agenda from "./agenda/agenda";
import AgendaOpen from "./agenda-open/agenda-open";
import MultipleAgenda from "./multipleAgenda/multipleAgenda";
import styles from "./agendas.module.scss";
import { MapLayer, LegendData, AgendasSize } from "helpers/models";
import { ReactComponent as LegendCloseSvg } from "assets/legend_close.svg";
import { ReactComponent as LegendOpenSvg } from "assets/legend_open.svg";
import { IconButton, Tooltip } from '@mui/material';

interface Props {
  size: string;
}

function Agendas(props: Props) {
  const [context, dispatch] = useContext(AppContext);
  const [isShowLegend, setIsShowLegend] = useState(false);
  const [legendType, setLegendType] = useState(true);
  const [t] = useTranslation();

  const deleteLayer = (item: LegendData) => {
    item.layerInfo.layerObjEn.hide(0)
    item.layerInfo.layerObjCn.hide(0)
    item.layerInfo.checked = false
    dispatch({ type: AppAction.updateLayerLegend, payload: item.layerInfo });
  }

  const agendasClass = () => {
    switch (props.size) {
      case AgendasSize.S:
        return styles.agendasS;
      case AgendasSize.M:
        return styles.agendasM;
      case AgendasSize.L:
        return styles.agendasL;
      default:
        return styles.agendasL;
    }
  };

  useEffect(() => {
    if (context.currentMultipleLocations) {
      setIsShowLegend(true);
    } else {
      if (context.layerLegend) {
        const num = context.layerLegend?.filter(item => item.layerInfo.checked).length;
        setIsShowLegend(num > 0);
      } else {
        setIsShowLegend(false);
      }
    }
  }, [context.currentMultipleLocations, context.layerLegend]);

  return (<>
    {isShowLegend && <div
      className={agendasClass()}
      style={{
        right: (context.rightPanel === RightPanel.Empty) || context.arrowRight ? "200px" : "600px"
      }}
    >
      <div className={styles.legendType}>
        <Tooltip
          className="p-[6px]"
          title={legendType ? t("layers.legendClose") : t("layers.legendOpen")}
          placement={legendType ? "right" : "top"}
          arrow
        >
          {legendType ?
            <LegendCloseSvg onClick={() => setLegendType(false)} className="fill-[#0009]" /> :
            <LegendOpenSvg onClick={() => setLegendType(true)} className="fill-[#0009]" />}
        </Tooltip>
      </div>

      {legendType ?
        <div className={styles.legendClose}>
          {
            context.currentMultipleLocations && (
              <MultipleAgenda />
            )
          }
          {
            context.layerLegend && (context.layerLegend.map((item, index) => (
              item.layerInfo.checked && (<Agenda
                {...item}
                onClose={() => deleteLayer(item)}
                key={index}
              />)
            )))
          }
        </div>
        :
        <div className={styles.legendOpen}>
          <AgendaOpen onClose={(event) => deleteLayer(event)} />
        </div>}
    </div>}
  </>);
}

export default Agendas;

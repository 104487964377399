import { config } from "containers/multi-locations/config/config-fathom";
import { Workbook, Worksheet } from "exceljs";
import { tx } from "../common/excel-fathom";
import { excelLocation } from "../location/excel-location";

export const excelSheetFathom = (context: any, t: any, workbook: Workbook, sheetNum: number) => {
	const worksheet = workbook.getWorksheet(sheetNum);

	if (!worksheet) {
		return;
	}

	const dataItem = excelLocation(context, worksheet, true);

	if (!dataItem || dataItem.length === 0) {
		return;
	}
	
	const hazardResult = context.hazardMultipleAnalysisResult;

	if (!hazardResult || hazardResult.length === 0) {
		return;
	}

	const FATHOM_LAYER_ARRAY = ['FL_Fluvial_Global_Fathom', 'FL_Pluvial_Global_Fathom', 'FL_Coastal_Global_Fathom']
	
	const fathomResult = hazardResult.filter((e: any) => FATHOM_LAYER_ARRAY.includes(e.layerId))

	if (!fathomResult || fathomResult.length === 0) {
		return;
	}

	dataItem.forEach((item: any, rowNum: number) => {
		const fathomResultFiltered = fathomResult.filter((f: any) => f.pointId === item.id || f.pointId.replaceAll('-', '') === item.id);

		if (fathomResultFiltered.length === 0) {
			return null;
		}

		fathomResultFiltered.forEach((e: any) => {

			const layerId = e.layerId;
			const defended = e.details.Defended && JSON.parse(e.details.Defended);
			const undefended = e.details.Undefended && JSON.parse(e.details.Undefended);
			
			fathomDefended(worksheet, t, layerId, defended, rowNum + 2 );
			fathomDefended(worksheet, t, layerId, undefended, rowNum + 2 );

		});
	});

};

const fathomDefended = (worksheet: Worksheet, t: any, layerId: string, defended: any[], rowNum: number) => {
	if(!layerId) {
		return;
	}
	
	if(!defended|| !defended.length) {
		return;
	}

	const layerIndex = config[layerId]

	if(!layerIndex) {
		return;
	}

	defended.forEach((e: any)=>{

		const floodProtection = e.floodProtection

		const returnPeriod = e.returnPeriod

		if(!floodProtection || !returnPeriod) {
			return;
		}

		const protectionIndex = layerIndex[floodProtection]
		
		if(!protectionIndex) {
			return;
		}

		const col = protectionIndex[returnPeriod]
		
		if(!col) {
			return;
		}

		const depth = e.depth;

		const value = depth <= 0 ? "-" : depth;

		tx(col, value, worksheet, rowNum, t);
	});

};

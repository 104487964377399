import { Polygon, Raster, Rule } from "helpers/models";

const get = (rules: Rule[]): string[] => {
  const colorArray = rules.map((c) => {
    const raster = (c.symbolizers[0] as Raster)?.raster?.color;
    const polygon = (c.symbolizers[0] as Polygon)?.polygon?.fill;
    return raster || polygon;
  });
  return colorArray;
};

export default get;

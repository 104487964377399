import { AnalysisItem, LegendItem } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import FATHOM_LAYER_ARRAY from "components/fathomAnalyze/id";
import getColor from "report/common/color";
import getLayer from "report/common/layer";
import getRect from "report/common/rect";
import getOther from "./other/other";
import getFathom from "./fathom/fathom";
import gap from "report/common/gap";
import getOutside from "report/common/outside";

const get = (
  t: any,
  analysisItems: AnalysisItem[],
  legendItems: LegendItem[]
): Content[] => {
  const row = analysisItems
    .filter((e) => e.valueLabel !== "No Data")
    .map((e) => {
      const legend = legendItems.find((i) => e.layerId === i.layerId);

      if (!legend || !legend.rules || !legend.rules.length) {
        return [];
      }

      const index = legend.rules.findIndex(
        (item) => item.title === e.valueLabel
      );

      const colorArray = getColor(legend.rules);

      return [
        gap(30),
        getLayer(t, e.layerId, 10, 1),
        gap(10),
        e.valueLabel === "Outside"
          ? getOutside(555)
          : getRect(colorArray, index, 555),
        gap(10),
        FATHOM_LAYER_ARRAY.includes(e.layerId)
          ? getFathom(t, e, legend)
          : getOther(t, e)
      ];
    });

  return [...row];
};

export default get;

import { AnalysisItem, RV } from "helpers/models";

const transform = (analysisItems: AnalysisItem[]): RV => {
  const groupedAnalysis: RV = {};

  analysisItems
    .forEach((item) => {
      if (!groupedAnalysis[item.layerId]) {
        groupedAnalysis[item.layerId] = {};
      }

      const valueLabel = item.valueLabel;
      if (!groupedAnalysis[item.layerId][valueLabel]) {
        groupedAnalysis[item.layerId][valueLabel] = 0;
      }
      groupedAnalysis[item.layerId][valueLabel]++;
    });

  return groupedAnalysis;
};

export default transform;

import { Fathom, Layer } from "helpers/models";

function groupByLayerId(array: Fathom[]): Layer {
  const result: Layer = {};
  array.forEach((item) => {
    const { layerId, ...rest } = item;

    if (!result[layerId]) {
      result[layerId] = {};
    }
    Object.entries(rest).forEach(([key, value]) => {
      if (!result[layerId][key]) {
        result[layerId][key] = [];
      }

      const existing = result[layerId][key].find(
        (entry) => entry.label === value
      );

      if (existing) {
        existing.count += 1;
      } else {
        result[layerId][key].push({ label: value, count: 1 });
      }
    });
  });
  return result;
}

export default groupByLayerId;

import { EChartOption } from "echarts";

const x = (count: number): EChartOption.XAxis => ({
  type: "value",
  max: count,
  axisLabel: {
    // show: true,
    fontSize: 10,
    color: "#333"
  },
  axisLine: {
    show: true,
    lineStyle: {
      color: "#D9E7F7",
      width: 1
    }
  }
});

export default x;

export const config: any = {
  FL_Fluvial_Global_Fathom: {
    Defended: {
      "5": "B",
      "10": "C",
      "20": "D",
      "50": "E",
      "100": "F",
      "200": "G",
      "500": "H",
      "1000": "I"
    },
    Undefended:  {
      "5": "J",
      "10": "K",
      "20": "L",
      "50": "M",
      "100": "N",
      "200": "O",
      "500": "P",
      "1000": "Q"
    }
  },
  FL_Pluvial_Global_Fathom: {
    Defended: {
      "5": "R",
      "10": "S",
      "20": "T",
      "50": "U",
      "100": "V",
      "200": "W",
      "500": "X",
      "1000": "Y"
    }
  },
  FL_Coastal_Global_Fathom: {
    Defended: {
      "5": "Z",
      "10": "AA",
      "20": "AB",
      "50": "AC",
      "100": "AD",
      "200": "AE",
      "500": "AF",
      "1000": "AG"
    },
    Undefended:  {
      "5": "AH",
      "10": "AI",
      "20": "AJ",
      "50": "AK",
      "100": "AL",
      "200": "AM",
      "500": "AN",
      "1000": "AO"
    }
  }
};

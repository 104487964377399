import { Content } from "pdfmake/interfaces";

const get = (text: string = "Hazards", w: number = 555): Content => {
  return {
    stack: [
      {
        canvas: [{ type: "rect", x: 0, y: 0, w, h: 28, color: "#3C424F" }],
        absolutePosition: { x: 20, y: 60 }
      },
      {
        text,
        color: "#FFF",
        margin: [10, 3, 0, 0],
        lineHeight: 0.7,
        fontSize: 14,
        opacity: 1,
      }
    ]
  };
};

export default get;

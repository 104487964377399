// import Modal from "components/modal/modal";
import Options, { Option } from "components/options/options";
import Svg from "components/svg/svg";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { getCounts, getCheckedCounts } from "helpers/helpers";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LocationsSvg } from "assets/icon_locations.svg";
import { getLegends, getLayerCounter } from "api/layer";
import styles from "./analyse-modal.module.scss";
import SelectLayers from "./select-layers";
import { Dialog } from '@mui/material';

function AnalyseModal() {
	const [context, dispatch] = useContext(AppContext);
	const [option, setOption] = useState<Option>(Option.HAZARD);
	const [t] = useTranslation();
	const [validateStatus, setValidateStatus] = useState(false);
	const [hazardButtons, setHazardButtons] = useState<any[]>([]);
	const [climateButtons, setClimateButtons] = useState<any[]>([]);

	const close = (): void => {
		dispatch({ type: AppAction.setAnalyseModal, payload: undefined });
	};

	useEffect(() => {
		if (context?.layerList?.length) {
			const hazardLegendsIds: string[] = [];
			let hazardPermission = false,
				fathomPermission = false;
			if (context.analyseModal && context.analyseModal.type === "1") {
				hazardPermission = context.menu.find((item) => item.id === "102001" && item.status === "1")
				fathomPermission = context.menu.find((item) => item.id === "102004" && item.status === "1")
			}

			if (context.analyseModal && context.analyseModal.type === "2") {
				hazardPermission = context.menu.find((item) => item.id === "103002" && item.status === "1")
				fathomPermission = context.menu.find((item) => item.id === "103005" && item.status === "1")
			}

			let arr =
				context?.layerList
					?.map((e) => e.children)
					.flat()
					.map((e) => {
						hazardLegendsIds.push(e.id);
						return {
							id: e.id,
							title: e.name,
							validateStatus: validateStatus,
							disabled: e.id.includes("Fathom") ? (!fathomPermission || validateStatus) : !hazardPermission,
							icon: Svg({ icon: e.id, disabled: e.id.includes("Fathom") ? (!fathomPermission || validateStatus) : !hazardPermission }),
						};
					}) || [];
			setHazardButtons(arr);
			if (!context.hazardLegends) {
				getLegends({
					layers: hazardLegendsIds.join(","),
				}).then((res: any) => {
					dispatch({ type: AppAction.setHazardLegends, payload: res.data.legends });
				});
			}
		}
	}, [context.menu, context.analyseModal, context.layerList, validateStatus]);

	useEffect(() => {
		if (context?.crsLayerList?.length) {
			const climateLegendsIds: string[] = [];
			let crsPermission = false;
			if (context.analyseModal && context.analyseModal.type === "1") {
				crsPermission = context.menu.find((item) => item.id === "102002" && item.status === "1")
			}

			if (context.analyseModal && context.analyseModal.type === "2") {
				crsPermission = context.menu.find((item) => item.id === "103003" && item.status === "1")
			}

			let arr =
				context?.crsLayerList?.map((e) => {
					climateLegendsIds.push(e.id);
					return {
						id: e.id,
						title: e.name,
						disabled: !crsPermission,
						icon: Svg({ icon: e.id, disabled: !crsPermission }),
					};
				}) || [];
			// arr = arr.filter(e => e.id !== "wind")
			setClimateButtons(arr);
		}
	}, [context.menu, context.analyseModal, context.crsLayerList]);

	useEffect(() => {
		if (!context.menu || !context.menu.length) {
			return;
		}

		if (context.analyseModal === undefined) {
			return;
		}

		getLayerCounter({
			actionCategories: "fathom",
			// userId: context.userInfo.userId,
			// tenantId: context.userInfo.tenantId
		}).then(res => {
			setValidateStatus(res.data.validateStatus <= 0)
		})

		if (context.analyseModal.type === "1") {
			if (context.menu.find((item) => item.id === "102001" && item.status === "1") || context.menu.find((item) => item.id === "102004" && item.status === "1")) {
				setOption(Option.HAZARD);
				return;
			}

			if (context.menu.find((item) => item.id === "102002" && item.status === "1")) {
				setOption(Option.CLIMATE);
				return;
			}
			return;
		}

		if (context.analyseModal.type === "2") {
			if (context.menu.find((item) => item.id === "103002" && item.status === "1") || context.menu.find((item) => item.id === "103005" && item.status === "1")) {
				setOption(Option.HAZARD);
				return;
			}

			if (context.menu.find((item) => item.id === "103003" && item.status === "1")) {
				setOption(Option.CLIMATE);
				return;
			}
			return;
		}
	}, [context.menu, context.analyseModal]);

	return (
		<Dialog
			open={!!context.analyseModal}
			onClose={close}
			sx={{
				"& .MuiDialog-paper": {
					width: "780px",
					maxWidth: "80vw",
					maxHeight: "85vh"
				}
			}}
		>
			<div className={styles.container}>
				<div className={styles.header}>{t("analyseModal.analyseLocations")}</div>
				<div className={styles.analyse}>
					<div>
						<div className={styles.header}>
							<LocationsSvg className={styles.image} />
							<div>
								{context.rightPanel === RightPanel.Single ? (
									<div className="ml-1">
										{context.currentMultipleLocationInfo
											? context.currentMultipleLocationInfo.name
											: context.searchLocationInfo[context.language].formatted_address}
									</div>
								) : (
									context.currentMultipleLocations && (
										<div>
											<div className="ml-3 pb-2">{context.currentMultipleLocations!.name}</div>
											<div className="ml-3">
												{getCheckedCounts(context.currentMultipleLocations!.items)}/{context.currentMultipleLocations?.itemCount}
												{t("analyseModal.locations")}
											</div>
										</div>
									)
								)}
							</div>
						</div>
					</div>
					<div className="mt-5 mb-2">
						<Options
							selected={option}
							onChange={setOption}
						/>
					</div>
					<div className={styles.tabContent}>
						{option === Option.HAZARD ? (
							<SelectLayers
								index={0}
								buttons={hazardButtons}
								defaultSelect={context.analyseModal ? context.analyseModal.selectId : undefined}
							/>
						) : (
							<SelectLayers
								index={1}
								buttons={climateButtons}
							/>
						)}
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default AnalyseModal;

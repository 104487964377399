import { AnalysisItem, Detail, LegendItem, R } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import layout from "report/common/layout";
import getDefended from "./defended";
import gap from "report/common/gap";
import data from "report/common/data";

const get = (
  t: any,
  analysisItem: AnalysisItem,
  legendItem: LegendItem
): Content[] => {
  const details = analysisItem.details as Detail;

  const ruleMap = legendItem.rules.reduce((acc: R, currentRule) => {
    const symbolizer = currentRule.symbolizers[0];
    if ("raster" in symbolizer) {
      acc[currentRule.title] = symbolizer.raster.color;
    }
    return acc;
  }, {} as R);

  return [
    {
      table: {
        headerRows: 0,
        widths: ["*", "*"],
        body: [
          data(
            t("fathom.Flood Protection"),
            t("legend." + analysisItem.floodProtection)
          ),
          data(
            t("fathom.Return Period"),
            t("legend." + analysisItem.valueLabel)
          ),
          data(
            t("fathom.Relative Score"),
            !analysisItem.relativeRisk || analysisItem.relativeRisk < 0
              ? "-"
              : analysisItem.relativeRisk.toString()
          ),
          data(
            t("fathom.Category Score"),
            !analysisItem.riskCategory || analysisItem.riskCategory < 0
              ? "-"
              : analysisItem.riskCategory.toString()
          )
        ]
      },
      layout: layout(1, false, 6)
    },
    gap(20),
    {
      columns: [
        getDefended(t, ruleMap, t("fathom.Defended"), details.Defended),
        getDefended(t, ruleMap, t("fathom.Undefended"), details.Undefended),
        ""
      ],
      columnGap: 10
    }
  ];
};

export default get;

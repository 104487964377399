import React, { useState } from "react";
import { FormControl, RadioGroup, FormControlLabel, Radio, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
	onLimitChange: (limitOption: string) => void;
	onCountChange: (limitCount: number) => void;
	initialLimitOption: string;
	initialLimitCount: number;
}

const LimitControl: React.FC<Props> = ({ initialLimitOption, initialLimitCount, onLimitChange, onCountChange }) => {
	const { t } = useTranslation();

	// const [limitOption, setLimitOption] = useState<string>(initialLimitOption || "unlimited");
	// const [limitCount, setLimitCount] = useState<number>(initialLimitCount || 0);

	// const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	if (event.target.value === "unlimited") {
	// 		setLimitCount(0);
	// 	} else {
	// 		setLimitCount(initialLimitCount);
	// 	}
	// 	setLimitOption(event.target.value);
	// };

	// const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setLimitCount(Number(event.target.value));
	// };

	return (
		<FormControl component="fieldset">
			<RadioGroup
				row
				name="locationLimit"
				value={initialLimitOption}
				onChange={(event) => {
					onLimitChange(event.target.value);
				}}
			>
				<FormControlLabel
					value="unlimited"
					control={<Radio color="success" />}
					label={<span style={{ fontSize: "14px", color: "#666" }}>{t("limitControl.unlimited")}</span>}
				/>
				<FormControlLabel
					value="limited"
					control={<Radio color="success" />}
					label={<span style={{ fontSize: "14px", color: "#666" }}>{t("limitControl.limited")}</span>}
				/>
				{initialLimitOption === "unlimited" ?
					(<span className="w-[130px] bg-[#f0f0f0] ml-[16px] rounded-[4px]"></span>)
					:
					(<TextField
						color="success"
						variant="outlined"
						value={initialLimitCount}
						onChange={(event) => {
							onCountChange(Number(event.target.value));
						}}
						size="small"
						style={{ marginLeft: 16 }}
						inputProps={{
							type: "number",
							min: 1,
							max: 10000000,
						}}
					/>)
				}

			</RadioGroup>
		</FormControl>
	);
};

export default LimitControl;

import domtoimage from "dom-to-image";
import { Content } from "pdfmake/interfaces";
import getTitle from "report/common/title";

const get = async (text: string = "Map Legends"): Promise<Content[]> => {
  const elementArray = document.querySelectorAll(
    '[class^="agenda_agenda"], [class^="multipleAgenda_multipleAgenda"]'
  );

  const cd = await Promise.all(
    Array.from(elementArray).map(async (e) => {
      return {
        image: await domtoimage.toPng(e, {
          filter: (element: Node) => {
            if (element instanceof HTMLElement) {
              return !Array.from(element.attributes).some(
                (attr) => attr.name === "role" && attr.value === "img"
              );
            }
            if (element instanceof SVGElement) {
              return !Array.from(element.attributes).some(
                (attr) => attr.name === "id" && attr.value === "loc"
              );
            }
            return true;
          }
        }),
        width: 130
      };
    })
  );

  if (cd.length <= 3) {
    return [getTitle(text), ...cd];
  }

  return [
    getTitle(text),
    {
      columns: [
        {
          stack: [...cd.slice(0, 3)]
        },
        {
          stack: [...cd.slice(3)]
        }
      ],
      columnGap: 0
    }
  ];
};

export default get;

import { Row } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import layout from "report/common/layout";

const get = (t: any, data: Row[], count: number): Content => {
  const row = data.map((e) => {
    const percent =
      e.count % count === 0
        ? (e.count / count) * 100 + "%"
        : ((e.count / count) * 100).toFixed(2) + "%";

    return [
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 12,
            h: 12,
            lineColor: "#E4E4E4",
            lineWidth: 1,
            color: e.color
          }
        ]
      },
      t("legend." + e.label),
      { text: e.count.toString(), alignment: "right" },
      { text: percent, alignment: "right" },
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 3,
            w: (e.count / count) * 150,
            h: 4,
            color: e.color
          }
        ],
        alignment: "right"
      }
    ];
  });

  return {
    table: {
      headerRows: 1,
      widths: [20, 130, 130, 130, "*"],
      body: [
        [
          { text: t("fathom.Return Period"), bold: true, colSpan: 2 },
          {},
          { text: t("report.Locations"), alignment: "right" },
          {
            text: t("report.Share Number of Locations"),
            alignment: "right"
          },
          {}
        ],
        ...row,
        [
          { text: t("report.Total") },
          {},
          { text: count, alignment: "right" },
          { text: "100%", alignment: "right" },
          {
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 3,
                w: 150,
                h: 4,
                color: "black"
              }
            ],
            alignment: "right"
          }
        ]
      ]
    },
    layout: layout(0, true, 6),

    unbreakable: true
  };
};

export default get;

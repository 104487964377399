import { AnalysisItem, LegendItem } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import getColor from "report/common/color";
import getLayer from "report/common/layer";
import getRect from "report/common/rect";
import layout from "report/common/layout";
import getOutside from "report/common/outside";

const get = (
  t: any,
  analysisItems: AnalysisItem[],
  legendItems: LegendItem[]
): Content => {
  const row = analysisItems
    .filter((e) => e.valueLabel !== "No Data")
    .map((e) => {
      const legend = legendItems.find((i) => e.layerId === i.layerId);

      if (!legend || !legend.rules || !legend.rules.length) {
        return [];
      }

      const index = legend.rules.findIndex(
        (item) => item.title === e.valueLabel
      );

      const colorArray = getColor(legend.rules);

      return [
        getLayer(t, e.layerId),
        e.valueLabel === "Outside" ? getOutside() : getRect(colorArray, index),
        t("legend." + e.valueLabel)
      ];
    });

  return {
    table: {
      widths: [200, 237, 100],
      body: [
        [
          { text: t("report.hazard") },
          { text: t("fathom.Hazard Risk") },
          { text: t("fathom.Return Period") }
        ],
        ...row
      ]
    },
    layout: layout(row.length + 2, false, 3),
    fontSize: 8,
    opacity: 0.6,
    lineHeight: 1,
    characterSpacing: 0
  };
};

export default get;

import { AppAction, AppContext } from "helpers/context";
import { MapContext } from "helpers/map";
import { useContext, useEffect, useState, useRef } from "react";
import { LocationData } from "helpers/models";
import { LeftPanel, RightPanel, zoomLevelMedium } from "helpers/constants";


const WorldZoom = () => {

    const [context, dispatch] = useContext(AppContext);
    const { AMap, map } = useContext(MapContext);
    var cluster: any = useRef(null);
    var points = [];
    var gridSize = 80;

    const handleMarkerClick = (e: any) => {
        if (e.clusterData.length > 1) {
            map.setCenter([e.lnglat.lng, e.lnglat.lat]);
            map.zoomIn();
        } else {
            let data = e.clusterData[0] ? e.clusterData[0] : e.cluster.p;
            if (context.currentMultipleLocationInfo && data.id === context.currentMultipleLocationInfo.id) {
                dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
                dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: undefined });
            } else {
                dispatch({ type: AppAction.setSearchMapCenter, payload: undefined });
                dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
                dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: data });
                dispatch({ type: AppAction.setMapZoom, payload: zoomLevelMedium });
                dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
            }
        }
    }

    const _renderClusterMarker = (event: any) => {
        const base = Math.pow(context.currentMultipleLocations!.itemCount!, 1 / 8);
        let current = 1;
        let index = 0;
        let styleArr = [
            {
                bg: "#fff8a1",
                size: "50px",
            },
            {
                bg: "#ffcb8a",
                size: "55px",
            },
            {
                bg: "#ff9c86",
                size: "60px",
            },
            {
                bg: "#ee6c91",
                size: "60px",
            },
            {
                bg: "#cb419c",
                size: "65px",
            },
            {
                bg: "#9c2499",
                size: "85px",
            },
            {
                bg: "#61168e",
                size: "85px",
            },
            {
                bg: "#001789",
                size: "85px",
            }
        ];

        for (let i = 1; i < styleArr.length; i++) {
            if (event.count >= current.toFixed() && event.count < (current * base).toFixed()) {
                index = i - 1
                break
            }
            current = current * base;
        }

        var content = `<div style="text-align:center;opacity:0.9;font-size:14px;font-weight: 500;color:#fff;border: 3px solid #fff;border-radius:50%;box-shadow:0px 0px 5px 0px rgba(0, 0, 0, 0.3);background: ${styleArr[index].bg};width:${styleArr[index].size};height:${styleArr[index].size};line-height:${styleArr[index].size};">${event.count}</div>`;
        event.marker.setContent(content);
    };

    const _renderMarker = (event: any) => {
        var content = '<img src="/icon_map_pin_yellow.svg" style="widht:100%;height:100%;"></img>';
        event.marker.setSize(context.currentMultipleLocationInfo && (event.data[0].id === context.currentMultipleLocationInfo.id) ? new AMap.Size(30, 42) : new AMap.Size(20, 28));
        event.marker.setContent(content)
    }


    useEffect(() => {
        if (context.currentMultipleLocations && context.currentMultipleLocations.items && context.currentMultipleLocations.items.length > 0) {
            // 在这里编写组件挂载时的逻辑

            // 数据中需包含经纬度信息字段 lnglat
            points = context.currentMultipleLocations.items.map((location) => {
                return {
                    ...location,
                    lnglat: [location.longitude, location.latitude],
                }
            })

            cluster.current = new AMap.MarkerCluster(map, points, {
                gridSize: gridSize, // 设置网格像素大小
                renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
                renderMarker: _renderMarker, // 自定义非聚合点样式
                maxZoom: 6.33, // 最大显示级别200公里
            });

            cluster.current.on('click', handleMarkerClick);
        }
        return () => {
            // 在这里编写组件卸载时的逻辑
            if (cluster.current) {
                cluster.current.off('click', handleMarkerClick);
                cluster.current.setMap(null);
                cluster.current = null;
            }
        };
    }, [context.currentMultipleLocations && context.currentMultipleLocations.items, context.currentMultipleLocationInfo]);

    return (
        <div></div>
    );
}

export default WorldZoom;
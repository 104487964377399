const get = (text: string) => {
  const regex = /(https?:\/\/[^\s\)]+|www\.[^\s\)]+)/g;
  const url = text.match(regex);
  if (!url || !url.length) {
    return "";
  }
  return url[0];
};

export default get;

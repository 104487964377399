import { AnalysisItem, LegendItem, LocationData } from "helpers/models";
import { getLanguage } from "helpers/translations";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import page from "report/common/page";
import getFooter from "report/common/footer";
import getHeader from "report/common/header";
import getLegend from "report/common/legend";
import getLogo from "report/common/logo";
import getMap from "report/common/map";
import getReportDetail from "./detail/report";
import getHazard from "./hazard/hazard";

interface Param {
  t: any;
  set: LocationData;
  analysisItems: AnalysisItem[];
  legendItems: LegendItem[];
}

const getDoc = async ({
  t,
  set,
  analysisItems,
  legendItems
}: Param): Promise<TDocumentDefinitions> => {
  const docDefinition: TDocumentDefinitions = {
    pageOrientation: "landscape",

    pageMargins: [20, 60, 20, 30],

    header: (currentPage, pageCount) =>
      currentPage === 1 ? getHeader(t("report.CatNet"), 842) : getLogo(),

    footer: (currentPage, pageCount) => getFooter(t, currentPage, pageCount),

    content: [
      {
        columns: [
          {
            stack: getReportDetail(t, set.name, set.itemCount ?? 0)
          },
          {
            stack: await getMap(t("report.Map View"))
          },
          {
            stack: await getLegend(t("report.Map Legends"))
          }
        ],
        columnGap: 20,
      },
      
      page(),
      await getHazard(t, analysisItems, legendItems, set.itemCount ?? 0)
    ],

    defaultStyle: {
      font: "微软雅黑",//getLanguage() === "en" ? "Roboto" : "微软雅黑",
      fontSize: 8,
      opacity: 0.6,
      lineHeight: 1,
      characterSpacing: 0
    }
  };

  return docDefinition;
};

export default getDoc;

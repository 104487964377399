import { Content } from "pdfmake/interfaces";

const get = (label: string, text: string): Content[] => {
  return [
    label,
    {
      text,
      opacity: 0.87
    }
  ];
};

export default get;

import { EChartOption } from "echarts";
import { Table } from "helpers/models";
import getSerie from "./serie";
import y from "./y";
import x from "./x";
import title from "./title";
import grid from "./grid";

const get = (t: any, grouped: Table, count: number): EChartOption => ({
  title: title(t),

  grid,

  xAxis: x(count),

  yAxis: y(t, grouped),

  series: getSerie(grouped)
});

export default get;

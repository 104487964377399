import { Content, PageBreak } from "pdfmake/interfaces";

const get = (pageBreak: PageBreak = "after"): Content => {
  return {
    text: "",
    pageBreak
  };
};

export default get;

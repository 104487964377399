import { Content, ContentColumns } from "pdfmake/interfaces";
import svg from "./svg";

const get = (
  t: any,
  layerId: string,
  // legendTitle: string,
  fontSize: number = 8,
  opacity: number = 0.6
): Content => {
  const layerTitle: ContentColumns = {
    columns: [
      svg[layerId] && {
        svg: svg[layerId],
        fit: [12, 12],
        width: 12
      },
      {
        // text: t("layers.item." + legendTitle),
        text: t("layerId." + layerId),
        width: "auto",
        margin: [3, 0, 6, 0]
      }
    ],
    fontSize,
    opacity,
    lineHeight: 1,
    characterSpacing: 0
  };

  layerId.includes("Fathom") &&
    layerTitle.columns.push({
      svg: svg.premium,
      fit: [12, 12],
      width: 12
    });

  return layerTitle;
};

export default get;

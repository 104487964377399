import styles from "./icon.module.scss";

interface Props {
  icon?: string;
  type?: string;
  onClick?: (event: any) => void;
  style?: React.CSSProperties;
}

function Icon(props: Props) {
  return (
    <div className={styles.icon} onClick={(event) => props.onClick && props.onClick(event)}>
      <div className={props.type === "outlined" ? "material-icons-outlined" : "material-icons"} style={props.style}>{props.icon || "close"}</div>
    </div>
  );
}

export default Icon;
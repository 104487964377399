import { Content } from "pdfmake/interfaces";

const barHeight = 4;
const xStart = 0;
const yStart = 0;
const fillColor = "#D3D3D3";

const get = (width: number = 237): Content => {
  return {
    canvas: [
      {
        type: "rect",
        x: xStart,
        y: yStart,
        w: width,
        h: barHeight,
        // lineColor: lineColor,
        // lineWidth: 1,
        color: fillColor
      }
    ],
    margin: [0, 4, 0, 0]
  };
};

export default get;

import { Table } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import page from "report/common/page";
import paginate from "report/common/pagenation";
import getEchart from "./echart/echart";

const get = async (
  t: any,
  grouped: Table,
  count: number
): Promise<Content[]> => {
  const groupedArray = paginate(grouped);

  const proArray = groupedArray.map(async (e) => await getEchart(t, e, count));

  const pageArray = await Promise.all(proArray);

  const pageData = pageArray.reduce((acc, cur, index) => {
    return index === pageArray.length - 1
      ? [...acc, cur]
      : [...acc, cur, page()];
  }, []);

  return pageData;
};

export default get;

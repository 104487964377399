import { Stack, Box } from "@mui/material";
import styles from "./ReturnPeriod.module.scss";
import { AnalysisItem, LegendItem, Polygon, Raster } from "helpers/models";
import { useTranslation } from "react-i18next";
import pint from "./pintUtil";

interface Props {
  legendItem: LegendItem;
  analysisItem: AnalysisItem;
}

export default function ReturnPeriod({
  legendItem,
  analysisItem
}: Readonly<Props>) {
  const [t] = useTranslation();

  const valueLabel = analysisItem.valueLabel;

  const getlengend = () => {
    if (valueLabel === "Waterbody") {
      return (
        <div className="w-full flex">
          <div
            style={{ backgroundColor: "rgb(220, 219, 220)" }}
            className={`w-1/2 h-[5px] border-0 border-gray-500 opacity-50 flex-none`}
          ></div>

          <div
            style={{ backgroundColor: "#00000061" }}
            className={`w-1/2 h-[5px] border-0 border-gray-500 opacity-50 flex-none`}
          ></div>
        </div>
      );
    }

    const isVal =
      valueLabel === "Outside" ||
      valueLabel === "No Data" ||
      valueLabel === "No Observation" ||
      valueLabel === undefined;

    if (isVal) {
      return (
        <div
          style={{ backgroundColor: "grey" }}
          className={`w-full h-[5px] border-0 border-gray-500 opacity-50 w-full flex-none`}
        ></div>
      );
    }

    return (
      <div
        id={legendItem.layerId}
        className="flex items-center h-[10px] w-full mb-[2px] pt-[6px]"
      >
        {legendItem.rules?.map((c, j: number) => {
          if (!c.symbolizers || !c.symbolizers[0]) {
            return null;
          }
          const raster = (c.symbolizers[0] as Raster)?.raster?.color;
          const polygon = (c.symbolizers[0] as Polygon)?.polygon?.fill;
          const rasterOpacity = (c.symbolizers[0] as Raster)?.raster?.opacity;
          const polygonOpacity = (c.symbolizers[0] as Polygon)?.polygon
            ?.fillOpacity;
          const color = raster || polygon;
          const opacity = rasterOpacity || polygonOpacity;
          const isPint = pint(valueLabel, c.title);
          return (
            opacity > 0 && (
              <div
                key={j}
                style={{ backgroundColor: color, opacity }}
                className={`${styles.lagend} ${isPint ? styles.active : ""}`}
              >
                {/* {c.title} {c.symbolizers[0].raster.color} */}
              </div>
            )
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Stack direction="row" spacing={1} sx={{ p: "3px" }}>
        <Box sx={{ flex: 7 }}>{t("fathom.Hazard Risk")}</Box>
        <Box sx={{ flex: 3 }}>{t("fathom.Return Period")}</Box>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ p: "3px" }}>
        <Box sx={{ flex: 7 }} className="flex justify-center items-center">
          {getlengend()}
        </Box>
        <Box sx={{ flex: 3 }} className={styles.value}>
          <div className="flex-1 content-end text-start">
            {t("legend." + valueLabel)}
          </div>
        </Box>
      </Stack>
    </div>
  );
}

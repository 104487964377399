import { useState } from "react";
import styles from "./hailExpander.module.scss";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

interface Props {
    layerInfo: any
}

function HaillExpander(props: Props) {
    const [t] = useTranslation();
    const [haillData, setHaillData] = useState<any>({
        "Hail Hazard (current climate)": {
            "Hail Hazard": props.layerInfo.details["Hail Hazard (current climate)"],
            "Hail Days (Hail > 2.5cm) per Year": props.layerInfo.details["Hail Days (Hail > 2.5cm) per Year"],
            "Intensity Value": props.layerInfo.intensity,
            "expanded": false
        },
        "Hail Hazard (31-year historical dataset)": {
            "Hail Hazard": props.layerInfo.details["Hail Hazard (31-year historical dataset)"],
            "Hail Days (31 year-average)": props.layerInfo.details["Hail Days (31 year-average)"],
            "31-year trend": props.layerInfo.details["31-year trend"],
            "Mean Hail Probability (31-year data)": props.layerInfo.details["Mean Hail Probability (31-year data)"],
            "expanded": false
        },
        "Hail Hazard (64-year historical dataset)": {
            "Hail Hazard": props.layerInfo.details["Hail Hazard (64-year historical dataset)"],
            "Hail Days (64 year-average)": props.layerInfo.details["Hail Days (64 year-average)"],
            "64-year trend": props.layerInfo.details["64-year trend"],
            "Mean Hail Probability (64-year data)": props.layerInfo.details["Mean Hail Probability (64-year data)"],
            "expanded": false
        }
    })

    return (<>
        {Object.keys(haillData).map((key) => (
            <div className={styles.expander} key={key}>
                <div className={styles.header} onClick={() => {
                    let temp = { ...haillData };
                    temp[key].expanded = !temp[key].expanded;
                    setHaillData(temp);
                }}>
                    <div
                        className={styles.icon}
                    >
                        <div className="material-icons">
                            {haillData[key].expanded ? "keyboard_arrow_down" : "keyboard_arrow_right"}
                        </div>
                    </div>
                    <div className="text-[#000000de] text-[14px] font-medium">{t("legend." + key)}</div>
                </div>
                {haillData[key].expanded && (<div className="pl-[48px]">{
                    Object.keys(haillData[key]).map((e) => (
                        e !== "expanded" && <Stack key={e} direction="row" spacing={1} className="my-[5px]">
                            <div className={styles.key}>
                                {t("legend." + e).startsWith("legend.") ? e : t("legend." + e)}
                            </div>
                            <div className={styles.value}>
                                {t("legend." + haillData[key][e]).startsWith("legend.")
                                    ? haillData[key][e]
                                    : t("legend." + haillData[key][e])}
                            </div>
                        </Stack>
                    ))}
                </div>)}
            </div>
        ))}
    </>);
}

export default HaillExpander;

import { Content } from "pdfmake/interfaces";
import { date, il, set } from "report/common/icon";
import layout from "report/common/layout";
import getTitle from "report/common/title";

const get = (t: any, name: string, count: number): Content[] => [
  getTitle(t("report.Report Details")),
  {
    table: {
      headerRows: 0,
      widths: ["auto", "*", "*"],
      body: [
        [
          {
            image: set,
            width: 7.5,
            alignment: "right",
            marginTop: 3,
          },
          { text: t("report.Location Set"), opacity: 0.6 },
          name
        ],
        [
          {
            image: il,
            width: 7.5,
            alignment: "right",
            marginTop: 3,
          },
          { text: t("report.Total Locations"), opacity: 0.6 },
          `${count}`
        ],
        [
          {
            image: date,
            width: 7.5,
            alignment: "right",
            marginTop: 3,
          },
          { text: t("report.date"), opacity: 0.6 },
          new Date().toLocaleString()
        ],
      ]
    },
    layout: layout(),
    fontSize: 8
  }
];

export default get;

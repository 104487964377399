import { config } from "containers/multi-locations/config/config-climate";
import { Workbook } from "exceljs";
import { tx } from "../common/excel-climate";
import { excelLocation } from "../location/excel-location";

export const excelSheetClimate = (context: any, t: any, workbook: Workbook, sheetNum: number, scenarioName: string) => {
	const worksheet = workbook.getWorksheet(sheetNum);

	if (!worksheet) {
		return;
	}

	const dataItem = excelLocation(context, worksheet);

	if (!dataItem || dataItem.length === 0) {
		return;
	}

	const climateResult = context.climateMultipleAnalysisResult;

	if (!climateResult || climateResult.length === 0) {
		return;
	}

	dataItem.forEach((item: any, i: number) => {
		const dataClimateFind = climateResult.find((climate: any) => climate.id === item.id || climate.id.replace(/-/g, "") === item.id.replace(/-/g, ""));

		const climateRiskScores = dataClimateFind?.climateRiskScores;
		if (!climateRiskScores || climateRiskScores.length === 0) {
			return;
		}

		climateRiskScores.forEach((score: any) => {
			const index = score.index;
			const scenarios = score.scenarios;

			if (!index || !scenarios || scenarios.length === 0) {
				return;
			}

			const scenario = scenarios.find((scenario: any) => scenario.name === scenarioName);
			if (!scenario) {
				return;
			}

			const bandGroups = scenario.bandGroups;

			if (!bandGroups || bandGroups.length === 0 || bandGroups.length !== 1) {
				return;
			}

			if (bandGroups.length !== 1) {
				return;
			}

			const bandGroup = bandGroups[0];

			const id = bandGroup.id;
			const bands = bandGroup.bands;

			if (!id || !bands || bands.length === 0) {
				return;
			}

			bands.forEach((band: any) => {
				if (!band) {
					return;
				}

				const name = band.name;
				const value = band.value;

				if (!name || !value) {
					return;
				}

				const indexInConfig = config[index];

				if (!indexInConfig) {
					return;
				}

				const col = indexInConfig[name];

				if (!col) {
					return;
				}

				tx(col, value, worksheet, i + 2, t);
			});
		});
	});
};

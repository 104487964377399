import { Worksheet } from "exceljs";
import { tx as tc } from "./excel-util";

export const tx = (
  col: string,
  value: any,
  worksheet: Worksheet,
  row: number,
  t: any
) => {
  if (value === "0" || value === 0 || value < 0) {
    tc(col, "-", worksheet, row, t);
    return;
  }

  tc(col, value, worksheet, row, t);
};

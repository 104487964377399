import { Layer } from "helpers/models";
import { Content } from "pdfmake/interfaces";
import gap from "report/common/gap";
import getLayer from "report/common/layer";
import getTitle from "report/common/title";
import item from "./row";

const get = (t: any, grouped: Layer, count: number): Content[] => {
  const layerArray = Object.keys(grouped).map((e) => {
    if (e === "FL_Pluvial_Global_Fathom") {
      return [
        getLayer(t, e, 10, 1),
        gap(10),
        item(t, grouped[e]["undefended"], count),
        gap(30)
      ];
    }
    const row = Object.keys(grouped[e]).map((defended) => {
      return [
        gap(10),
        getTitle(t("report." + defended), 10),
        item(t, grouped[e][defended], count)
      ];
    });
    return [getLayer(t, e, 10, 1), ...row, gap(30)];
  });

  return [layerArray];
};

export default get;

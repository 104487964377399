export enum MapSize {
	XS = "XS",
	S = "S",
	M = "M",
	L = "L",
}

export enum AgendasSize {
	S = "S",
	M = "M",
	L = "L",
}

export interface MapData {
	zoom: number;
	centerLat: number;
	centerLon: number;
}

export interface PerilGroup {
	name: string;
	perils: Peril[];
}

export interface Peril {
	name: string;
	checked?: boolean;
	onClick?: () => void;
	onInfoClick?: () => void;
}

export interface MapLayer {
	name: string;
	children: Children[];
}

export interface AnalysisItem {
	layerId: string;
	pointId?: string;
	valueLabel: string;
	floodProtection?: string;
	relativeRisk?: number;
	riskCategory?: number;
	intensity: number;
	details?: {};
	checked?: boolean;
	hailAdditionalDetails?: {};
}

export interface climateRiskScoresItems {
	index: string;
	scenarios: any[];
}

export interface ClimateItems {
	id: string;
	climateRiskScores: climateRiskScoresItems[];
	coordinates: {
		latitude: number;
		longitude: number;
	};
	errors?: any[];
}

export interface Raster {
	raster: {
		color: string;
		opacity: number;
	};
}

export interface Polygon {
	polygon: {
		fill: string;
		fillOpacity: number;
	};
}

export interface Rule {
	title: string;
	symbolizers: (Raster | Polygon)[];
}

export interface LegendItem {
	layerId: string;
	rules: Rule[];
	title: string;
	subtitle?: string;
}

export interface AnalysisData {
	legendItems: LegendItem[] | string;
	analysisItems: AnalysisItem[] | string;
}

export interface Children {
	id: string;
	name: string;
	group: string;
	category: string;
	description: string;
	checked?: boolean;
	onClick?: () => void;
	onInfoClick?: () => void;
	layerObj?: any;
	layerObjEn?: any;
	layerObjCn?: any;
	oversea?: boolean;
	opacity?: number;
}

export interface LocationData {
	id: string;
	name: string;
	address?: string;
	country?: string;
	county?: string;
	province?: string;
	oversea?: boolean;
	spatial?: string;
	createTime?: string;
	items?: any[];
	code?: string;
	itemCount?: number;
	tiv: number;
	lat: number;
	lon: number;
	checked: boolean;
	latitude?: number;
	latitudeGcj?: number;
	latitudeWgs?: number;
	longitude?: number;
	longitudeGcj?: number;
	longitudeWgs?: number;
	coordinateSystem?: string
}

export interface Item {
	id: string;
	country: string;
	county?: string;
	code?: number;
	latitude: number;
	longitude: number;
	name: string;
	number?: string;
	place?: string;
	province?: string;
	street?: string;
	quality?: number;
	address: string;
	city?: string;
	district?: string;
	location?: string;
}

// export interface layerList {
//   id: string;
//   group: string;
//   category: string;
//   description: string;
//   name: string;
// }

export interface LegendData {
	layerId: string;
	title: string;
	subtitle: string;
	rules?: any;
	layerInfo: Children;
}

export interface crsLayer {
	id: string;
	name: string;
	description: string;
}

export interface locationSourceOption {
	label: string;
	value: string;
}

export interface DefendedData {
	depth: string;
	floodProtection: string;
	returnPeriod: string;
	valueLabel: string;
	color: string;
}

export interface analyseModalData {
	type: "1" | "2",
	selectId?: string
}

export interface Detail {
	Defended: string;
	Undefended: string;
}

export type R = Record<string, string>

export type RR = Record<string, R>

export type RV = { [key: string]: { [key: string]: number } }

// export type RD = { [key: string]: { count: number; color: string } }

// export type RC = { [key: string]:  RD; }

export interface Fathom {
	layerId: string,
	defended: string,
	undefended: string,
}

export type Row = { label: string; count: number; color?: string };

export type Table = Record<string, Row[]>;

export type Layer = Record<string, Table>;

export interface HailAdditionalDetails {
	"HailHazard-31-year-historical-data-set": R;
	"HailHazard-64-year-historical-data-set": R;
}
const text = (t: any, idArray: string[]) => {
  if (!idArray || !idArray.length) {
    return "";
  }
  let text = t("multiple.noData");
  idArray.forEach((layerId: string, index: number) => {
    if (index === 0) {
      text += t(`layerId.${layerId}`);
    } else if (index === idArray.length - 1) {
      text += t("multiple.and") + t(`layerId.${layerId}`);
    } else {
      text += t("multiple.comma") + t(`layerId.${layerId}`);
    }
  });
  text += t("multiple.period");
  return text;
};

export default text;

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Accordion.module.scss";

import { ReactNode } from "react";

const CustomAccordion = ({ title, children }: { title: string; children: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        margin: "0!important",
        "&::before": {
          display: "none"
        },
        borderBottom: "1px solid #ccc",
        borderRadius: "0!important"
      }}
    >
      <AccordionSummary
        className={styles.summary}
        expandIcon={<div className="material-icons">keyboard_arrow_right</div>}
        sx={{
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)"
          },
          padding: "0 0",
          minHeight: "34px!important",
          height: "36px!important",
          fontWeight: "600"
        }}
      >
        {title || t("fathom.Undefended") }
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0 0",
          width: "100%",
          borderTop: "1px solid #ccc"
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;

import * as echarts from "echarts";
import { EChartOption } from "echarts";

const get = (
  option: EChartOption<EChartOption.Series>,
  count: number
): Promise<string> => {
  const tempDiv = document.createElement("div");
  tempDiv.style.width = "790px";
  tempDiv.style.height = count * 30 + 30 + "px";

  const chart = echarts.init(tempDiv, null, { renderer: "svg" });

  chart.setOption(option);
  return new Promise((resolve, reject) => {
    chart.on("finished", () => {
      try {
        const base64Image = chart.renderToSVGString();
        chart.dispose();
        resolve(base64Image);
      } catch (error) {
        reject(new Error(error as string));
      }
    });
  });
};

export default get;
